import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';
import { H3, P } from '@insights-ltd/design-library/components';
import { InviteTokenResponse } from 'api/practitioners/practitionerApiTypes';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import { FullScreenSpinner } from 'components/FullScreen';
import { useQueryClient } from '@tanstack/react-query';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useSignUp, useValidateInvite, useFederatedSignUp } from 'api';
import { FormData } from './types';
import SignUpForm from './SignUpForm/SignUpForm';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(spacingSizeMap.L),
  width: '500px',
  color: `${theme.palette.common.white} !important`,
}));

const PasswordSignUpForm = ({ token }: { token: string }) => {
  const navigate = useNavigate();
  const { mutate, isPending, error } = useSignUp(useQueryClient());

  const signupWithPassword = ({
    fullName,
    password,
    preferredDialect = 'en-GB',
  }: FormData) => {
    mutate(
      { fullName, password, preferredDialect, token },
      { onSuccess: () => navigate('/') },
    );
  };

  return (
    <SignUpForm
      onSubmit={signupWithPassword}
      signUpError={error}
      isLoading={isPending}
      authenticationMethod="PASSWORD"
    />
  );
};

const FederatedSignUpForm = ({ token }: { token: string }) => {
  const { mutate, isPending, error } = useFederatedSignUp();

  function signupFederated({ fullName, preferredDialect = 'en-GB' }: FormData) {
    mutate(
      { fullName, preferredDialect, token },
      {
        onSuccess: (response) => {
          window.location.href = response.url;
        },
      },
    );
  }

  return (
    <SignUpForm
      onSubmit={signupFederated}
      signUpError={error}
      isLoading={isPending}
      authenticationMethod="FEDERATED"
    />
  );
};

type ContentProps = {
  data?: InviteTokenResponse;
  token: string;
};

const Content = ({ data, token }: ContentProps) => {
  const { t } = useTranslation();

  if (!data) {
    return (
      <StyledCard>
        <H3>{t('ui.event-management.sign_up.error.heading')}</H3>
        <Box height={spacingSizeMap.XL} />
        <P>{t('ui.event-management.sign_up.error.body')}</P>
        <Box height={spacingSizeMap.S} />
        <P variant="body-bold">
          <Link href="mailto://customerservice@insights.com">
            customerservice@insights.com
          </Link>
        </P>
      </StyledCard>
    );
  }

  return data.authenticationMethod === 'EXTERNAL' ? (
    <FederatedSignUpForm token={token} />
  ) : (
    <PasswordSignUpForm token={token} />
  );
};

const SignUp = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const token = hash.slice('#token='.length);
  const { data, status } = useValidateInvite(token);

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.sign-up')}</title>
      </Helmet>
      <SelfServiceLayout>
        <Content data={data} token={token} />
      </SelfServiceLayout>
    </>
  );
};

export default SignUp;
