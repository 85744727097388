import {
  TextV2,
  SvgLock2Bold,
  Avatar,
  Label,
  SwitchV2,
} from '@insights-ltd/design-library/components';
import { Box, styled } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const StyledLock = styled(SvgLock2Bold)(({ theme }) => ({
  fill: theme.palette.grey[600],
  transform: 'scale(67%)',
  marginRight: '1px',
}));

type SpecialHandlingSelectionProps = {
  setSpecialHandling: (value: boolean) => void;
  isSpecialHandling: boolean;
  disabled?: boolean;
};

export const SpecialHandlingSelection = ({
  setSpecialHandling,
  isSpecialHandling,
  disabled = false,
}: SpecialHandlingSelectionProps) => {
  const { t } = useTranslation();
  const handleChange = (value: boolean) => {
    setSpecialHandling(value);
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'flext-start'}
        alignItems={'center'}
        mb={1}
      >
        <Avatar color="blue">
          <StyledLock />
        </Avatar>
        <TextV2 variant="h4" sx={{ marginLeft: '8px' }}>
          {t('ui.event-management.special-handling.subtitle')}
        </TextV2>
      </Box>
      <Box mt={2} pb={1} width={700}>
        <TextV2 color="textSecondary">
          <Trans
            i18nKey="ui.event-management.special-handling.info"
            components={{
              stress: (
                <TextV2
                  component="span"
                  variant="bodyBold"
                  color="textSecondary"
                />
              ),
            }}
          />
        </TextV2>
      </Box>
      <Box mt={2}>
        <Label>{t('ui.event-management.special-handling.switch.label')}</Label>
        <Box ml={1} mt={1}>
          <SwitchV2
            id="special-handling-switch"
            disabled={disabled}
            checked={isSpecialHandling}
            onChange={handleChange}
            value={isSpecialHandling}
            labelLeft={t('ui.event-management.special-handling.switch.off')}
            labelRight={t('ui.event-management.special-handling.switch.on')}
            sx={{ marginTop: '20px' }}
          />
        </Box>
      </Box>
    </>
  );
};
