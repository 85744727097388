import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box, styled } from '@mui/material';
import {
  Dialog,
  RadioGroupControl,
  Span,
  TextAreaV2,
} from '@insights-ltd/design-library/components';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ReasonToArchive } from 'api/archive/archiveApiTypes';

export type Props = {
  open: boolean;
  profileTitle: string;
  onCancel: () => void;
  archiveReason: ReasonToArchive;
  setArchiveReason: Dispatch<SetStateAction<ReasonToArchive>>;
  archiveReasonOther: string;
  setArchiveReasonOther: Dispatch<SetStateAction<string>>;
  onSubmit: () => void;
  archiveError: boolean;
  lastProfile: boolean;
};

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  display: 'inline-block',
  fontWeight: 600,
}));

export const archiveReasonOptions = [
  {
    value: 'INCORRECT_EVALUATOR' as ReasonToArchive,
    label: 'ui.event-management.learner.profiles.archive.reason.option1',
  },
  {
    value: 'PRIOR_PROFILE_PREFERENCE' as ReasonToArchive,
    label: 'ui.event-management.learner.profiles.archive.reason.option2',
  },
  {
    value: 'OTHER' as ReasonToArchive,
    label: 'ui.event-management.learner.profiles.archive.reason.option3',
  },
];

const ProfileArchiveDialog = ({
  open,
  profileTitle,
  archiveReason,
  setArchiveReason,
  archiveReasonOther,
  setArchiveReasonOther,
  onCancel,
  onSubmit,
  archiveError,
  lastProfile,
}: Props) => {
  const { t } = useTranslation();
  const archiveReasonOtherContainer = useRef<HTMLDivElement>(null);

  const scrollToArchiveReasonTextbox = async () => {
    await document.getElementById(`#archive-other-reason`)?.focus();

    if (archiveReasonOtherContainer.current) {
      archiveReasonOtherContainer.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToArchiveReasonTextbox();
  }, [archiveReason]);

  return (
    <Dialog
      onSubmit={onSubmit}
      onClose={() => onCancel()}
      open={open}
      content={
        <>
          {!archiveError && !lastProfile && (
            <>
              <Box>
                <Span>
                  <Trans
                    i18nKey="ui.event-management.learner.profiles.archive.confirmation"
                    components={{ bold: <Span variant="body-bold" /> }}
                    values={{ title: profileTitle }}
                  />
                </Span>
              </Box>
              <Box>
                <p>
                  <Span>
                    <Trans
                      i18nKey="ui.event-management.learner.profiles.archive.recent.dicovery.profile"
                      components={{ bold: <Span variant="body-bold" /> }}
                    />
                  </Span>
                </p>
              </Box>
              <Box sx={{ lineHeight: '1.75rem' }}>
                <Span>
                  <Trans
                    i18nKey="ui.event-management.learner.profiles.archive.restore.suggestion"
                    components={{ bold: <Span variant="body-bold" /> }}
                  />
                </Span>
              </Box>
              <Box
                sx={{
                  margin: '1rem 0',
                  ' > span': { fontWeight: 600 },
                  '& .MuiFormControlLabel-root': {
                    alignItems: 'start',
                    marginTop: '12px',
                  },
                  '& .MuiTypography-root': {
                    fontWeight: 600,
                    lineHeight: '1.75rem',
                  },
                }}
              >
                <Span>
                  <Trans
                    i18nKey="ui.event-management.learner.profiles.archive.reason"
                    components={{ bold: <Span variant="body-bold" /> }}
                  />
                </Span>
                <RadioGroupControl
                  options={archiveReasonOptions.map((option) => ({
                    value: option.value,
                    label: (
                      <strong>{t(`${option.label}`)}</strong>
                    ) as unknown as string,
                  }))}
                  name="archiveReasons"
                  title={t(
                    'ui.event-management.learner.profiles.reason-for-archive',
                  )}
                  value={archiveReason}
                  onChange={setArchiveReason}
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                />
                {archiveReason === 'OTHER' && (
                  <div ref={archiveReasonOtherContainer}>
                    <TextAreaV2
                      value={archiveReasonOther}
                      autoFocus
                      maxLength={100}
                      onChange={(event) =>
                        setArchiveReasonOther(event.target.value)
                      }
                    />
                  </div>
                )}
              </Box>
            </>
          )}
          {archiveError && <Box>Profile failed to archive!</Box>}
          {lastProfile && (
            <>
              <Box>
                <Span>
                  <Trans
                    i18nKey="ui.event-management.learner.profiles.cannot.archive.reason"
                    components={{ bold: <Span variant="body-bold" /> }}
                    values={{ title: profileTitle }}
                  />
                </Span>
              </Box>
              <Box>
                <p>
                  <Span>
                    <Trans
                      i18nKey="ui.event-management.learner.profiles.cannot.archive.suggestion"
                      components={{
                        bold: <Span variant="body-bold" />,
                        '1': <StyledLink to="/evaluator-links" />,
                        '2': <StyledLink to="/experiences/create" />,
                      }}
                      values={{ title: profileTitle }}
                    />
                  </Span>
                </p>
              </Box>
            </>
          )}
        </>
      }
      primaryButtonText={
        !archiveError && !lastProfile
          ? t('ui.event-management.learner.profiles.archive.submit.button')
          : ''
      }
      disablePrimaryButton={
        archiveReason === '' ||
        (archiveReason === 'OTHER' && archiveReasonOther.length < 1)
      }
      secondaryButtonText={
        !archiveError && !lastProfile
          ? t('ui.event-management.learner.profiles.archive.cancel.button')
          : 'Okay'
      }
      title={
        !archiveError && !lastProfile
          ? t('ui.event-management.learner.profiles.archive.title')
          : t('ui.event-management.learner.profiles.cannot.archive.title')
      }
    />
  );
};

export default ProfileArchiveDialog;
