import { MutationStatus } from '@tanstack/react-query';
import { COLOUR_SCORE_COLOURS } from 'api/httpEntities';
import {
  Chapter,
  ChapterPositions,
  ColourEnergy,
  DatadogRumConfig,
  EventTypes,
  FrequencyType,
  InviteeOptions,
  InviteeStatuses,
  JobTitles,
  LicensedProductOption,
  ProductTypes,
  ProfileTypes,
  QueryKeyRoots,
  RolePositions,
} from 'types/types';

export const CHAPTER_POSITION: ChapterPositions = [
  'FOUNDATION',
  'MANAGEMENT',
  'EFFECTIVE_SELLING',
  'PERSONAL_ACHIEVEMENT',
  'INTERVIEW',
  'IDTL',
].reduce((acc, chapter, idx) => ({ ...acc, [chapter]: idx }), {});

export const CHAPTERS: Chapter[] = [
  {
    id: 'FOUNDATION',
    titleKey: 'ui.event-management.events.assign-chapters.foundation',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: true,
  },
  {
    id: 'MANAGEMENT',
    titleKey: 'ui.event-management.events.assign-chapters.management',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'EFFECTIVE_SELLING',
    titleKey: 'ui.event-management.events.assign-chapters.effective-selling',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'PERSONAL_ACHIEVEMENT',
    titleKey: 'ui.event-management.events.assign-chapters.personal-achievement',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'INTERVIEW',
    titleKey: 'ui.event-management.events.assign-chapters.interview',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'IDTL',
    titleKey:
      'ui.event-management.events.assign-chapters.insights-discovering-leadership-effectiveness',
    productTypes: ['DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE'],
    mandatory: true,
  },
  {
    id: 'DFC',
    titleKey:
      'ui.event-management.events.assign-chapters.insights-discovery-full-circle',
    productTypes: ['DISCOVERY_FULL_CIRCLE_PROFILE'],
    mandatory: true,
  },
];

export const COLOUR_ENERGY: ColourEnergy = {
  CoolBlue: 'ui.event-management.colour.blue.name',
  EarthGreen: 'ui.event-management.colour.green.name',
  FieryRed: 'ui.event-management.colour.red.name',
  SunshineYellow: 'ui.event-management.colour.yellow.name',
};

export const COUNTRIES: string[] = [
  'ui.common.country.albania',
  'ui.common.country.algeria',
  'ui.common.country.argentina',
  'ui.common.country.australia',
  'ui.common.country.austria',
  'ui.common.country.azerbaijan',
  'ui.common.country.bahrain',
  'ui.common.country.belarus',
  'ui.common.country.belgium',
  'ui.common.country.belize',
  'ui.common.country.bolivia',
  'ui.common.country.bosnia-and-herzegovina',
  'ui.common.country.brazil',
  'ui.common.country.brunei-darussalam',
  'ui.common.country.bulgaria',
  'ui.common.country.canada',
  'ui.common.country.chile',
  'ui.common.country.china',
  'ui.common.country.colombia',
  'ui.common.country.costa-rica',
  'ui.common.country.croatia',
  'ui.common.country.czech-republic',
  'ui.common.country.denmark',
  'ui.common.country.dominican-republic',
  'ui.common.country.ecuador',
  'ui.common.country.egypt',
  'ui.common.country.el-salvador',
  'ui.common.country.estonia',
  'ui.common.country.faroe-islands',
  'ui.common.country.finland',
  'ui.common.country.france',
  'ui.common.country.germany',
  'ui.common.country.greece',
  'ui.common.country.greenland',
  'ui.common.country.guatemala',
  'ui.common.country.honduras',
  'ui.common.country.hong-kong',
  'ui.common.country.hungary',
  'ui.common.country.iceland',
  'ui.common.country.india',
  'ui.common.country.indonesia',
  'ui.common.country.iran-islamic-republic-of',
  'ui.common.country.iraq',
  'ui.common.country.ireland',
  'ui.common.country.israel',
  'ui.common.country.italy',
  'ui.common.country.jamaica',
  'ui.common.country.japan',
  'ui.common.country.jordan',
  'ui.common.country.kazakhstan',
  'ui.common.country.kenya',
  'ui.common.country.korea-republic-of',
  'ui.common.country.kuwait',
  'ui.common.country.kyrgyzstan',
  'ui.common.country.latvia',
  'ui.common.country.lebanon',
  'ui.common.country.libyan-arab-jamahiriya',
  'ui.common.country.liechtenstein',
  'ui.common.country.lithuania',
  'ui.common.country.luxembourg',
  'ui.common.country.macao',
  'ui.common.country.macedonia',
  'ui.common.country.malaysia',
  'ui.common.country.malta',
  'ui.common.country.mexico',
  'ui.common.country.monaco',
  'ui.common.country.mongolia',
  'ui.common.country.morocco',
  'ui.common.country.netherlands',
  'ui.common.country.new-zealand',
  'ui.common.country.nicaragua',
  'ui.common.country.nigeria',
  'ui.common.country.norway',
  'ui.common.country.oman',
  'ui.common.country.pakistan',
  'ui.common.country.panama',
  'ui.common.country.paraguay',
  'ui.common.country.peru',
  'ui.common.country.philippines',
  'ui.common.country.poland',
  'ui.common.country.portugal',
  'ui.common.country.puerto-rico',
  'ui.common.country.qatar',
  'ui.common.country.romania',
  'ui.common.country.russian-federa.',
  'ui.common.country.rwanda',
  'ui.common.country.saudi-arabia',
  'ui.common.country.senegal',
  'ui.common.country.serbia-and-montenegro',
  'ui.common.country.singapore',
  'ui.common.country.slovakia',
  'ui.common.country.slovenia',
  'ui.common.country.south-africa',
  'ui.common.country.spain',
  'ui.common.country.sweden',
  'ui.common.country.switzerland',
  'ui.common.country.syrian-arab-republic',
  'ui.common.country.taiwan',
  'ui.common.country.tajikistan',
  'ui.common.country.tanzania',
  'ui.common.country.thailand',
  'ui.common.country.trinidad-and-tobago',
  'ui.common.country.tunisia',
  'ui.common.country.turkey',
  'ui.common.country.turkmenistan',
  'ui.common.country.ukraine',
  'ui.common.country.united-arab-emirates',
  'ui.common.country.united-kingdom',
  'ui.common.country.united-states',
  'ui.common.country.uruguay',
  'ui.common.country.uzbekistan',
  'ui.common.country.venezuela',
  'ui.common.country.vietnam',
  'ui.common.country.yemen',
  'ui.common.country.zimbabwe',
];

export const CSV_ROW_LIMIT = 500;

export const DATADOG_RUM_CONFIGS: DatadogRumConfig[] = [
  {
    hostname: 'test.practitioner.insights.com',
    applicationId: '5ad9f839-08cc-4322-b89f-4bd1f89a019b',
    clientToken: 'pub9831b15b9248253e77a7d60af5e38aee',
  },
  {
    hostname: 'demo.practitioner.insights.com',
    applicationId: '3e218273-d1ce-44de-a9c8-f8484c70f13e',
    clientToken: 'pub886026adb6604abd6f925467caf26381',
  },
  {
    hostname: 'practitioner.insights.com',
    applicationId: 'bb107e60-e829-4a04-86b2-e9a987a219da',
    clientToken: 'pub2f1ec5c2e52e88004d250f4fe27f21ac',
  },
];

export const DEFAULT_ROUTE = '/dashboard';

export const EVENT_TYPES: EventTypes = {
  INSIGHTS_DISCOVERY_WORKSHOP: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovery-workshop',
    color: 'pink',
  },
  INSIGHTS_DISCOVERY_ACCREDITATION: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovery-accreditation',
    color: 'orange',
  },
  INSIGHTS_SELF_AWARE_LEADER: {
    textKey:
      'ui.event-management.events.create.event-type.insights-self-aware-leader',
    color: 'blue',
  },
  INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovering-leadership-effectiveness',
    color: 'blue',
  },
  INSIGHTS_DISCOVERY_FULL_CIRCLE: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovery-full-circle',
    color: 'darkOrange',
  },
  INSIGHTS_EXPLORE: {
    textKey: 'ui.event-management.events.create.event-type.insights-explore',
    color: 'blue',
  },
};

export const FREQUENCY_OPTIONS: FrequencyType[] = [
  {
    textKey: 'ui.event-management.events.create.frequency-type.daily',
    value: 'DAILY',
  },
  {
    textKey: 'ui.event-management.events.create.frequency-type.weekly',
    value: 'WEEKLY',
  },
  {
    textKey: 'ui.event-management.events.create.frequency-type.biweekly',
    value: 'BIWEEKLY',
  },
  {
    textKey: 'ui.event-management.events.create.frequency-type.never',
    value: 'NEVER',
  },
];

export const INSIGHTS_ORGANISATION_ID = 'insights';

export const INVITEE_OPTIONS: InviteeOptions = {
  ADDED: {
    textKey: 'ui.event-management.events.invitee.status.not-invited',
    color: 'grey',
  },
  INVITE_SENT: {
    textKey: 'ui.event-management.events.invitee.status.invited',
    color: 'orange',
  },
  READY_TO_PURCHASE: {
    textKey: 'ui.event-management.events.invitee.status.ready-to-purchase',
    color: 'pink',
  },
  PROFILE_GENERATED: {
    textKey: 'ui.event-management.events.invitee.status.profile-created',
    color: 'darkGreen',
  },
};

export const INVITEE_STATUSES: InviteeStatuses = {
  ...INVITEE_OPTIONS,
  INVITE_REQUESTED: {
    textKey: 'ui.event-management.events.invitee.status.not-invited',
    color: 'grey',
  },
};

export const JOB_TITLES: JobTitles = {
  CLIENT_PRACTITIONER: 'ui.event-management.job-title.client-practitioner.name',
  LICENSED_PRACTITIONER:
    'ui.event-management.job-title.licensed-practitioner.name',
  ADMIN: 'ui.event-management.job-title.admin.name',
  INSIGHTS_ADMIN: 'ui.event-management.job-title.super-admin.name',
};

export const ROLES = {
  SUPER_ADMIN: 'ui.event-management.users.roles.client-services',
  GROUP_MANAGER: 'ui.event-management.users.roles.group-manager',
  ADVANCED_USER: 'ui.event-management.users.roles.advanced-user',
  STANDARD: 'ui.event-management.users.roles.standard',
} as const;

export const ROLE_POSITION: RolePositions = [
  'SUPER_ADMIN',
  'GROUP_MANAGER',
  'ADVANCED_USER',
  'STANDARD',
].reduce((acc, role, idx) => ({ ...acc, [role]: idx }), {});

export const LICENSED_PRODUCTS: LicensedProductOption = {
  DISCOVERY_SELF_AWARE_PROFILE: {
    value: 'DISCOVERY_SELF_AWARE_PROFILE',
    label: 'ui.event-management.products.discovery-self-aware-profile',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_PERSONAL_PROFILE: {
    value: 'DISCOVERY_PERSONAL_PROFILE',
    label: 'ui.event-management.products.discovery-personal-profile',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE: {
    value: 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
    label:
      'ui.event-management.products.discovery-transformational-leadership-profile',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_FULL_CIRCLE_PROFILE: {
    value: 'DISCOVERY_FULL_CIRCLE_PROFILE',
    label: 'ui.event-management.products.discovery-full-circle',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_EXPLORE_PROFILE: {
    value: 'DISCOVERY_EXPLORE_PROFILE',
    label: 'ui.event-management.products.explore-profile',
    disabled: true,
    defaultSelected: true,
  },
};

export const LICENSED_PRODUCTS_ABREV: ProfileTypes = {
  DISCOVERY_PERSONAL_PROFILE:
    'ui.event-management.product-types.insights-discovery.abrev',
  DISCOVERY_PROFILE:
    'ui.event-management.product-types.insights-discovery.abrev',
  IDTL_PROFILE:
    'ui.event-management.product-types.insights-discovery-transformational-leadership.abrev',
  DFC_PROFILE:
    'ui.event-management.product-types.insights-discovery-full-circle.abrev',
  EXPLORE_PROFILE: 'ui.event-management.product-types.insights-explore.abrev',
  API_PROFILE: 'ui.event-management.product-types.insights-api.abrev',
};

export const PRIVACY_POLICY_URL =
  'https://www.insights.com/general-privacy-notice/';

export const PRODUCT_TYPES: ProductTypes = {
  DISCOVERY_SELF_AWARE_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-self-aware-profile',
  DISCOVERY_PERSONAL_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-personal-profile',
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-transformational-leadership-profile',
  DISCOVERY_FULL_CIRCLE_PROFILE:
    'ui.event-management.events.create.product-type.insights-full-circle-profile',
  DISCOVERY_EXPLORE_PROFILE:
    'ui.event-management.events.create.product-type.insights-explore-profile',
};

export const PROFILE_TYPES: ProfileTypes = {
  DISCOVERY_PERSONAL_PROFILE:
    'ui.event-management.profiles.discovery-personal-profile',
  DISCOVERY_PROFILE: 'ui.event-management.profiles.discovery-personal-profile',
  IDTL_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-transformational-leadership-profile',
  DFC_PROFILE:
    'ui.event-management.events.create.product-type.insights-full-circle-profile',
  EXPLORE_PROFILE:
    'ui.event-management.events.create.product-type.insights-explore-profile',
  API_PROFILE: 'ui.event-management.profiles.api-profile',
};

export const QUERY_KEY_ROOTS: QueryKeyRoots = {
  EVENT_DATA: 'eventData',
  EVENTS_DATA: 'eventsData',
  INVITEES_DATA: 'inviteesData',
  EVENT_TEAM_SCORES_DATA: 'eventTeamScoresData',
  EVENT_REMINDER_DATA: 'eventReminderData',
  PRACTITIONER_DATA: 'practitionerData',
  PRACTITIONERS_DATA: 'practitionersData',
  PRACTITIONER_CANCELLED_EVENTS: 'practitionerCancelledEvents',
  PRACTITIONER_ORGANISATION_GROUP_DATA: 'PractitionerOrganisationGroupsData',
  OPEN_PRACTITIONER_INVITES_DATA: 'openPractitionerInvitesData',
  PRACTITIONERS_SUMMARY_DATA: 'practitionersSummaryData',
  MY_TRANSACTIONS_DATA: 'myTransactionsData',
  MY_LATEST_TRANSACTIONS_DATA: 'myLatestTransactionsData',
  PRACTITIONER_PRIMARY_WALLET_DATA: 'practitionerPrimaryWalletData',
  TEAM_DATA: 'teamData',
  TEAM_LEARNERS_DATA: 'teamLearnersData',
  TEAMS_DATA: 'teamsData',
  MY_TEAMS_DATA: 'myTeamsData',
  TEAM_SCORES_DATA: 'teamScoresData',
  ORGANISATIONS_DATA: 'organisationsData',
  ORGANISATION_DATA: 'organisationData',
  ORGANISATION_EVENT_DATA: 'organisationEventsData',
  ORGANISATION_EVALUATOR_LINKS: 'organisationEvaluatorLinks',
  ORGANISATION_EVALUATOR_LINK_EVALUATIONS:
    'organisationEvaluatorLinkEvaluations',
  MY_ORGANISATION_DATA: 'myOrganisationData',
  LEARNER_DATA: 'learnerData',
  LEARNERS_DATA: 'learnersData',
  LEARNER_TEAM_DATA: 'learnerTeamData',
  PROFILES_DATA: 'profilesData',
  ARCHIVED_PROFILES_DATA: 'archivedProfilesData',
  EVALUATOR_PROFILES: 'evaluatorProfiles',
  ORGANISATION_GROUP_DATA: 'organisationGroupData',
  ORGANISATION_GROUPS_DATA: 'organisationGroupsData',
  ORGANISATIONS_ORGANISATION_GROUP_DATA: 'organisationsOrganisationGroupsData',
  CONTRIBUTORS_DATA: 'contributorsData',
  TEAM_WHEEL_SUMMARY: 'teamWheelSummary',
  SUPPORTED_ROLES_DATA: 'supportedRolesData',
  TEAM_COLOUR_COUNTS: 'teamColourCounts',
  INITIATE_SIGN_IN_EMAIL: 'email',
};

export const COLOUR_SCORE_COLOUR_POSITION: Record<string, number> =
  COLOUR_SCORE_COLOURS.reduce(
    (acc, colour, idx) => ({
      ...acc,
      [colour]: idx,
    }),
    {},
  );

export const TIMEZONES: string[] = [
  'GMT',
  'GMT+01:00',
  'GMT+02:00',
  'GMT+03:00',
  'GMT+03:30',
  'GMT+04:00',
  'GMT+05:00',
  'GMT+05:30',
  'GMT+06:00',
  'GMT+07:00',
  'GMT+08:00',
  'GMT+09:00',
  'GMT+09:30',
  'GMT+10:00',
  'GMT+11:00',
  'GMT+12:00',
  'GMT-11:00',
  'GMT-10:00',
  'GMT-09:00',
  'GMT-08:00',
  'GMT-07:00',
  'GMT-06:00',
  'GMT-05:00',
  'GMT-04:00',
  'GMT-03:00',
  'GMT-02:00',
  'GMT-01:00',
];

export const VALID_FULL_NAME_REGEX = /.+/;
export const VALID_EMAIL_REGEX = /^[!-~]+@\S+\.[a-zA-Z0-9]+$/;
export const NO_SPECIAL_CHARACTERS_REGEX = /^[^!#$%&()*+/:;<=>?@[\]^_`{|}~]*$/;
export const NUMERICAL_ONLY_REGEX = /^[0-9]+$/;

export const ARBITRARY_MAX_LENGTH = 100;

export const QUERY_STATUS: Record<Uppercase<MutationStatus>, MutationStatus> = {
  SUCCESS: 'success',
  PENDING: 'pending',
  ERROR: 'error',
  IDLE: 'idle',
};

export const TEAM_WHEEL_CENTER_COORDS = { x: 260, y: 260 };

export const TEAM_WHEEL_SCORE_THRESHOLD = 150;

export const MINIMUM_SEARCH_CHARACTER_LIMIT = 2;

export const TIMEZONE_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'ui.shared.timezone.africa-abidjan',
    value: 'Africa/Abidjan',
  },
  {
    label: 'ui.shared.timezone.africa-accra',
    value: 'Africa/Accra',
  },
  {
    label: 'ui.shared.timezone.africa-addis_ababa',
    value: 'Africa/Addis_Ababa',
  },
  {
    label: 'ui.shared.timezone.africa-algiers',
    value: 'Africa/Algiers',
  },
  {
    label: 'ui.shared.timezone.africa-asmara',
    value: 'Africa/Asmara',
  },
  {
    label: 'ui.shared.timezone.africa-bamako',
    value: 'Africa/Bamako',
  },
  {
    label: 'ui.shared.timezone.africa-bangui',
    value: 'Africa/Bangui',
  },
  {
    label: 'ui.shared.timezone.africa-banjul',
    value: 'Africa/Banjul',
  },
  {
    label: 'ui.shared.timezone.africa-bissau',
    value: 'Africa/Bissau',
  },
  {
    label: 'ui.shared.timezone.africa-blantyre',
    value: 'Africa/Blantyre',
  },
  {
    label: 'ui.shared.timezone.africa-brazzaville',
    value: 'Africa/Brazzaville',
  },
  {
    label: 'ui.shared.timezone.africa-bujumbura',
    value: 'Africa/Bujumbura',
  },
  {
    label: 'ui.shared.timezone.africa-cairo',
    value: 'Africa/Cairo',
  },
  {
    label: 'ui.shared.timezone.africa-casablanca',
    value: 'Africa/Casablanca',
  },
  {
    label: 'ui.shared.timezone.africa-ceuta',
    value: 'Africa/Ceuta',
  },
  {
    label: 'ui.shared.timezone.africa-conakry',
    value: 'Africa/Conakry',
  },
  {
    label: 'ui.shared.timezone.africa-dakar',
    value: 'Africa/Dakar',
  },
  {
    label: 'ui.shared.timezone.africa-dar_es_salaam',
    value: 'Africa/Dar_es_Salaam',
  },
  {
    label: 'ui.shared.timezone.africa-djibouti',
    value: 'Africa/Djibouti',
  },
  {
    label: 'ui.shared.timezone.africa-douala',
    value: 'Africa/Douala',
  },
  {
    label: 'ui.shared.timezone.africa-el_aaiun',
    value: 'Africa/El_Aaiun',
  },
  {
    label: 'ui.shared.timezone.africa-freetown',
    value: 'Africa/Freetown',
  },
  {
    label: 'ui.shared.timezone.africa-gaborone',
    value: 'Africa/Gaborone',
  },
  {
    label: 'ui.shared.timezone.africa-harare',
    value: 'Africa/Harare',
  },
  {
    label: 'ui.shared.timezone.africa-johannesburg',
    value: 'Africa/Johannesburg',
  },
  {
    label: 'ui.shared.timezone.africa-juba',
    value: 'Africa/Juba',
  },
  {
    label: 'ui.shared.timezone.africa-kampala',
    value: 'Africa/Kampala',
  },
  {
    label: 'ui.shared.timezone.africa-khartoum',
    value: 'Africa/Khartoum',
  },
  {
    label: 'ui.shared.timezone.africa-kigali',
    value: 'Africa/Kigali',
  },
  {
    label: 'ui.shared.timezone.africa-kinshasa',
    value: 'Africa/Kinshasa',
  },
  {
    label: 'ui.shared.timezone.africa-lagos',
    value: 'Africa/Lagos',
  },
  {
    label: 'ui.shared.timezone.africa-libreville',
    value: 'Africa/Libreville',
  },
  {
    label: 'ui.shared.timezone.africa-lome',
    value: 'Africa/Lome',
  },
  {
    label: 'ui.shared.timezone.africa-luanda',
    value: 'Africa/Luanda',
  },
  {
    label: 'ui.shared.timezone.africa-lubumbashi',
    value: 'Africa/Lubumbashi',
  },
  {
    label: 'ui.shared.timezone.africa-lusaka',
    value: 'Africa/Lusaka',
  },
  {
    label: 'ui.shared.timezone.africa-malabo',
    value: 'Africa/Malabo',
  },
  {
    label: 'ui.shared.timezone.africa-maputo',
    value: 'Africa/Maputo',
  },
  {
    label: 'ui.shared.timezone.africa-maseru',
    value: 'Africa/Maseru',
  },
  {
    label: 'ui.shared.timezone.africa-mbabane',
    value: 'Africa/Mbabane',
  },
  {
    label: 'ui.shared.timezone.africa-mogadishu',
    value: 'Africa/Mogadishu',
  },
  {
    label: 'ui.shared.timezone.africa-monrovia',
    value: 'Africa/Monrovia',
  },
  {
    label: 'ui.shared.timezone.africa-nairobi',
    value: 'Africa/Nairobi',
  },
  {
    label: 'ui.shared.timezone.africa-ndjamena',
    value: 'Africa/Ndjamena',
  },
  {
    label: 'ui.shared.timezone.africa-niamey',
    value: 'Africa/Niamey',
  },
  {
    label: 'ui.shared.timezone.africa-nouakchott',
    value: 'Africa/Nouakchott',
  },
  {
    label: 'ui.shared.timezone.africa-ouagadougou',
    value: 'Africa/Ouagadougou',
  },
  {
    label: 'ui.shared.timezone.africa-porto-novo',
    value: 'Africa/Porto-Novo',
  },
  {
    label: 'ui.shared.timezone.africa-sao_tome',
    value: 'Africa/Sao_Tome',
  },
  {
    label: 'ui.shared.timezone.africa-timbuktu',
    value: 'Africa/Timbuktu',
  },
  {
    label: 'ui.shared.timezone.africa-tripoli',
    value: 'Africa/Tripoli',
  },
  {
    label: 'ui.shared.timezone.africa-tunis',
    value: 'Africa/Tunis',
  },
  {
    label: 'ui.shared.timezone.africa-windhoek',
    value: 'Africa/Windhoek',
  },
  {
    label: 'ui.shared.timezone.america-adak',
    value: 'America/Adak',
  },
  {
    label: 'ui.shared.timezone.america-anchorage',
    value: 'America/Anchorage',
  },
  {
    label: 'ui.shared.timezone.america-anguilla',
    value: 'America/Anguilla',
  },
  {
    label: 'ui.shared.timezone.america-antigua',
    value: 'America/Antigua',
  },
  {
    label: 'ui.shared.timezone.america-araguaina',
    value: 'America/Araguaina',
  },
  {
    label: 'ui.shared.timezone.america-argentina-buenos_aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    label: 'ui.shared.timezone.america-argentina-catamarca',
    value: 'America/Argentina/Catamarca',
  },
  {
    label: 'ui.shared.timezone.america-argentina-comodrivadavia',
    value: 'America/Argentina/ComodRivadavia',
  },
  {
    label: 'ui.shared.timezone.america-argentina-cordoba',
    value: 'America/Argentina/Cordoba',
  },
  {
    label: 'ui.shared.timezone.america-argentina-jujuy',
    value: 'America/Argentina/Jujuy',
  },
  {
    label: 'ui.shared.timezone.america-argentina-la_rioja',
    value: 'America/Argentina/La_Rioja',
  },
  {
    label: 'ui.shared.timezone.america-argentina-mendoza',
    value: 'America/Argentina/Mendoza',
  },
  {
    label: 'ui.shared.timezone.america-argentina-rio_gallegos',
    value: 'America/Argentina/Rio_Gallegos',
  },
  {
    label: 'ui.shared.timezone.america-argentina-salta',
    value: 'America/Argentina/Salta',
  },
  {
    label: 'ui.shared.timezone.america-argentina-san_juan',
    value: 'America/Argentina/San_Juan',
  },
  {
    label: 'ui.shared.timezone.america-argentina-san_luis',
    value: 'America/Argentina/San_Luis',
  },
  {
    label: 'ui.shared.timezone.america-argentina-tucuman',
    value: 'America/Argentina/Tucuman',
  },
  {
    label: 'ui.shared.timezone.america-argentina-ushuaia',
    value: 'America/Argentina/Ushuaia',
  },
  {
    label: 'ui.shared.timezone.america-aruba',
    value: 'America/Aruba',
  },
  {
    label: 'ui.shared.timezone.america-asuncion',
    value: 'America/Asuncion',
  },
  {
    label: 'ui.shared.timezone.america-atikokan',
    value: 'America/Atikokan',
  },
  {
    label: 'ui.shared.timezone.america-bahia',
    value: 'America/Bahia',
  },
  {
    label: 'ui.shared.timezone.america-bahia_banderas',
    value: 'America/Bahia_Banderas',
  },
  {
    label: 'ui.shared.timezone.america-barbados',
    value: 'America/Barbados',
  },
  {
    label: 'ui.shared.timezone.america-belem',
    value: 'America/Belem',
  },
  {
    label: 'ui.shared.timezone.america-belize',
    value: 'America/Belize',
  },
  {
    label: 'ui.shared.timezone.america-blanc-sablon',
    value: 'America/Blanc-Sablon',
  },
  {
    label: 'ui.shared.timezone.america-boa_vista',
    value: 'America/Boa_Vista',
  },
  {
    label: 'ui.shared.timezone.america-bogota',
    value: 'America/Bogota',
  },
  {
    label: 'ui.shared.timezone.america-boise',
    value: 'America/Boise',
  },
  {
    label: 'ui.shared.timezone.america-cambridge_bay',
    value: 'America/Cambridge_Bay',
  },
  {
    label: 'ui.shared.timezone.america-campo_grande',
    value: 'America/Campo_Grande',
  },
  {
    label: 'ui.shared.timezone.america-cancun',
    value: 'America/Cancun',
  },
  {
    label: 'ui.shared.timezone.america-caracas',
    value: 'America/Caracas',
  },
  {
    label: 'ui.shared.timezone.america-cayenne',
    value: 'America/Cayenne',
  },
  {
    label: 'ui.shared.timezone.america-cayman',
    value: 'America/Cayman',
  },
  {
    label: 'ui.shared.timezone.america-chicago',
    value: 'America/Chicago',
  },
  {
    label: 'ui.shared.timezone.america-chihuahua',
    value: 'America/Chihuahua',
  },
  {
    label: 'ui.shared.timezone.america-ciudad_juarez',
    value: 'America/Ciudad_Juarez',
  },
  {
    label: 'ui.shared.timezone.america-coral_harbour',
    value: 'America/Coral_Harbour',
  },
  {
    label: 'ui.shared.timezone.america-costa_rica',
    value: 'America/Costa_Rica',
  },
  {
    label: 'ui.shared.timezone.america-creston',
    value: 'America/Creston',
  },
  {
    label: 'ui.shared.timezone.america-cuiaba',
    value: 'America/Cuiaba',
  },
  {
    label: 'ui.shared.timezone.america-curacao',
    value: 'America/Curacao',
  },
  {
    label: 'ui.shared.timezone.america-danmarkshavn',
    value: 'America/Danmarkshavn',
  },
  { label: 'ui.shared.timezone.america-dawson', value: 'America/Dawson' },
  {
    label: 'ui.shared.timezone.america-dawson_creek',
    value: 'America/Dawson_Creek',
  },
  { label: 'ui.shared.timezone.america-denver', value: 'America/Denver' },
  { label: 'ui.shared.timezone.america-detroit', value: 'America/Detroit' },
  { label: 'ui.shared.timezone.america-dominica', value: 'America/Dominica' },
  { label: 'ui.shared.timezone.america-edmonton', value: 'America/Edmonton' },
  { label: 'ui.shared.timezone.america-eirunepe', value: 'America/Eirunepe' },
  {
    label: 'ui.shared.timezone.america-el_salvador',
    value: 'America/El_Salvador',
  },
  { label: 'ui.shared.timezone.america-ensenada', value: 'America/Ensenada' },
  {
    label: 'ui.shared.timezone.america-fort_nelson',
    value: 'America/Fort_Nelson',
  },
  { label: 'ui.shared.timezone.america-fortaleza', value: 'America/Fortaleza' },
  { label: 'ui.shared.timezone.america-glace_bay', value: 'America/Glace_Bay' },
  { label: 'ui.shared.timezone.america-goose_bay', value: 'America/Goose_Bay' },
  {
    label: 'ui.shared.timezone.america-grand_turk',
    value: 'America/Grand_Turk',
  },
  { label: 'ui.shared.timezone.america-grenada', value: 'America/Grenada' },
  {
    label: 'ui.shared.timezone.america-guadeloupe',
    value: 'America/Guadeloupe',
  },
  { label: 'ui.shared.timezone.america-guatemala', value: 'America/Guatemala' },
  { label: 'ui.shared.timezone.america-guayaquil', value: 'America/Guayaquil' },
  { label: 'ui.shared.timezone.america-guyana', value: 'America/Guyana' },
  { label: 'ui.shared.timezone.america-halifax', value: 'America/Halifax' },
  { label: 'ui.shared.timezone.america-havana', value: 'America/Havana' },
  {
    label: 'ui.shared.timezone.america-hermosillo',
    value: 'America/Hermosillo',
  },
  {
    label: 'ui.shared.timezone.america-indiana-indianapolis',
    value: 'America/Indiana/Indianapolis',
  },
  {
    label: 'ui.shared.timezone.america-indiana-knox',
    value: 'America/Indiana/Knox',
  },
  {
    label: 'ui.shared.timezone.america-indiana-marengo',
    value: 'America/Indiana/Marengo',
  },
  {
    label: 'ui.shared.timezone.america-indiana-petersburg',
    value: 'America/Indiana/Petersburg',
  },
  {
    label: 'ui.shared.timezone.america-indiana-tell_city',
    value: 'America/Indiana/Tell_City',
  },
  {
    label: 'ui.shared.timezone.america-indiana-vevay',
    value: 'America/Indiana/Vevay',
  },
  {
    label: 'ui.shared.timezone.america-indiana-vincennes',
    value: 'America/Indiana/Vincennes',
  },
  {
    label: 'ui.shared.timezone.america-indiana-winamac',
    value: 'America/Indiana/Winamac',
  },
  { label: 'ui.shared.timezone.america-inuvik', value: 'America/Inuvik' },
  { label: 'ui.shared.timezone.america-iqaluit', value: 'America/Iqaluit' },
  { label: 'ui.shared.timezone.america-jamaica', value: 'America/Jamaica' },
  { label: 'ui.shared.timezone.america-juneau', value: 'America/Juneau' },
  {
    label: 'ui.shared.timezone.america-kentucky-louisville',
    value: 'America/Kentucky/Louisville',
  },
  {
    label: 'ui.shared.timezone.america-kentucky-monticello',
    value: 'America/Kentucky/Monticello',
  },
  { label: 'ui.shared.timezone.america-la_paz', value: 'America/La_Paz' },
  { label: 'ui.shared.timezone.america-lima', value: 'America/Lima' },
  {
    label: 'ui.shared.timezone.america-los_angeles',
    value: 'America/Los_Angeles',
  },
  { label: 'ui.shared.timezone.america-maceio', value: 'America/Maceio' },
  { label: 'ui.shared.timezone.america-managua', value: 'America/Managua' },
  { label: 'ui.shared.timezone.america-manaus', value: 'America/Manaus' },
  {
    label: 'ui.shared.timezone.america-martinique',
    value: 'America/Martinique',
  },
  { label: 'ui.shared.timezone.america-matamoros', value: 'America/Matamoros' },
  { label: 'ui.shared.timezone.america-mazatlan', value: 'America/Mazatlan' },
  { label: 'ui.shared.timezone.america-menominee', value: 'America/Menominee' },
  { label: 'ui.shared.timezone.america-merida', value: 'America/Merida' },
  {
    label: 'ui.shared.timezone.america-metlakatla',
    value: 'America/Metlakatla',
  },
  {
    label: 'ui.shared.timezone.america-mexico_city',
    value: 'America/Mexico_City',
  },
  { label: 'ui.shared.timezone.america-miquelon', value: 'America/Miquelon' },
  { label: 'ui.shared.timezone.america-moncton', value: 'America/Moncton' },
  { label: 'ui.shared.timezone.america-monterrey', value: 'America/Monterrey' },
  {
    label: 'ui.shared.timezone.america-montevideo',
    value: 'America/Montevideo',
  },
  { label: 'ui.shared.timezone.america-montreal', value: 'America/Montreal' },
  {
    label: 'ui.shared.timezone.america-montserrat',
    value: 'America/Montserrat',
  },
  { label: 'ui.shared.timezone.america-nassau', value: 'America/Nassau' },
  { label: 'ui.shared.timezone.america-new_york', value: 'America/New_York' },
  { label: 'ui.shared.timezone.america-nipigon', value: 'America/Nipigon' },
  { label: 'ui.shared.timezone.america-nome', value: 'America/Nome' },
  { label: 'ui.shared.timezone.america-noronha', value: 'America/Noronha' },
  {
    label: 'ui.shared.timezone.america-north_dakota-beulah',
    value: 'America/North_Dakota/Beulah',
  },
  {
    label: 'ui.shared.timezone.america-north_dakota-center',
    value: 'America/North_Dakota/Center',
  },
  {
    label: 'ui.shared.timezone.america-north_dakota-new_salem',
    value: 'America/North_Dakota/New_Salem',
  },
  { label: 'ui.shared.timezone.america-nuuk', value: 'America/Nuuk' },
  { label: 'ui.shared.timezone.america-ojinaga', value: 'America/Ojinaga' },
  { label: 'ui.shared.timezone.america-panama', value: 'America/Panama' },
  {
    label: 'ui.shared.timezone.america-pangnirtung',
    value: 'America/Pangnirtung',
  },
  {
    label: 'ui.shared.timezone.america-paramaribo',
    value: 'America/Paramaribo',
  },
  { label: 'ui.shared.timezone.america-phoenix', value: 'America/Phoenix' },
  {
    label: 'ui.shared.timezone.america-port_of_spain',
    value: 'America/Port_of_Spain',
  },
  {
    label: 'ui.shared.timezone.america-port-au-prince',
    value: 'America/Port-au-Prince',
  },
  {
    label: 'ui.shared.timezone.america-porto_velho',
    value: 'America/Porto_Velho',
  },
  {
    label: 'ui.shared.timezone.america-puerto_rico',
    value: 'America/Puerto_Rico',
  },
  {
    label: 'ui.shared.timezone.america-punta_arenas',
    value: 'America/Punta_Arenas',
  },
  {
    label: 'ui.shared.timezone.america-rainy_river',
    value: 'America/Rainy_River',
  },
  {
    label: 'ui.shared.timezone.america-rankin_inlet',
    value: 'America/Rankin_Inlet',
  },
  { label: 'ui.shared.timezone.america-recife', value: 'America/Recife' },
  { label: 'ui.shared.timezone.america-regina', value: 'America/Regina' },
  { label: 'ui.shared.timezone.america-resolute', value: 'America/Resolute' },
  {
    label: 'ui.shared.timezone.america-rio_branco',
    value: 'America/Rio_Branco',
  },
  { label: 'ui.shared.timezone.america-rosario', value: 'America/Rosario' },
  { label: 'ui.shared.timezone.america-santarem', value: 'America/Santarem' },
  { label: 'ui.shared.timezone.america-santiago', value: 'America/Santiago' },
  {
    label: 'ui.shared.timezone.america-santo_domingo',
    value: 'America/Santo_Domingo',
  },
  { label: 'ui.shared.timezone.america-sao_paulo', value: 'America/Sao_Paulo' },
  {
    label: 'ui.shared.timezone.america-scoresbysund',
    value: 'America/Scoresbysund',
  },
  { label: 'ui.shared.timezone.america-sitka', value: 'America/Sitka' },
  { label: 'ui.shared.timezone.america-st_johns', value: 'America/St_Johns' },
  { label: 'ui.shared.timezone.america-st_kitts', value: 'America/St_Kitts' },
  { label: 'ui.shared.timezone.america-st_lucia', value: 'America/St_Lucia' },
  { label: 'ui.shared.timezone.america-st_thomas', value: 'America/St_Thomas' },
  {
    label: 'ui.shared.timezone.america-st_vincent',
    value: 'America/St_Vincent',
  },
  {
    label: 'ui.shared.timezone.america-swift_current',
    value: 'America/Swift_Current',
  },
  {
    label: 'ui.shared.timezone.america-tegucigalpa',
    value: 'America/Tegucigalpa',
  },
  { label: 'ui.shared.timezone.america-thule', value: 'America/Thule' },
  {
    label: 'ui.shared.timezone.america-thunder_bay',
    value: 'America/Thunder_Bay',
  },
  { label: 'ui.shared.timezone.america-tijuana', value: 'America/Tijuana' },
  { label: 'ui.shared.timezone.america-toronto', value: 'America/Toronto' },
  { label: 'ui.shared.timezone.america-tortola', value: 'America/Tortola' },
  { label: 'ui.shared.timezone.america-vancouver', value: 'America/Vancouver' },

  {
    label: 'ui.shared.timezone.america-whitehorse',
    value: 'America/Whitehorse',
  },
  { label: 'ui.shared.timezone.america-winnipeg', value: 'America/Winnipeg' },
  { label: 'ui.shared.timezone.america-yakutat', value: 'America/Yakutat' },
  {
    label: 'ui.shared.timezone.america-yellowknife',
    value: 'America/Yellowknife',
  },
  { label: 'ui.shared.timezone.antarctica-casey', value: 'Antarctica/Casey' },
  { label: 'ui.shared.timezone.antarctica-davis', value: 'Antarctica/Davis' },
  {
    label: 'ui.shared.timezone.antarctica-dumontdurville',
    value: 'Antarctica/DumontDUrville',
  },
  {
    label: 'ui.shared.timezone.antarctica-macquarie',
    value: 'Antarctica/Macquarie',
  },
  { label: 'ui.shared.timezone.antarctica-mawson', value: 'Antarctica/Mawson' },
  {
    label: 'ui.shared.timezone.antarctica-mcmurdo',
    value: 'Antarctica/McMurdo',
  },
  { label: 'ui.shared.timezone.antarctica-palmer', value: 'Antarctica/Palmer' },
  {
    label: 'ui.shared.timezone.antarctica-rothera',
    value: 'Antarctica/Rothera',
  },
  { label: 'ui.shared.timezone.antarctica-syowa', value: 'Antarctica/Syowa' },
  { label: 'ui.shared.timezone.antarctica-troll', value: 'Antarctica/Troll' },
  { label: 'ui.shared.timezone.antarctica-vostok', value: 'Antarctica/Vostok' },
  { label: 'ui.shared.timezone.asia-aden', value: 'Asia/Aden' },
  { label: 'ui.shared.timezone.asia-almaty', value: 'Asia/Almaty' },
  { label: 'ui.shared.timezone.asia-amman', value: 'Asia/Amman' },
  { label: 'ui.shared.timezone.asia-anadyr', value: 'Asia/Anadyr' },
  { label: 'ui.shared.timezone.asia-aqtau', value: 'Asia/Aqtau' },
  { label: 'ui.shared.timezone.asia-aqtobe', value: 'Asia/Aqtobe' },
  { label: 'ui.shared.timezone.asia-ashgabat', value: 'Asia/Ashgabat' },
  { label: 'ui.shared.timezone.asia-atyrau', value: 'Asia/Atyrau' },
  { label: 'ui.shared.timezone.asia-baghdad', value: 'Asia/Baghdad' },
  { label: 'ui.shared.timezone.asia-bahrain', value: 'Asia/Bahrain' },
  { label: 'ui.shared.timezone.asia-baku', value: 'Asia/Baku' },
  { label: 'ui.shared.timezone.asia-bangkok', value: 'Asia/Bangkok' },
  { label: 'ui.shared.timezone.asia-barnaul', value: 'Asia/Barnaul' },
  { label: 'ui.shared.timezone.asia-beirut', value: 'Asia/Beirut' },
  { label: 'ui.shared.timezone.asia-bishkek', value: 'Asia/Bishkek' },
  { label: 'ui.shared.timezone.asia-brunei', value: 'Asia/Brunei' },
  { label: 'ui.shared.timezone.asia-chita', value: 'Asia/Chita' },
  { label: 'ui.shared.timezone.asia-choibalsan', value: 'Asia/Choibalsan' },
  { label: 'ui.shared.timezone.asia-chongqing', value: 'Asia/Chongqing' },
  { label: 'ui.shared.timezone.asia-colombo', value: 'Asia/Colombo' },
  { label: 'ui.shared.timezone.asia-damascus', value: 'Asia/Damascus' },
  { label: 'ui.shared.timezone.asia-dhaka', value: 'Asia/Dhaka' },
  { label: 'ui.shared.timezone.asia-dili', value: 'Asia/Dili' },
  { label: 'ui.shared.timezone.asia-dubai', value: 'Asia/Dubai' },
  { label: 'ui.shared.timezone.asia-dushanbe', value: 'Asia/Dushanbe' },
  { label: 'ui.shared.timezone.asia-famagusta', value: 'Asia/Famagusta' },
  { label: 'ui.shared.timezone.asia-gaza', value: 'Asia/Gaza' },
  { label: 'ui.shared.timezone.asia-harbin', value: 'Asia/Harbin' },
  { label: 'ui.shared.timezone.asia-hebron', value: 'Asia/Hebron' },
  { label: 'ui.shared.timezone.asia-ho_chi_minh', value: 'Asia/Ho_Chi_Minh' },
  { label: 'ui.shared.timezone.asia-hong_kong', value: 'Asia/Hong_Kong' },
  { label: 'ui.shared.timezone.asia-hovd', value: 'Asia/Hovd' },
  { label: 'ui.shared.timezone.asia-irkutsk', value: 'Asia/Irkutsk' },
  { label: 'ui.shared.timezone.asia-jakarta', value: 'Asia/Jakarta' },
  { label: 'ui.shared.timezone.asia-jayapura', value: 'Asia/Jayapura' },

  { label: 'ui.shared.timezone.asia-jerusalem', value: 'Asia/Jerusalem' },
  { label: 'ui.shared.timezone.asia-kabul', value: 'Asia/Kabul' },
  { label: 'ui.shared.timezone.asia-kamchatka', value: 'Asia/Kamchatka' },
  { label: 'ui.shared.timezone.asia-karachi', value: 'Asia/Karachi' },
  { label: 'ui.shared.timezone.asia-kashgar', value: 'Asia/Kashgar' },
  { label: 'ui.shared.timezone.asia-kathmandu', value: 'Asia/Kathmandu' },
  { label: 'ui.shared.timezone.asia-khandyga', value: 'Asia/Khandyga' },
  { label: 'ui.shared.timezone.asia-kolkata', value: 'Asia/Kolkata' },
  { label: 'ui.shared.timezone.asia-krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { label: 'ui.shared.timezone.asia-kuala_lumpur', value: 'Asia/Kuala_Lumpur' },
  { label: 'ui.shared.timezone.asia-kuching', value: 'Asia/Kuching' },
  { label: 'ui.shared.timezone.asia-kuwait', value: 'Asia/Kuwait' },
  { label: 'ui.shared.timezone.asia-macau', value: 'Asia/Macau' },
  { label: 'ui.shared.timezone.asia-magadan', value: 'Asia/Magadan' },
  { label: 'ui.shared.timezone.asia-makassar', value: 'Asia/Makassar' },
  { label: 'ui.shared.timezone.asia-manila', value: 'Asia/Manila' },
  { label: 'ui.shared.timezone.asia-muscat', value: 'Asia/Muscat' },
  { label: 'ui.shared.timezone.asia-nicosia', value: 'Asia/Nicosia' },
  { label: 'ui.shared.timezone.asia-novokuznetsk', value: 'Asia/Novokuznetsk' },
  { label: 'ui.shared.timezone.asia-novosibirsk', value: 'Asia/Novosibirsk' },
  { label: 'ui.shared.timezone.asia-omsk', value: 'Asia/Omsk' },
  { label: 'ui.shared.timezone.asia-oral', value: 'Asia/Oral' },
  { label: 'ui.shared.timezone.asia-phnom_penh', value: 'Asia/Phnom_Penh' },
  { label: 'ui.shared.timezone.asia-pontianak', value: 'Asia/Pontianak' },
  { label: 'ui.shared.timezone.asia-pyongyang', value: 'Asia/Pyongyang' },
  { label: 'ui.shared.timezone.asia-qatar', value: 'Asia/Qatar' },
  { label: 'ui.shared.timezone.asia-qostanay', value: 'Asia/Qostanay' },
  { label: 'ui.shared.timezone.asia-qyzylorda', value: 'Asia/Qyzylorda' },
  { label: 'ui.shared.timezone.asia-riyadh', value: 'Asia/Riyadh' },
  { label: 'ui.shared.timezone.asia-sakhalin', value: 'Asia/Sakhalin' },
  { label: 'ui.shared.timezone.asia-samarkand', value: 'Asia/Samarkand' },
  { label: 'ui.shared.timezone.asia-seoul', value: 'Asia/Seoul' },
  { label: 'ui.shared.timezone.asia-shanghai', value: 'Asia/Shanghai' },
  { label: 'ui.shared.timezone.asia-singapore', value: 'Asia/Singapore' },
  {
    label: 'ui.shared.timezone.asia-srednekolymsk',
    value: 'Asia/Srednekolymsk',
  },
  { label: 'ui.shared.timezone.asia-taipei', value: 'Asia/Taipei' },
  { label: 'ui.shared.timezone.asia-tashkent', value: 'Asia/Tashkent' },
  { label: 'ui.shared.timezone.asia-tbilisi', value: 'Asia/Tbilisi' },
  { label: 'ui.shared.timezone.asia-tehran', value: 'Asia/Tehran' },
  { label: 'ui.shared.timezone.asia-tel_aviv', value: 'Asia/Tel_Aviv' },
  { label: 'ui.shared.timezone.asia-thimphu', value: 'Asia/Thimphu' },
  { label: 'ui.shared.timezone.asia-tokyo', value: 'Asia/Tokyo' },
  { label: 'ui.shared.timezone.asia-tomsk', value: 'Asia/Tomsk' },
  { label: 'ui.shared.timezone.asia-ulaanbaatar', value: 'Asia/Ulaanbaatar' },
  { label: 'ui.shared.timezone.asia-urumqi', value: 'Asia/Urumqi' },
  { label: 'ui.shared.timezone.asia-ust-nera', value: 'Asia/Ust-Nera' },
  { label: 'ui.shared.timezone.asia-vientiane', value: 'Asia/Vientiane' },
  { label: 'ui.shared.timezone.asia-vladivostok', value: 'Asia/Vladivostok' },
  { label: 'ui.shared.timezone.asia-yakutsk', value: 'Asia/Yakutsk' },
  { label: 'ui.shared.timezone.asia-yangon', value: 'Asia/Yangon' },
  {
    label: 'ui.shared.timezone.asia-yekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  { label: 'ui.shared.timezone.asia-yerevan', value: 'Asia/Yerevan' },
  { label: 'ui.shared.timezone.atlantic-azores', value: 'Atlantic/Azores' },
  { label: 'ui.shared.timezone.atlantic-bermuda', value: 'Atlantic/Bermuda' },
  { label: 'ui.shared.timezone.atlantic-canary', value: 'Atlantic/Canary' },
  {
    label: 'ui.shared.timezone.atlantic-cape_verde',
    value: 'Atlantic/Cape_Verde',
  },
  { label: 'ui.shared.timezone.atlantic-faroe', value: 'Atlantic/Faroe' },
  {
    label: 'ui.shared.timezone.atlantic-jan_mayen',
    value: 'Atlantic/Jan_Mayen',
  },
  { label: 'ui.shared.timezone.atlantic-madeira', value: 'Atlantic/Madeira' },
  {
    label: 'ui.shared.timezone.atlantic-reykjavik',
    value: 'Atlantic/Reykjavik',
  },
  {
    label: 'ui.shared.timezone.atlantic-south_georgia',
    value: 'Atlantic/South_Georgia',
  },
  {
    label: 'ui.shared.timezone.atlantic-st_helena',
    value: 'Atlantic/St_Helena',
  },
  { label: 'ui.shared.timezone.atlantic-stanley', value: 'Atlantic/Stanley' },
  {
    label: 'ui.shared.timezone.australia-adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: 'ui.shared.timezone.australia-brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: 'ui.shared.timezone.australia-broken_hill',
    value: 'Australia/Broken_Hill',
  },
  { label: 'ui.shared.timezone.australia-currie', value: 'Australia/Currie' },
  { label: 'ui.shared.timezone.australia-darwin', value: 'Australia/Darwin' },
  { label: 'ui.shared.timezone.australia-eucla', value: 'Australia/Eucla' },
  { label: 'ui.shared.timezone.australia-hobart', value: 'Australia/Hobart' },
  {
    label: 'ui.shared.timezone.australia-lindeman',
    value: 'Australia/Lindeman',
  },
  {
    label: 'ui.shared.timezone.australia-lord_howe',
    value: 'Australia/Lord_Howe',
  },
  {
    label: 'ui.shared.timezone.australia-melbourne',
    value: 'Australia/Melbourne',
  },
  { label: 'ui.shared.timezone.australia-perth', value: 'Australia/Perth' },
  { label: 'ui.shared.timezone.australia-sydney', value: 'Australia/Sydney' },
  { label: 'ui.shared.timezone.cet', value: 'CET' },
  { label: 'ui.shared.timezone.cst6cdt', value: 'CST6CDT' },
  { label: 'ui.shared.timezone.eet', value: 'EET' },
  { label: 'ui.shared.timezone.est5edt', value: 'EST5EDT' },
  { label: 'ui.shared.timezone.etc-gmt+1', value: 'Etc/GMT+1' },
  { label: 'ui.shared.timezone.etc-gmt+10', value: 'Etc/GMT+10' },
  { label: 'ui.shared.timezone.etc-gmt+11', value: 'Etc/GMT+11' },
  { label: 'ui.shared.timezone.etc-gmt+12', value: 'Etc/GMT+12' },
  { label: 'ui.shared.timezone.etc-gmt+2', value: 'Etc/GMT+2' },
  { label: 'ui.shared.timezone.etc-gmt+3', value: 'Etc/GMT+3' },
  { label: 'ui.shared.timezone.etc-gmt+4', value: 'Etc/GMT+4' },
  { label: 'ui.shared.timezone.etc-gmt+5', value: 'Etc/GMT+5' },
  { label: 'ui.shared.timezone.etc-gmt+6', value: 'Etc/GMT+6' },
  { label: 'ui.shared.timezone.etc-gmt+7', value: 'Etc/GMT+7' },
  { label: 'ui.shared.timezone.etc-gmt+8', value: 'Etc/GMT+8' },
  { label: 'ui.shared.timezone.etc-gmt+9', value: 'Etc/GMT+9' },
  { label: 'ui.shared.timezone.etc-gmt-1', value: 'Etc/GMT-1' },
  { label: 'ui.shared.timezone.etc-gmt-10', value: 'Etc/GMT-10' },
  { label: 'ui.shared.timezone.etc-gmt-11', value: 'Etc/GMT-11' },
  { label: 'ui.shared.timezone.etc-gmt-12', value: 'Etc/GMT-12' },
  { label: 'ui.shared.timezone.etc-gmt-13', value: 'Etc/GMT-13' },
  { label: 'ui.shared.timezone.etc-gmt-14', value: 'Etc/GMT-14' },
  { label: 'ui.shared.timezone.etc-gmt-2', value: 'Etc/GMT-2' },
  { label: 'ui.shared.timezone.etc-gmt-3', value: 'Etc/GMT-3' },
  { label: 'ui.shared.timezone.etc-gmt-4', value: 'Etc/GMT-4' },
  { label: 'ui.shared.timezone.etc-gmt-5', value: 'Etc/GMT-5' },
  { label: 'ui.shared.timezone.etc-gmt-6', value: 'Etc/GMT-6' },
  { label: 'ui.shared.timezone.etc-gmt-7', value: 'Etc/GMT-7' },
  { label: 'ui.shared.timezone.etc-gmt-8', value: 'Etc/GMT-8' },
  { label: 'ui.shared.timezone.etc-gmt-9', value: 'Etc/GMT-9' },
  { label: 'ui.shared.timezone.europe-amsterdam', value: 'Europe/Amsterdam' },
  { label: 'ui.shared.timezone.europe-andorra', value: 'Europe/Andorra' },
  { label: 'ui.shared.timezone.europe-astrakhan', value: 'Europe/Astrakhan' },
  { label: 'ui.shared.timezone.europe-athens', value: 'Europe/Athens' },
  { label: 'ui.shared.timezone.europe-belfast', value: 'Europe/Belfast' },
  { label: 'ui.shared.timezone.europe-belgrade', value: 'Europe/Belgrade' },
  { label: 'ui.shared.timezone.europe-berlin', value: 'Europe/Berlin' },
  { label: 'ui.shared.timezone.europe-brussels', value: 'Europe/Brussels' },
  { label: 'ui.shared.timezone.europe-bucharest', value: 'Europe/Bucharest' },
  { label: 'ui.shared.timezone.europe-budapest', value: 'Europe/Budapest' },
  { label: 'ui.shared.timezone.europe-chisinau', value: 'Europe/Chisinau' },
  { label: 'ui.shared.timezone.europe-copenhagen', value: 'Europe/Copenhagen' },
  { label: 'ui.shared.timezone.europe-dublin', value: 'Europe/Dublin' },
  { label: 'ui.shared.timezone.europe-gibraltar', value: 'Europe/Gibraltar' },
  { label: 'ui.shared.timezone.europe-guernsey', value: 'Europe/Guernsey' },
  { label: 'ui.shared.timezone.europe-helsinki', value: 'Europe/Helsinki' },
  {
    label: 'ui.shared.timezone.europe-isle_of_man',
    value: 'Europe/Isle_of_Man',
  },
  { label: 'ui.shared.timezone.europe-istanbul', value: 'Europe/Istanbul' },
  { label: 'ui.shared.timezone.europe-jersey', value: 'Europe/Jersey' },
  {
    label: 'ui.shared.timezone.europe-kaliningrad',
    value: 'Europe/Kaliningrad',
  },
  { label: 'ui.shared.timezone.europe-kirov', value: 'Europe/Kirov' },
  { label: 'ui.shared.timezone.europe-kyiv', value: 'Europe/Kyiv' },
  { label: 'ui.shared.timezone.europe-lisbon', value: 'Europe/Lisbon' },
  { label: 'ui.shared.timezone.europe-ljubljana', value: 'Europe/Ljubljana' },
  { label: 'ui.shared.timezone.europe-london', value: 'Europe/London' },
  { label: 'ui.shared.timezone.europe-luxembourg', value: 'Europe/Luxembourg' },
  { label: 'ui.shared.timezone.europe-madrid', value: 'Europe/Madrid' },
  { label: 'ui.shared.timezone.europe-malta', value: 'Europe/Malta' },
  { label: 'ui.shared.timezone.europe-minsk', value: 'Europe/Minsk' },
  { label: 'ui.shared.timezone.europe-monaco', value: 'Europe/Monaco' },
  { label: 'ui.shared.timezone.europe-moscow', value: 'Europe/Moscow' },
  { label: 'ui.shared.timezone.europe-oslo', value: 'Europe/Oslo' },
  { label: 'ui.shared.timezone.europe-paris', value: 'Europe/Paris' },
  { label: 'ui.shared.timezone.europe-prague', value: 'Europe/Prague' },
  { label: 'ui.shared.timezone.europe-riga', value: 'Europe/Riga' },
  { label: 'ui.shared.timezone.europe-rome', value: 'Europe/Rome' },
  { label: 'ui.shared.timezone.europe-samara', value: 'Europe/Samara' },
  { label: 'ui.shared.timezone.europe-sarajevo', value: 'Europe/Sarajevo' },
  { label: 'ui.shared.timezone.europe-saratov', value: 'Europe/Saratov' },
  { label: 'ui.shared.timezone.europe-simferopol', value: 'Europe/Simferopol' },
  { label: 'ui.shared.timezone.europe-skopje', value: 'Europe/Skopje' },
  { label: 'ui.shared.timezone.europe-sofia', value: 'Europe/Sofia' },
  { label: 'ui.shared.timezone.europe-stockholm', value: 'Europe/Stockholm' },
  { label: 'ui.shared.timezone.europe-tallinn', value: 'Europe/Tallinn' },
  { label: 'ui.shared.timezone.europe-tirane', value: 'Europe/Tirane' },
  { label: 'ui.shared.timezone.europe-tiraspol', value: 'Europe/Tiraspol' },
  { label: 'ui.shared.timezone.europe-ulyanovsk', value: 'Europe/Ulyanovsk' },
  { label: 'ui.shared.timezone.europe-uzhgorod', value: 'Europe/Uzhgorod' },
  { label: 'ui.shared.timezone.europe-vaduz', value: 'Europe/Vaduz' },
  { label: 'ui.shared.timezone.europe-vienna', value: 'Europe/Vienna' },
  { label: 'ui.shared.timezone.europe-vilnius', value: 'Europe/Vilnius' },
  { label: 'ui.shared.timezone.europe-volgograd', value: 'Europe/Volgograd' },
  { label: 'ui.shared.timezone.europe-warsaw', value: 'Europe/Warsaw' },
  { label: 'ui.shared.timezone.europe-zagreb', value: 'Europe/Zagreb' },
  { label: 'ui.shared.timezone.europe-zaporozhye', value: 'Europe/Zaporozhye' },
  { label: 'ui.shared.timezone.europe-zurich', value: 'Europe/Zurich' },
  {
    label: 'ui.shared.timezone.indian-antananarivo',
    value: 'Indian/Antananarivo',
  },
  { label: 'ui.shared.timezone.indian-chagos', value: 'Indian/Chagos' },
  { label: 'ui.shared.timezone.indian-christmas', value: 'Indian/Christmas' },
  { label: 'ui.shared.timezone.indian-cocos', value: 'Indian/Cocos' },
  { label: 'ui.shared.timezone.indian-comoro', value: 'Indian/Comoro' },
  { label: 'ui.shared.timezone.indian-kerguelen', value: 'Indian/Kerguelen' },
  { label: 'ui.shared.timezone.indian-mahe', value: 'Indian/Mahe' },
  { label: 'ui.shared.timezone.indian-maldives', value: 'Indian/Maldives' },
  { label: 'ui.shared.timezone.indian-mauritius', value: 'Indian/Mauritius' },
  { label: 'ui.shared.timezone.indian-mayotte', value: 'Indian/Mayotte' },
  { label: 'ui.shared.timezone.indian-reunion', value: 'Indian/Reunion' },
  { label: 'ui.shared.timezone.met', value: 'MET' },
  { label: 'ui.shared.timezone.mst7mdt', value: 'MST7MDT' },
  { label: 'ui.shared.timezone.pacific-apia', value: 'Pacific/Apia' },
  { label: 'ui.shared.timezone.pacific-auckland', value: 'Pacific/Auckland' },
  {
    label: 'ui.shared.timezone.pacific-bougainville',
    value: 'Pacific/Bougainville',
  },
  { label: 'ui.shared.timezone.pacific-chatham', value: 'Pacific/Chatham' },
  { label: 'ui.shared.timezone.pacific-chuuk', value: 'Pacific/Chuuk' },
  { label: 'ui.shared.timezone.pacific-easter', value: 'Pacific/Easter' },
  { label: 'ui.shared.timezone.pacific-efate', value: 'Pacific/Efate' },
  { label: 'ui.shared.timezone.pacific-enderbury', value: 'Pacific/Enderbury' },
  { label: 'ui.shared.timezone.pacific-fakaofo', value: 'Pacific/Fakaofo' },
  { label: 'ui.shared.timezone.pacific-fiji', value: 'Pacific/Fiji' },
  { label: 'ui.shared.timezone.pacific-funafuti', value: 'Pacific/Funafuti' },
  { label: 'ui.shared.timezone.pacific-galapagos', value: 'Pacific/Galapagos' },
  { label: 'ui.shared.timezone.pacific-gambier', value: 'Pacific/Gambier' },
  {
    label: 'ui.shared.timezone.pacific-guadalcanal',
    value: 'Pacific/Guadalcanal',
  },
  { label: 'ui.shared.timezone.pacific-guam', value: 'Pacific/Guam' },
  { label: 'ui.shared.timezone.pacific-honolulu', value: 'Pacific/Honolulu' },
  { label: 'ui.shared.timezone.pacific-johnston', value: 'Pacific/Johnston' },
  { label: 'ui.shared.timezone.pacific-kanton', value: 'Pacific/Kanton' },
  {
    label: 'ui.shared.timezone.pacific-kiritimati',
    value: 'Pacific/Kiritimati',
  },
  { label: 'ui.shared.timezone.pacific-kosrae', value: 'Pacific/Kosrae' },
  { label: 'ui.shared.timezone.pacific-kwajalein', value: 'Pacific/Kwajalein' },
  { label: 'ui.shared.timezone.pacific-majuro', value: 'Pacific/Majuro' },
  { label: 'ui.shared.timezone.pacific-marquesas', value: 'Pacific/Marquesas' },
  { label: 'ui.shared.timezone.pacific-midway', value: 'Pacific/Midway' },
  { label: 'ui.shared.timezone.pacific-nauru', value: 'Pacific/Nauru' },
  { label: 'ui.shared.timezone.pacific-niue', value: 'Pacific/Niue' },
  { label: 'ui.shared.timezone.pacific-norfolk', value: 'Pacific/Norfolk' },
  { label: 'ui.shared.timezone.pacific-noumea', value: 'Pacific/Noumea' },
  { label: 'ui.shared.timezone.pacific-pago_pago', value: 'Pacific/Pago_Pago' },
  { label: 'ui.shared.timezone.pacific-palau', value: 'Pacific/Palau' },
  { label: 'ui.shared.timezone.pacific-pitcairn', value: 'Pacific/Pitcairn' },
  { label: 'ui.shared.timezone.pacific-pohnpei', value: 'Pacific/Pohnpei' },
  {
    label: 'ui.shared.timezone.pacific-port_moresby',
    value: 'Pacific/Port_Moresby',
  },
  { label: 'ui.shared.timezone.pacific-rarotonga', value: 'Pacific/Rarotonga' },
  { label: 'ui.shared.timezone.pacific-saipan', value: 'Pacific/Saipan' },
  { label: 'ui.shared.timezone.pacific-tahiti', value: 'Pacific/Tahiti' },
  { label: 'ui.shared.timezone.pacific-tarawa', value: 'Pacific/Tarawa' },
  { label: 'ui.shared.timezone.pacific-tongatapu', value: 'Pacific/Tongatapu' },
  { label: 'ui.shared.timezone.pacific-wake', value: 'Pacific/Wake' },
  { label: 'ui.shared.timezone.pacific-wallis', value: 'Pacific/Wallis' },
  { label: 'ui.shared.timezone.pst8pdt', value: 'PST8PDT' },
  { label: 'ui.shared.timezone.utc', value: 'UTC' },
  { label: 'ui.shared.timezone.wet', value: 'WET' },
];
