import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import {
  GroupResponse,
  OrganisationResponse,
  OrganisationSupportedRole,
  PractitionerData,
} from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import SectionHeader from 'components/SectionHeader';
import { isGroupManager, isSuperAdmin } from 'utils/role';
import { styled } from '@mui/material';
import isGroupResponse from 'utils/typeGuards/isGroupResponse';
import AccountOrganisationCard from 'components/OrganisationInfoCard/AccountOrganisationCard/AccountOrganisationCard';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import OrganisationInfoCardList from 'components/OrganisationInfoCardList';
import { sortGroupsAndOrgs, sortOrgs } from 'utils/mappers/sorting';
import { useBetaEnabled } from 'features';
import { TextV2 } from '@insights-ltd/design-library/components';
import { useAuth } from 'contexts/AuthContext';

type ItemType = 'Organisation' | 'Group';

type Props = {
  organisations: OrganisationResponse[];
  groups?: GroupResponse[];
  practitioner?: PractitionerData;
  allowDemotion?: boolean;
  onOrganisationRemove?: (id: string) => void;
  supportedRoles: OrganisationSupportedRole[];
};

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  width: theme.spacing(spacingSizeMap.M),
  height: theme.spacing(spacingSizeMap.M),
}));

const OrganisationInfoSection = ({
  organisations = [],
  practitioner,
  groups = [],
  allowDemotion = false,
  onOrganisationRemove,
  supportedRoles,
}: Props) => {
  const { t } = useTranslation();
  let displayedOrganisations: (OrganisationResponse | GroupResponse)[] = [
    ...organisations,
  ];
  const organisationsInGroups = createGroupOrganisationHashMap(groups);
  let showGroups = isGroupManager(practitioner?.roles);

  if (isSuperAdmin(practitioner?.roles)) {
    displayedOrganisations = organisations.filter((organisation) => {
      const organisationSupportedRoles = supportedRoles.find(
        (orgSupportedRoles) => {
          return orgSupportedRoles.organisationId === organisation.id;
        },
      );

      return (
        isSuperAdmin(organisationSupportedRoles?.supportedRoles) ||
        organisation.visibility === 'PRIVATE'
      );
    });

    showGroups = false;
  }

  if (isGroupManager(practitioner?.roles)) {
    displayedOrganisations = [
      ...displayedOrganisations.filter(({ id }) => !organisationsInGroups[id]),
      ...groups,
    ];
  }

  displayedOrganisations = displayedOrganisations.sort(sortOrgs);
  const { user } = useAuth();
  const checkRevokeAccess = (
    isPrivateOrg: boolean,
    organisationId: string,
    canRevokeAccess?: boolean,
  ) => {
    return isSuperAdmin(user?.roles) && user?.id === practitioner?.id
      ? isPrivateOrg && canRevokeAccess && organisationId !== 'insights'
      : displayedOrganisations.length > 1;
  };
  const items = displayedOrganisations
    .map((organisation) => {
      const { id, name } = organisation;
      if (isGroupResponse(organisation)) {
        return {
          id,
          name,
          orgCount: organisation.organisations.length,
          type: 'Group' as ItemType,
          groupType: organisation.type,
          allowDemotion: allowDemotion && displayedOrganisations.length > 1,
          onClick: () => {
            if (onOrganisationRemove) onOrganisationRemove(id);
          },
        };
      }
      return {
        id,
        name,
        type: 'Organisation' as ItemType,
        allowDemotion:
          allowDemotion &&
          checkRevokeAccess(
            organisation?.visibility === 'PRIVATE',
            organisation.id,
            organisation.canRevokeAccess,
          ),
        parent: organisationsInGroups[id],
        visibility: organisation?.visibility,
        canRevokeAccess: organisation?.canRevokeAccess,
        onClick: () => {
          if (onOrganisationRemove) onOrganisationRemove(id);
        },
      };
    })
    .sort(sortGroupsAndOrgs);

  let listElement;

  if (items.length > 6) {
    listElement = <OrganisationInfoCardList items={items} />;
  } else {
    listElement = items.map((organisation) => {
      const { id, name, orgCount, groupType, visibility, canRevokeAccess } =
        organisation;

      const isPrivateOrg = visibility === 'PRIVATE';
      if (groupType) {
        return (
          <AccountOrganisationCard
            key={id}
            id={id}
            name={name}
            type="Group"
            groupType={groupType}
            orgCount={orgCount}
            allowDemotion={allowDemotion}
          />
        );
      }

      const getRevokeAccess = () => {
        if (
          isSuperAdmin(user?.roles) &&
          isSuperAdmin(practitioner?.roles) &&
          organisation.visibility === 'PRIVATE'
        ) {
          return allowDemotion && canRevokeAccess;
        }
        return (
          allowDemotion &&
          organisation.id !== 'insights' &&
          displayedOrganisations.length > 1
        );
      };

      return (
        <AccountOrganisationCard
          key={id}
          id={id}
          name={name}
          type="Organisation"
          allowDemotion={getRevokeAccess()}
          parent={organisationsInGroups[id]}
          isPrivateOrg={isPrivateOrg}
          onClick={() => {
            if (onOrganisationRemove) onOrganisationRemove(id);
          }}
        />
      );
    });
  }

  return (
    <>
      <SectionHeader
        icon={<StyledOrganisationIcon />}
        title={
          showGroups
            ? t('ui.event-management.my-account.organisations-and-groups', {
                count: displayedOrganisations.length,
              })
            : t('ui.event-management.my-account.organisation')
        }
      />
      <Grid
        container
        sx={{
          alignItems: 'stretch',
          paddingTop: '0.5rem',
          paddingBottom: '1.5rem',
          '& > div': {
            paddingBottom: '1.0rem',
          },
          '& > div:nth-of-type(odd)': {
            paddingRight: '0.5rem',
          },
          '& > div:nth-of-type(even)': {
            paddingLeft: '0.5rem',
          },
        }}
      >
        {listElement}
        {useBetaEnabled('special-handling') &&
          practitioner?.id !== user?.id &&
          isSuperAdmin(user?.roles) && (
            <TextV2 variant="bodySmall">
              {t('ui.event-management.special-handling.user.info')}
            </TextV2>
          )}
      </Grid>
    </>
  );
};

export default OrganisationInfoSection;
