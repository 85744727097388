import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Box } from '@mui/material';
import { P, ButtonV2 } from '@insights-ltd/design-library/components';
import { ArchivedProfileResponse, useRestoreProfile } from 'api';
import PronounsDialectLabel from 'components/PronounsDialectLabel';
import { PROFILE_TYPES } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { isoToLocaleString } from 'utils/dates';
import { isExploreProfile } from 'domain/profile';
import Grid from '@mui/material/Grid';
import { useBetaEnabled } from 'features';
import ProfileRestoreDialog from 'components/ProfileRestoreDialog';
import { archiveReasonOptions } from '../../components/ProfileArchiveDialog';

type Props = {
  profile: ArchivedProfileResponse;
  learnerId: string;
  disabled?: boolean;
  organisationId: string;
};

const ProfileArchivedCard = ({
  profile,
  learnerId,
  disabled = false,
  organisationId,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const [restoreError, setRestoreError] = useState<boolean>(false);
  const isDFCProfile = profile.type === 'DFC_PROFILE';
  const disabledDFCProfileTile = isDFCProfile && profile.contributorCount === 0;
  const [showRestoreDialog, setShowRestoreDialog] = useState(false);
  const betaAvailable = useBetaEnabled('profile-archive');
  const { mutate: restoreProfile } = useRestoreProfile(
    learnerId,
    organisationId,
  );

  const profileTitle = `${t(PROFILE_TYPES[profile.type])} - 
    ${isoToLocaleString(profile.evaluationSubmittedOn, locale)}`;

  const handleRestoreProfile = () => {
    restoreProfile(
      {
        profileId: profile.id,
        organisationId,
        betaAvailable,
      },
      {
        onSuccess: () => {
          setShowRestoreDialog(false);
          setRestoreError(false);
        },
        onError: () => {
          setRestoreError(true);
        },
      },
    );
  };

  const handleProfileRestoreClose = () => {
    setShowRestoreDialog(false);
    setRestoreError(false);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={(theme) => ({
          display: 'flex',
          height: '100%',
          border: `1px solid ${theme.palette.grey[500]}`,
          backgroundColor:
            disabled || disabledDFCProfileTile
              ? theme.palette.grey[200]
              : 'undefined',
          borderColor:
            disabled || disabledDFCProfileTile ? 'transparent' : 'undefined',
          '& * p': {
            color:
              disabled || disabledDFCProfileTile
                ? theme.palette.grey[700]
                : 'undefined',
          },
        })}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            justifyContent: 'space-between',
            '& > div:last-of-type': {
              marginTop: 'auto',
            },
          }}
        >
          <Box mb={(theme) => theme.spacing(spacingSizeMap.L)}>
            <Box
              sx={{
                display: 'flex',
                flex: 0,
                '& > P': {
                  maxWidth: '15.563rem',
                },
              }}
            >
              <P variant="body-large" color="primary">
                {profileTitle}
              </P>
              <Box>
                {betaAvailable && (
                  <ButtonV2
                    sx={(theme) => ({
                      '&.MuiButtonBase-root': {
                        padding: '0.15rem 0 0 1rem',
                        minWidth: '1rem',
                        border: 'none',
                        color: theme.palette.error.main,
                        lineHeight: '1.625rem',
                        fontWeight: '600',
                        size: '1rem',
                      },
                    })}
                    buttonText={t(
                      'ui.event-management.learner.profiles.restore',
                    )}
                    buttonVariant="outlined"
                    onClick={() => setShowRestoreDialog(true)}
                  />
                )}
              </Box>
            </Box>

            <PronounsDialectLabel
              pronoun={profile.pronoun}
              dialect={profile.preferredProfileDialect}
            />

            {disabledDFCProfileTile && (
              <Grid
                sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}
              >
                <P variant="body-bold">
                  {t('ui.event-management.learner.profiles.zero-contributors')}
                </P>
                <P>
                  {t(
                    'ui.event-management.learner.profiles.profile-not-available',
                  )}
                </P>
              </Grid>
            )}
          </Box>
          <Box mt={(theme) => theme.spacing(spacingSizeMap.L)}>
            <Box
              sx={{ marginTop: isExploreProfile(profile) ? '1rem' : '2rem' }}
            >
              <b>
                {t('ui.event-management.learner.profiles.reason-for-archive')}:{' '}
                <Box
                  component="span"
                  sx={(theme) => ({
                    color: theme.palette.grey[600],
                    lineHeight: '1.75rem',
                    fontSize: '1rem',
                  })}
                >
                  {profile?.archiveReason === 'OTHER'
                    ? profile?.reasonOther
                    : t(
                        archiveReasonOptions[
                          archiveReasonOptions
                            .map((archiveReason) => archiveReason.value)
                            .indexOf(profile?.archiveReason)
                        ].label,
                      )}
                </Box>
              </b>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {showRestoreDialog && (
        <ProfileRestoreDialog
          open={showRestoreDialog}
          profileTitle={profileTitle}
          onCancel={() => handleProfileRestoreClose()}
          onSubmit={handleRestoreProfile}
          restoreError={restoreError}
        />
      )}
    </>
  );
};

export default ProfileArchivedCard;
