import React from 'react';
import Box from '@mui/material/Box';
import { Dialog, Span } from '@insights-ltd/design-library/components';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  open: boolean;
  profileTitle: string;
  onCancel: () => void;
  onSubmit: () => void;
  restoreError: boolean;
};

const ProfileRestoreDialog = ({
  open,
  profileTitle,
  onCancel,
  onSubmit,
  restoreError,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onSubmit={onSubmit}
      onClose={() => onCancel()}
      open={open}
      content={
        <>
          {!restoreError && (
            <Box>
              <Span>
                <Trans
                  i18nKey="ui.event-management.learner.profiles.restore.confirmation"
                  components={{ bold: <Span variant="body-bold" /> }}
                  values={{ title: profileTitle }}
                />
              </Span>
            </Box>
          )}
          {restoreError && <Box>Profile failed to archive!</Box>}
        </>
      }
      primaryButtonText={
        !restoreError
          ? t('ui.event-management.learner.profiles.restore.submit.button')
          : ''
      }
      secondaryButtonText={
        !restoreError
          ? t('ui.event-management.learner.profiles.restore.cancel.button')
          : 'Close'
      }
      title={t('ui.event-management.learner.profiles.restore.title')}
    />
  );
};

export default ProfileRestoreDialog;
