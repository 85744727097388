import { authenticatedFetch } from 'api/fetch';
import {
  GetGroupResponse,
  Group,
  GroupResponse,
  OrganisationResponse,
  PractitionerData,
} from 'api/httpEntities';
import { sortOrgsAlphabetically } from '@insights-ltd/design-library/utils';
import { OrganisationVisibility } from 'api/organisations/organisationApitypes';

export const createGroup = async (
  groupRequest: Omit<Group, 'id'>,
): Promise<Group> => {
  const { json } = await authenticatedFetch<Group>(
    '/api/v1/organisation-groups',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(groupRequest),
    },
  );

  return json();
};

export const getGroup = async (id: string): Promise<GroupResponse> => {
  const url = `/api/v1/organisation-groups/${id}`;

  const { json } = await authenticatedFetch<GroupResponse>(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const groupResponse: GroupResponse = await json();

  return {
    ...groupResponse,
    organisations:
      groupResponse.organisations?.sort(sortOrgsAlphabetically) ?? [],
  };
};
export const getGroups = async (): Promise<GetGroupResponse> => {
  const { json } = await authenticatedFetch<GetGroupResponse>(
    '/api/v1/organisation-groups',
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return json();
};

export const deleteGroup = async (groupId: string): Promise<void> => {
  const url = `/api/v1/organisation-groups/${groupId}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });

  await json();
};

export const updateGroup = async ({ id, name, type }: Group): Promise<void> => {
  const url = `/api/v1/organisation-groups/${id}`;

  const { json } = await authenticatedFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, type }),
  });

  await json();
};

export const addOrganisationToGroup = async ({
  groupId,
  organisationId,
}: {
  groupId: string;
  organisationId: string;
}): Promise<void> => {
  const url = `/api/v1/organisation-groups/${groupId}/organisations/${organisationId}`;

  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  await json();
};

export const getVisibility = (visibility?: boolean): OrganisationVisibility =>
  visibility ? 'PRIVATE' : 'PUBLIC';

export const createGroupedOrganisation = async ({
  groupId,
  name,
  visibility,
}: {
  groupId: string;
  name: string;
  visibility?: OrganisationVisibility;
}) => {
  const url = `/api/v1/organisation-groups/${groupId}/organisations`;

  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      visibility,
    }),
  });

  await json();
};

export const removeOrganisationFromGroup = async ({
  groupId,
  organisationId,
}: {
  groupId: string;
  organisationId: string;
}) => {
  const url = `/api/v1/organisation-groups/${groupId}/organisations/${organisationId}`;

  const { json } = await authenticatedFetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });

  await json();
};

export const getGroupsByOrganisations = async ({
  ids,
}: {
  ids: string[];
}): Promise<{ organisationGroups: GroupResponse[] }> => {
  const url = '/api/v1/organisation-groups?searchBy=ORGANISATION_MEMBER_ANY';

  const { json } = await authenticatedFetch<{
    organisationGroups: GroupResponse[];
  }>(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  });

  return json();
};

export const addOrganisationsToGroup = async ({
  groupId,
  organisations,
}: {
  groupId: string;
  organisations: OrganisationResponse[];
}) => {
  const queue = [];

  for (let i = 0; i < organisations.length; i += 1) {
    queue.push(
      addOrganisationToGroup({ groupId, organisationId: organisations[i].id }),
    );
  }

  return (await Promise.allSettled(queue)).map(({ status }, index) => ({
    success: status === 'fulfilled',
    organisation: organisations[index],
  }));
};

export const addExistingUserToOrgs = async (
  partnerGroupId: string,
  practitionerId: string,
  requestData: { organisationIds: string[] },
) => {
  const { json } = await authenticatedFetch(
    `/api/v1/organisation-groups/${partnerGroupId}/practitioners/${practitionerId}/organisations`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    },
  );
  return json();
};

export const getExistingUserByGroup = async (
  groupId: string,
  emailAddress: string | undefined,
): Promise<PractitionerData | null> => {
  const { status, json } = await authenticatedFetch(
    `/api/v1/organisation-groups/${groupId}/practitioners?email=${encodeURIComponent(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      emailAddress!,
    )}`,
    undefined,
  );

  if (status === 204) {
    return null;
  }
  return json();
};
