/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Alert, H1, P, Span } from '@insights-ltd/design-library/components';
import { arrayMatches } from '@insights-ltd/design-library/utils';
import { queryStatus } from 'utils/queryStatus';
import { Box, Container, Grid, Link, Button, styled } from '@mui/material';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import {
  Profile,
  LearnerPurchaseRequest,
  useGetPractitionerProducts,
  useGetPractitionerWallet,
  useGetEvaluationsByOrgs,
} from 'api';
import { useAuth } from 'contexts/AuthContext';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { LicensedProduct } from 'types/types';
import ChapterCheckboxes from './ChapterCheckboxes/ChapterCheckboxes';
import ProductRadioOptions from './ProductRadioOptions/ProductRadioOptions';
import ConfirmPurchase from './ConfirmPurchase/ConfirmPurchase';
import ConfirmDialog from './ConfirmDialog/ConfirmDialog';
import ProfileBasket from './ProfileBasket/ProfileBasket';
import PurchaseError from './PurchaseError';
import { LearnerForPurchase, ProfileTypes, PurchaseType } from './types';

const profileType: ProfileTypes = {
  DISCOVERY_SELF_AWARE_PROFILE: 'DISCOVERY_PROFILE',
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE: 'IDTL_PROFILE',
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  '> div:first-of-type': {
    alignSelf: 'flex-start',
    margin: `0.25rem ${theme.spacing(spacingSizeMap.XS)} 0`,
  },
}));

const getLatestProfile = (latestProfiles: Profile[], productName: string) => {
  return latestProfiles!.find(
    (profile) => profile.profileType === profileType[productName] || null,
  );
};

const extractPurchaseLines = (
  learnersWithCosts: LearnerForPurchase[],
  productName: string,
) => {
  return learnersWithCosts
    .filter((learner) => {
      const latestProfile = getLatestProfile(
        learner.latestEvaluation.latestProfiles,
        productName,
      );
      return learner.assignedChapters.length > 0 && !latestProfile;
    })
    .map((learner) => {
      return {
        learner: learner.id,
        evaluations: [learner.latestEvaluation.id],
        chapters: learner.assignedChapters.map(
          ({ name: chapterName }) => chapterName,
        ),
      };
    });
};

const extractUpdateLines = (
  learnersWithCosts: LearnerForPurchase[],
  productName: string,
) => {
  return learnersWithCosts
    .filter((learner) => {
      const latestProfile = getLatestProfile(
        learner.latestEvaluation.latestProfiles,
        productName,
      );
      return learner.assignedChapters.length > 0 && !!latestProfile;
    })
    .map((learner) => {
      return {
        profile: getLatestProfile(
          learner.latestEvaluation.latestProfiles,
          productName,
        )!.id,
        chapters: learner.assignedChapters.map(
          ({ name: chapterName }) => chapterName,
        ),
      };
    });
};

const getDefaultSelectedChapter = (product: LicensedProduct) => {
  switch (product) {
    case 'DISCOVERY_SELF_AWARE_PROFILE':
      return { FOUNDATION: true };
    case 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE':
      return { IDTL: true };
    default:
      return {};
  }
};

const handleChangeOfProduct = (
  product: LicensedProduct,
  selectedChapters: Record<string, boolean | undefined>,
  cb: (chapters: Record<string, boolean | undefined>) => void,
) => {
  if (
    product === 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE' &&
    !selectedChapters.IDTL
  ) {
    cb(getDefaultSelectedChapter(product));
  }

  if (
    product === 'DISCOVERY_SELF_AWARE_PROFILE' &&
    !selectedChapters.FOUNDATION
  ) {
    cb(getDefaultSelectedChapter(product));
  }
};

const backButtonTranslation: Record<PurchaseType, string> = {
  Evaluator:
    'ui.event-management.evaluator-links.purchase-page.back-button-label.evaluator',
  Chapters:
    'ui.event-management.evaluator-links.purchase-page.back-button-label.learner',
  Teams:
    'ui.event-management.evaluator-links.purchase-page.back-button-label.teams',
};

type PurchaseProductsProps = {
  isPurchasing: boolean;
  parentLink: string;
  organisationIds: string[];
  evaluationIds: string[];
  removeCheckedItems?: (items: string[]) => void;
  onPurchase: (
    requestData: LearnerPurchaseRequest,
    onSuccess: () => void,
    onFailure: () => void,
  ) => void;
  resetMutate: () => void;
  error: unknown | null;
  isError: boolean;
  type: PurchaseType;
};

const PurchaseProfiles = ({
  isPurchasing,
  organisationIds,
  parentLink,
  evaluationIds,
  removeCheckedItems,
  onPurchase,
  error,
  isError,
  resetMutate,
  type,
}: PurchaseProductsProps) => {
  const { t } = useTranslation();
  const [productName, setProductType] = useState<LicensedProduct>(
    'DISCOVERY_SELF_AWARE_PROFILE',
  );
  const [purchaseError, setPurchaseError] = useState<boolean>(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const backButtonText = t(backButtonTranslation[type]);

  const [selectedChapters, setSelectedChapters] = useState<
    Record<string, boolean | undefined>
  >(getDefaultSelectedChapter(productName));

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { status: productStatus, data: products } = useGetPractitionerProducts(
    user!.id,
  );

  const { status: learnersStatus, data: evaluatorProfiles = [] } =
    useGetEvaluationsByOrgs(organisationIds, evaluationIds);
  const {
    status: walletStatus,
    data: wallet,
    refetch,
  } = useGetPractitionerWallet(user!.id);

  const responseStatus = queryStatus(
    productStatus,
    walletStatus,
    learnersStatus,
  );

  if (responseStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (responseStatus === 'error') {
    return (
      <FullScreenError
        message={t(
          'ui.event-management.events.download-profiles.error-loading-learners',
        )}
      />
    );
  }
  const purchaseProducts = products!.products;
  const productNames: LicensedProduct[] = purchaseProducts.map(
    ({ name }) => name,
  );

  const { availableUnits: walletBalance } = wallet!;

  const handleClose = () => setIsConfirmOpen(false);

  const chapters = purchaseProducts
    .filter((product) => product.name === productName)
    .flatMap((p) => p.chapters);

  const chapterNames = chapters.map(({ name }) => name);
  const learnersWithCosts: LearnerForPurchase[] = evaluatorProfiles.map(
    (evaluation) => {
      const { learner, ...latestEvaluation } = evaluation;
      const purchasedChapters =
        getLatestProfile(
          latestEvaluation.latestProfiles,
          productName,
        )?.chapters.filter((product) => chapterNames.includes(product)) || [];
      const learnerCost = chapters.reduce(
        (acc, { name: chapterName, cost }) => {
          return !purchasedChapters.includes(chapterName) &&
            selectedChapters[chapterName]
            ? cost + acc
            : acc;
        },
        0,
      );
      return {
        ...learner,
        latestEvaluation,
        purchaseTotal: learnerCost ?? 0,
        assignedChapters: chapters.filter(
          (chapter) =>
            selectedChapters[chapter.name] &&
            !purchasedChapters.includes(chapter.name),
        ),
        purchasedChapters,
      };
    },
  );

  const transactionTotal = learnersWithCosts.reduce(
    (acc, { purchaseTotal }) => (purchaseTotal ? acc + purchaseTotal : acc),
    0,
  );

  const handlePurchase = () => {
    setIsConfirmOpen(true);
    const requestData = {
      product: productName,
      createProfiles: extractPurchaseLines(learnersWithCosts, productName),
      updateProfiles: extractUpdateLines(learnersWithCosts, productName),
    };

    if (!isError) {
      onPurchase(
        requestData,
        () => {
          setIsConfirmOpen(false);
          navigate(parentLink);
        },
        () => {
          setIsConfirmOpen(false);
        },
      );
    }
  };

  const handleChecked = (name: string, selected: boolean) => {
    let selectedItems;
    if (selected) {
      const { [name]: oldName, ...remainingChecked } = selectedChapters;
      selectedItems = remainingChecked;
    } else {
      selectedItems = {
        ...selectedChapters,
        [name]: true,
      };
    }
    setSelectedChapters(selectedItems);
  };

  const postTransactionBalance = walletBalance - transactionTotal || 0;
  const isInvalidTransaction = transactionTotal > walletBalance;

  const learnersCount = evaluatorProfiles.length;

  let prePurchasedChapters: string[];
  let disabledProducts: LicensedProduct[] = [];

  switch (type) {
    case 'Chapters':
      prePurchasedChapters =
        evaluatorProfiles[0]?.latestProfiles.find(
          (profile) => profile?.profileType === profileType[productName],
        )?.chapters ?? [];
      disabledProducts = evaluatorProfiles[0]?.latestProfiles.find(
        (profile) =>
          profile?.profileType === profileType.DISCOVERY_SELF_AWARE_PROFILE,
      )
        ? []
        : ['DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE'];
      break;
    case 'Evaluator':
    case 'Teams':
      prePurchasedChapters = chapters.reduce((arr, { name: chapterName }) => {
        const total = learnersWithCosts.filter((learner) =>
          learner.purchasedChapters.includes(chapterName),
        ).length;
        if (total === learnersCount) arr.push(chapterName);
        return arr;
      }, [] as string[]);
      break;
      break;
    default:
      prePurchasedChapters = [];
      disabledProducts = [];
  }

  if (
    prePurchasedChapters?.length > 0 &&
    prePurchasedChapters?.length > Object.keys(selectedChapters).length &&
    !arrayMatches(Object.keys(selectedChapters), prePurchasedChapters)
  ) {
    const prePurchasedMap = prePurchasedChapters.reduce(
      (acc, current) => ({ ...acc, [current]: true }),
      {},
    );
    setSelectedChapters({
      ...selectedChapters,
      ...prePurchasedMap,
    });
  }

  handleChangeOfProduct(productName, selectedChapters, setSelectedChapters);

  if (evaluationIds.length === 0) navigate(parentLink);

  return (
    <Container maxWidth="lg">
      <Box sx={(th) => ({ marginBottom: th.spacing(spacingSizeMap.L) })}>
        <H1 variant="h2">
          {t('ui.event-management.learners.purchase-profiles.page-title')}
        </H1>
      </Box>
      <Box
        sx={(th) => ({
          '& > p': {
            fontWeight: th.typography.fontWeightBold,
          },
        })}
      >
        <P>
          {t('ui.event-management.learners.purchase-profiles.which-product')}
        </P>
      </Box>
      <Box sx={{ marginBottom: '2.5rem' }}>
        <ProductRadioOptions
          onChange={setProductType}
          productValue={productName}
          disabledValues={disabledProducts}
          practitionerProducts={productNames}
        />
      </Box>
      <Box sx={(th) => ({ marginBottom: th.spacing(spacingSizeMap.S) })}>
        <H1 variant="h3">
          {t(
            'ui.event-management.learners.purchase-profiles.your-basket.heading',
          )}
        </H1>
      </Box>
      {productName === 'DISCOVERY_SELF_AWARE_PROFILE' ? (
        <>
          <Box sx={(th) => ({ marginBottom: th.spacing(spacingSizeMap.XS) })}>
            {type === 'Evaluator' ? (
              <P>
                {t(
                  'ui.event-management.learners.purchase-profiles.your-basket.assign-chapters.description',
                )}
              </P>
            ) : null}
          </Box>
          <Box sx={(th) => ({ marginBottom: th.spacing(spacingSizeMap.XS) })}>
            <ChapterCheckboxes
              chapters={chapters}
              checkedChapters={selectedChapters}
              handleCheckedChapter={handleChecked}
              prePurchasedChapters={prePurchasedChapters}
            />
          </Box>
        </>
      ) : null}
      <ProfileBasket
        purchaseItems={learnersWithCosts}
        removeCheckedItems={removeCheckedItems}
        productName={productName}
        setPurchaseError={setPurchaseError}
        purchaseError={purchaseError}
      />
      {purchaseError ? (
        <Grid
          sx={(theme) => ({
            marginTop: theme.spacing(spacingSizeMap.S),
            width: '720px',
            [theme.breakpoints.down('lg')]: {
              width: '100%',
            },
          })}
        >
          <StyledAlert iconType="warning" kind="error">
            <Span color="error" variant="body-bold">
              {t(
                'ui.event-management.learners.purchase-profiles.confirm-purchase.basket-error',
              )}
            </Span>
          </StyledAlert>
        </Grid>
      ) : null}
      <Grid container>
        <Grid
          container
          spacing={1}
          sx={{
            marginTop: '2.5rem',
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          {!purchaseError ? (
            <ConfirmPurchase
              userUnitBalance={walletBalance}
              transactionTotal={transactionTotal}
              postTransactionBalance={postTransactionBalance}
              isInvalidTransaction={isInvalidTransaction}
            />
          ) : null}
          <Grid item xs={12}>
            <Box display="flex" alignItems="top" justifyContent="space-between">
              <Link component={RouterLink} underline="none" to={parentLink}>
                <Button variant="outlined" color="primary">
                  {backButtonText}
                </Button>
              </Link>
              {!purchaseError ? (
                <Button
                  disabled={
                    isInvalidTransaction ||
                    transactionTotal === 0 ||
                    isPurchasing
                  }
                  variant="contained"
                  color="primary"
                  onClick={handlePurchase}
                >
                  {t(
                    'ui.event-management.learners.purchase-profiles.confirm-purchase.button-label',
                  )}
                </Button>
              ) : null}
            </Box>
          </Grid>
        </Grid>
        {isConfirmOpen && !isError ? (
          <ConfirmDialog
            handleClose={handleClose}
            open={isConfirmOpen && !isError}
          />
        ) : null}
        {isError ? (
          <PurchaseError
            error={error}
            isError={isError}
            reset={resetMutate}
            resetWallet={refetch}
          />
        ) : null}
      </Grid>
    </Container>
  );
};

export default PurchaseProfiles;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
