import { ArchivedProfileResponse, ProfileResponse } from 'api';
import {
  DIALECT_CODES,
  ENABLED_EVALUATOR_DIALECTS_NO_PREFERRED,
} from 'variables';

export const isIDTLProfile = (profile: ProfileResponse) =>
  profile.type === 'IDTL_PROFILE';

export const getProfileDialects = (profile: ProfileResponse) =>
  isIDTLProfile(profile)
    ? [DIALECT_CODES.ENGLISH]
    : ENABLED_EVALUATOR_DIALECTS_NO_PREFERRED.map(({ value }) => value);

export const isExploreProfile = (
  profile: ProfileResponse | ArchivedProfileResponse,
) => profile.type === 'EXPLORE_PROFILE';

export const isApiProfile = (
  profile: ProfileResponse | ArchivedProfileResponse,
) => profile.type === 'API_PROFILE';
