import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled, SxProps, Theme } from '@mui/material';
import { P, Span } from '@insights-ltd/design-library/components';
import { Group, GroupType } from 'api';
import { Link as RouterLink } from 'react-router-dom';

const StyledP = styled(P)({});

export const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  display: 'inline-block',
  marginLeft: '4px',
  marginRight: '4px',
}));

export const StyledSpan = styled(Span)(({ theme }) => ({
  fontWeight: `${theme.typography.fontWeightBold} !important`,
}));

export const GroupText = ({
  groupName,
  groupType,
}: {
  groupName: string;
  groupType: string;
}) => (
  <Trans
    i18nKey="ui.event-management.organisations.select-organisation.group.group-name"
    values={{ groupName, groupType }}
    components={{ '2': <StyledSpan variant="body" color="textSecondary" /> }}
  />
);

export const GroupLink = ({
  parent,
  sx,
  variant,
}: {
  parent: Group;
  sx: SxProps<Theme>;
  variant: 'small' | 'medium';
}) => {
  const { t } = useTranslation();
  const { name, type, id } = parent;

  const typeTranslations: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.type.customer'),
    PARTNER: t('ui.event-management.organisations.type.partner'),
  };

  if (variant === 'small') {
    return (
      <StyledP
        color="textSecondary"
        sx={{
          display: 'inline',
        }}
      >
        <GroupText groupName={name} groupType={typeTranslations[type]} />
      </StyledP>
    );
  }

  return (
    <StyledP sx={sx} variant="body-bold">
      <Trans
        i18nKey="ui.event-management.users.add-existing-practitioner.current-organisations.parent-group"
        components={{
          '1': <StyledLink to={`/groups/${id}`} />,
        }}
        values={{ groupName: name, groupType: typeTranslations[type] }}
      />
    </StyledP>
  );
};
