/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import {
  H1,
  SvgAddRegular,
  SvgSubtractRegular,
} from '@insights-ltd/design-library/components';
import { queryStatus } from 'utils/queryStatus';
import { QueryStatus, useQueryClient } from '@tanstack/react-query';
import {
  LearnerPurchaseRequest,
  useGetAllLearnerEvents,
  useGetLearnerByOrganisation,
  useGetProfilesByOrganisation,
  useGetArchivedProfilesByOrganisation,
  useGetOrganisation,
  usePurchaseLearnerChapter,
  useGetLearnerTeams,
} from 'api';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import Breadcrumbs from 'components/Breadcrumbs';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import PurchasePage from 'components/PurchasePage/PurchasePage';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useBetaEnabled } from 'features';
import LearnerInfo from './LearnerInfo';
import OrganisationInfo from './OrganisationInfo';
import LearnerExperiences from './LearnerExperiences';
import Profiles from './Profiles';
import ProfileArchivedCard from './ProfileArchivedCard';
import { LearnerTeamsList } from './LearnerTeams';
import { truncatedTextStyles } from './learnerHelper';

type Props = {
  otherStatus?: QueryStatus;
  crumbs?: Record<string, string>;
  evaluatorLinkId?: string;
};

const Learner = ({ otherStatus, crumbs, evaluatorLinkId }: Props) => {
  const [downloadProfileError, setDownloadProfileError] = useState(false);
  const [archivedProfilesExpanded, setArchivedProfilesExpanded] =
    React.useState<string | false>(false);
  const { learnerId, organisationId } = useParams<{
    learnerId: string;
    organisationId: string;
  }>();
  const { t } = useTranslation();
  const betaAvailable = useBetaEnabled('profile-archive');
  const { status: learnerStatus, data: learner } = useGetLearnerByOrganisation(
    learnerId || '',
    organisationId || '',
  );
  const {
    status: orgsStatus,
    organisations: practitionerOrganisations,
    organisationsInGroups,
    groups,
  } = useGetAllOrganisationsAndGroups();
  const { status: orgStatus, data: organisation } =
    useGetOrganisation(organisationId);
  const { status: eventStatus, data: events } = useGetAllLearnerEvents(
    learnerId || '',
  );
  const { status: profileStatus, data: profiles } =
    useGetProfilesByOrganisation(learnerId || '', organisationId || '');
  const { status: archivedProfileStatus, data: archivedProfiles } =
    useGetArchivedProfilesByOrganisation(
      learnerId || '',
      organisationId || '',
      betaAvailable,
    );
  const {
    status: learnerTeamsStatus,
    data: learnerTeams,
    refetch: refetchLearnerTeams,
  } = useGetLearnerTeams(learnerId ?? '');

  useEffect(() => {
    refetchLearnerTeams();
  }, [organisationId, refetchLearnerTeams]);

  const StyledAddIcon = styled(SvgAddRegular)(({ theme }) => ({
    width: 12,
    height: 12,
    stroke: theme.palette.primary.main,
  }));

  const StyledSubtractIcon = styled(SvgSubtractRegular)(({ theme }) => ({
    width: 12,
    height: 12,
    stroke: theme.palette.primary.main,
  }));

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setArchivedProfilesExpanded(isExpanded ? panel : false);
    };

  const status = queryStatus(
    learnerTeamsStatus,
    eventStatus,
    learnerStatus,
    profileStatus,
    !betaAvailable && archivedProfileStatus === 'pending'
      ? 'success'
      : archivedProfileStatus,
    otherStatus || 'success',
    orgStatus,
    orgsStatus,
  );

  const showOrgs: boolean =
    practitionerOrganisations && practitionerOrganisations?.length > 1;

  const defaultCrumbs: Record<string, string> = {
    '/': t('ui.event-management.dashboard.home.title'),
    '/learners': t('ui.event-management.learners-and-teams.title'),
  };

  const breadcrumbs: Record<string, string> =
    crumbs === undefined ? defaultCrumbs : crumbs;

  const allValidEvents = events ?? [];

  const parentLink = `/learners/${organisation?.id}/${learnerId}`;
  const editLearnerLink = evaluatorLinkId
    ? `/evaluator-links/${evaluatorLinkId}/${organisationId}/${learnerId}/edit`
    : `/learners/${organisationId}/${learnerId}/edit`;
  const queryClient = useQueryClient();
  const {
    mutate,
    error,
    isError,
    isPending,
    reset: resetMutate,
  } = usePurchaseLearnerChapter(queryClient);
  const pageTitle = t(
    'ui.event-management.learners.purchase-profiles.page-title',
  );

  const handlePurchase = (
    requestData: LearnerPurchaseRequest,
    onSuccess: () => void,
  ) => {
    const { createProfiles, updateProfiles, product } = requestData;
    return mutate(
      {
        learnerId: learnerId ?? '',
        organisationId: organisation?.id ?? '',
        purchaseData: {
          product,
          createProfile: createProfiles[0],
          updateProfile: updateProfiles[0],
        },
      },
      { onSuccess },
    );
  };

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.learner.error-loading-learner')}
      />
    );
  }

  const linkCrumbs: Record<string, string> = {
    '/': t('ui.event-management.events.nav.home'),
    '/organisations': t('ui.event-management.organisations.title'),
    [`/organisations/${organisation!.id}`]: organisation!.name,
    [parentLink]: learner!.fullName,
  };

  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.view-learner', {
            learnerName: learner!.fullName,
          })}
        </title>
      </Helmet>
      <PurchasePage
        onPurchase={handlePurchase}
        title={pageTitle}
        breadcrumbs={linkCrumbs}
        indexRoute={
          <Container maxWidth="lg">
            <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
              <Breadcrumbs
                crumbs={breadcrumbs}
                activeText={learner!.fullName}
                sx={{
                  '& .MuiBreadcrumbs-li > p': {
                    ...truncatedTextStyles,
                  },
                }}
              />
            </Box>
            <Grid container>
              <Grid item spacing={4} xs={12} md={8}>
                <Box
                  sx={{
                    ...truncatedTextStyles,
                    maxWidth: '100%',
                  }}
                >
                  <H1 variant="h2">{learner!.fullName}</H1>
                </Box>
              </Grid>
              <Grid
                display={{
                  md: 'flex',
                }}
                justifyContent={{
                  md: 'flex-end',
                }}
                spacing={4}
                item
                xs={12}
                md={4}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={editLearnerLink}
                >
                  {t('ui.event-management.learner.edit-button')}
                </Button>
              </Grid>
            </Grid>
            <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
            <Divider />
            <Grid container spacing={2}>
              <Grid container item xs={8} spacing={4}>
                <LearnerInfo learner={learner!} />
                <Grid item xs={12} mt="0.5rem">
                  <OrganisationInfo
                    learnerId={learnerId || ''}
                    organisationId={organisationId || ''}
                    name={organisation?.name || ''}
                    dataRetentionPolicy={organisation?.dataRetentionPolicy}
                    organisationsInGroups={organisationsInGroups}
                    organisations={practitionerOrganisations}
                    groups={groups}
                    parent={
                      organisationId
                        ? organisationsInGroups[organisationId]
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                mb={(theme) => theme.spacing(spacingSizeMap.L)}
              >
                <Profiles
                  learnerId={learnerId || ''}
                  profiles={profiles!}
                  evaluatorSubmittedOn={learner?.latestEvaluation?.submittedOn}
                  onProfileDownloadError={() => setDownloadProfileError(true)}
                  organisationId={organisationId || ''}
                />
                {betaAvailable &&
                  archivedProfiles &&
                  archivedProfiles.length !== 0 && (
                    <>
                      <Box pb="24px" />
                      <Accordion
                        expanded={archivedProfilesExpanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        sx={(theme) => ({
                          margin: '0 !important',
                          boxShadow: 'none',
                          '&.MuiAccordion-root': { position: 'inherit' },
                          '& .MuiAccordionSummary-root': {
                            padding: 0,
                            fontWeight: 600,
                            fontSize: '1rem',
                            lineHeight: '1.625rem',
                            color: theme.palette.blue.main,
                          },
                          '& .MuiAccordionSummary-expandIconWrapper > svg': {
                            strokeWidth: 3,
                          },
                        })}
                      >
                        <AccordionSummary
                          expandIcon={
                            archivedProfilesExpanded === 'panel1' ? (
                              <StyledSubtractIcon />
                            ) : (
                              <StyledAddIcon />
                            )
                          }
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{
                            width: 'fit-content',
                            minHeight: '1px !important',
                            '& .MuiAccordionSummary-content.Mui-expanded': {
                              margin: 0,
                            },
                            '& .MuiAccordionSummary-content': {
                              margin: 0,
                            },
                          }}
                        >
                          {`Archived Profiles (${archivedProfiles.length})\u00A0`}
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiAccordionDetails-root': {
                              padding: '1rem 0 0 0 !important',
                            },
                          }}
                        >
                          <Grid
                            container
                            item
                            spacing={2}
                            data-testid="archived-profiles"
                          >
                            {archivedProfiles.map((profile) => (
                              <Grid item xs={12} sm={4} key={profile.id}>
                                <ProfileArchivedCard
                                  learnerId={learnerId || ''}
                                  profile={profile}
                                  organisationId={organisationId || ''}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                <Box mb="40px" />
                <LearnerExperiences
                  events={allValidEvents}
                  showOrgs={showOrgs}
                />
                <Box pb="24px" />
                <LearnerTeamsList learnerTeams={learnerTeams} />
                <Box pb="24px" />
              </Grid>
            </Grid>
          </Container>
        }
        evaluationIds={
          learner?.latestEvaluation?.id ? [learner?.latestEvaluation?.id] : []
        }
        parentLink={parentLink}
        isError={isError}
        isPurchasing={isPending}
        error={error}
        onReset={resetMutate}
        organisationIds={[organisation?.id ?? '']}
        type="Chapters"
      />
      <Snackbar
        open={downloadProfileError}
        autoHideDuration={5000}
        onClose={() => setDownloadProfileError(false)}
      >
        <Alert elevation={6} severity="error">
          {t('ui.event-management.generic.error')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Learner;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
