import React, { createContext, useMemo, useState } from 'react';
import { Dialects } from '@insights-ltd/design-library/utils';

const LanguageSelectWrapperContext = createContext<
  | {
      userSelectedDialect: Dialects | undefined;
      setUserSelectedDialect: (value: Dialects) => void;
    }
  | undefined
>(undefined);

const LanguageSelectWrapperProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userSelectedDialect, setUserSelectedDialect] = useState<
    Dialects | undefined
  >(undefined);

  return (
    <LanguageSelectWrapperContext.Provider
      value={useMemo(() => {
        return { userSelectedDialect, setUserSelectedDialect };
      }, [userSelectedDialect])}
    >
      {children}
    </LanguageSelectWrapperContext.Provider>
  );
};

export { LanguageSelectWrapperContext, LanguageSelectWrapperProvider };
