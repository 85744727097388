/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { useAuth } from 'contexts/AuthContext';
import {
  H1,
  Span,
  SvgLock2Bold,
  Tag,
} from '@insights-ltd/design-library/components';
import Breadcrumbs from 'components/Breadcrumbs';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import PermissionGuard from 'components/PermissionGuard';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import FullHeight from 'components/FullHeight/FullHeight';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import OrganisationGroupLink from 'components/OrganisationGroupLink/OrganisationGroupLink';
import useTabHistory from 'components/hooks/useTabHistory';
import { DeleteType } from 'pages/Event/types';
import { LocationStateProvider } from 'components/hooks/useHistoryState';
import { isAdvancedUser, isGroupManager } from 'utils/role';
import { useGetGroupByOrganisation } from 'api/organisationGroups/organisationGroupHooks';
import { isoToDuration } from 'utils/dates';
import { styled } from '@mui/material';
import { useBetaEnabled } from 'features';
import OrganisationInvites from './OrganisationInvites';
import OrganisationPractitioners from './OrganisationPractitioners';
import PractitionerInvitedDialog from './OrganisationInvites/PractitionerInvitedDialog';
import OrganisationEvents from './OrganisationEvents';
import useGetOrganisationData from './useGetOrganisationData';
import { OrganisationViewType } from './typeGuard';

const Organisation: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const locationState: {
    showInvitedDialog: boolean;
    showInviteDeletedDialog: boolean;
    showDeletedDialog: DeleteType;
    experienceName: string;
    activeTab: OrganisationViewType;
  } = state;

  const { tabValue, persistTab } = useTabHistory<OrganisationViewType>();

  const { organisationId } = useParams<{ organisationId: string }>();
  const removeDialogFromState = () => {
    const { showInvitedDialog, showInviteDeletedDialog, ...rest } =
      locationState;
    navigate(pathname, { replace: true, ...rest });
  };

  const isAdminUser = user?.roles.includes('SUPER_ADMIN');
  const isGM = isGroupManager(user?.roles);
  const isAU = isAdvancedUser(user?.roles);

  const {
    organisation,
    invites,
    practitioners,
    hasMultiOrgs,
    upcomingEvents,
    pastEvents,
    cancelledEvents,
    status: organisationsStatus,
    canViewAllOrgs,
  } = useGetOrganisationData(
    organisationId || '',
    tabValue as OrganisationViewType,
  );

  const getGroupByOrganisationQuery = useGetGroupByOrganisation(
    organisationId || '',
    {
      enabled:
        user?.permissions.Organisation_Group_ReadAll &&
        user?.permissions.Organisation_Group_ReadAll !== 'None',
    },
  );
  const { data: organisationGroup } = getGroupByOrganisationQuery;
  const isPartnerGroup = organisationGroup?.type === 'PARTNER';

  const status = queryStatusIgnoreIdle(
    {
      status: organisationsStatus,
      isLoading: organisationsStatus !== 'success',
    },
    getGroupByOrganisationQuery,
  );

  const isSpecialHandling = organisation?.visibility === 'PRIVATE';
  const isSpecialHandlingBetaEnabled = useBetaEnabled('special-handling');
  const groupManagerBetaEnabled = useBetaEnabled('group-manager-permissions');

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisation.error-loading')}
      />
    );
  }

  const { name, id, dataRetentionPolicy } = organisation!;
  const breadcrumbs: Record<string, string> =
    hasMultiOrgs && isPartnerGroup
      ? {
          '/': t('ui.event-management.events.nav.home'),
          '/organisations': t('ui.event-management.organisations.title'),
          [`/groups/${organisationGroup?.id}`]: t(
            'ui.event-management.group.breadcrumb.partner',
            {
              groupName: organisationGroup?.name,
            },
          ),
        }
      : {
          '/': t('ui.event-management.dashboard.home.title'),
          '/organisations': t('ui.event-management.organisations.title'),
        };
  const showInvitedOrDeletedDialog =
    locationState?.showInvitedDialog || locationState?.showInviteDeletedDialog;

  const activeBreadcrumbText = !hasMultiOrgs
    ? t('ui.event-management.dashboard.card-header.my-organisation', { name })
    : name;

  const tabs = [
    <Tab
      key="practitioners"
      label={t('ui.event-management.organisation.practitioners')}
      value="practitioners"
    />,
    ...(canViewAllOrgs
      ? [
          <Tab
            key="invites"
            label={t('ui.event-management.organisation.user-invites')}
            value="invites"
          />,
        ]
      : []),
    <Tab
      key="upcomingEvents"
      label={t('ui.event-management.organisation.active-experience')}
      value="upcomingEvents"
    />,
    <Tab
      key="pastEvents"
      label={t('ui.event-management.organisation.archived-experience')}
      value="pastEvents"
    />,

    <Tab
      key="cancelledEvents"
      label={t('ui.event-management.organisation.cancelled-experience')}
      value="cancelled"
    />,
  ];

  const isInsights = organisationId === 'insights';

  const formattedRetentionPeriod = isoToDuration(
    dataRetentionPolicy.maximumInactivityPeriod,
  );

  const StyledLock = styled(SvgLock2Bold)(({ theme }) => ({
    fill: theme.palette.grey[600],
    transform: 'scale(67%)',
    marginRight: '1px',
  }));

  const editButton = (
    <Grid item xs={12} md="auto">
      <Button
        variant="outlined"
        color="primary"
        to={`/organisations/${organisationId}/edit`}
        component={RouterLink}
        hidden={isInsights}
        aria-label={t('ui.event-management.organisations.button.edit-label')}
      >
        {t('ui.event-management.organisations.button.edit-label')}
      </Button>
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.organisation', { orgName: name })}
        </title>
      </Helmet>
      <FullHeight>
        <Container
          maxWidth="lg"
          sx={(theme) => ({
            '& > *': {
              marginBottom: theme.spacing(spacingSizeMap.M),
            },
          })}
        >
          <Box pt={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={breadcrumbs}
              activeText={activeBreadcrumbText}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8}>
              <H1 variant="h2">
                {name}
                {isSpecialHandlingBetaEnabled && isSpecialHandling && (
                  <>
                    {' '}
                    <Tag
                      icon={<StyledLock />}
                      label={t('ui.event-management.special-handling.tag')}
                      variant="default"
                      color="white"
                    />
                  </>
                )}
              </H1>
            </Grid>
            <PermissionGuard
              requiredPermissions={[
                { action: 'Organisation_Update', resourceId: id },
              ]}
            >
              {(groupManagerBetaEnabled || isPartnerGroup) && (isGM || isAU)
                ? editButton
                : isAdminUser && editButton}
            </PermissionGuard>
            {organisationGroup ? (
              <Grid
                sx={(theme) => ({
                  margin: `${theme.spacing(spacingSizeMap.S)} 0 ${theme.spacing(
                    spacingSizeMap.XS,
                  )}`,
                })}
                item
                xs={12}
                md={8}
              >
                <OrganisationGroupLink group={organisationGroup} />
              </Grid>
            ) : null}
            <Grid item xs={10} md={8}>
              <Trans
                i18nKey="ui.event-management.organisation.data-retention-period"
                values={{ years: formattedRetentionPeriod.years }}
                components={{
                  strong: (
                    <Span
                      variant="body-bold"
                      color="textSecondary"
                      style={
                        dataRetentionPolicy?.maximumInactivityPeriod
                          ? undefined
                          : { display: 'none' }
                      }
                    />
                  ),
                  light: <Span color="textSecondary" />,
                }}
              />
            </Grid>
          </Grid>
          <TabContext value={tabValue ?? 'practitioners'}>
            <TabList
              onChange={(_, value) => {
                persistTab(value);
              }}
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs}
            </TabList>

            <TabPanel
              value="practitioners"
              sx={{
                padding: 0,
              }}
            >
              <OrganisationPractitioners
                practitioners={practitioners!}
                title={t('ui.event-management.organisation.practitioners')}
                organisationId={organisation!.id}
                organisationGroup={organisationGroup}
              />
            </TabPanel>
            <TabPanel
              value="upcomingEvents"
              sx={{
                padding: 0,
              }}
            >
              <OrganisationEvents
                title={t('ui.event-management.events.upcoming-events')}
                events={upcomingEvents!}
                ascending
              />
            </TabPanel>
            <TabPanel
              value="invites"
              sx={{
                padding: 0,
              }}
            >
              <OrganisationInvites
                invites={invites!}
                title={t('ui.event-management.organisation.user-invites')}
                organisationId={organisation!.id}
              />
            </TabPanel>
            <TabPanel
              value="pastEvents"
              sx={{
                padding: 0,
              }}
            >
              <OrganisationEvents
                title={t('ui.event-management.events.past-events')}
                events={pastEvents!}
              />
            </TabPanel>
            <TabPanel
              value="cancelled"
              sx={{
                padding: 0,
              }}
            >
              <OrganisationEvents
                title={t('ui.event-management.events.cancelled-events')}
                events={cancelledEvents!}
                experienceView={tabValue}
                showDeletedDialog={locationState?.showDeletedDialog}
                deletedExperienceName={locationState?.experienceName}
              />
            </TabPanel>
          </TabContext>
          {showInvitedOrDeletedDialog ? (
            <PractitionerInvitedDialog
              open
              onClose={() => removeDialogFromState()}
              dialogType={
                locationState?.showInvitedDialog ? 'invite' : 'delete'
              }
            />
          ) : null}
        </Container>
      </FullHeight>
    </>
  );
};

const WrappedOrganisation = () => (
  <LocationStateProvider>
    <Organisation />
  </LocationStateProvider>
);

export default WrappedOrganisation;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
