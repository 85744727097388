import {
  useQuery,
  useMutation,
  QueryClient,
  QueryKey,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { QueryOptionsConfig } from 'api/hooks';
import {
  RequestError,
  PractitionerData,
  OrganisationResponse,
  EventSummaryResponse,
  LearnerAndInviteeResponse,
  FilteredLearnerResponse,
  WalletResponse,
  TransactionResponse,
  GroupResponse,
  EvaluatorLinkResponse,
  SignUpRequest,
} from 'api/httpEntities';
import { reload } from 'api/utils';
import { sortChapters } from 'utils/mappers/sorting';
import { EventType } from 'types/types';
import { QUERY_KEY_ROOTS } from 'variables';
import {
  PractitionerUpdateRequest,
  AddPractitionerToOrganisationsRequest,
  MultiOrgsForUserSearch,
} from './practitionerApiTypes';
import {
  validateInvite,
  signUp,
  getPractitioner,
  getPractitionerById,
  getOrganisationsForPractitioner,
  getProductsForPractitioner,
  deletePractitionerFromOrg,
  getPractitionerByEmail,
  getPractitioners,
  getPractitionerEvents,
  signIn,
  initiateSignIn,
  signOut,
  reinviteUser,
  deleteUserInvite,
  forgottenPassword,
  inviteUserV2,
  resetPassword,
  updatePractitioner,
  addPractitionerToOrganisations,
  deleteLearner,
  updateLearner,
  getAllLearnersAndInvitees,
  getFilteredLearners,
  getAllLearnerEvents,
  getPractitionerWallet,
  getMyLatestTransactions,
  updatePractitionerAccess,
  changePassword,
  getPractitionerGroups,
  getMyTeams,
  getPractitionerEvaluatorLinks,
  searchLearners,
  getLearnerTeams,
  getOrganisationLearners,
  removeProfileCotributors,
  getEventPractitioners,
  federatedSignUp,
} from './practitioners';

export function useValidateInvite(token: string) {
  return useQuery({
    queryKey: [''],
    queryFn: async () => validateInvite(token),
  });
}

export const useSignUp = (
  queryClient: QueryClient,
): UseMutationResult<void, RequestError, SignUpRequest, unknown> =>
  useMutation({
    mutationFn: signUp,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
      }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (error: RequestError) => {
      /* Kept to establish the type of the error */
    },
  });

export function useFederatedSignUp() {
  return useMutation({
    mutationFn: federatedSignUp,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (_: RequestError) => {
      /* Kept to establish the type of the error */
    },
  });
}

export const useGetPractitioner = (
  config: QueryOptionsConfig<PractitionerData> = {},
) =>
  useQuery<PractitionerData, Error>({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
    queryFn: getPractitioner,
    ...config,
  });

export const useGetPractitionerById = (practitionerId?: string) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA, practitionerId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getPractitionerById(practitionerId!),
    enabled: !!practitionerId,
  });

export const useGetPractitionerOrganisations = (
  practitionerId?: string,
  config: QueryOptionsConfig<OrganisationResponse[]> = {},
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.PRACTITIONER_DATA,
      practitionerId,
      'organisations',
    ] as QueryKey,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getOrganisationsForPractitioner(practitionerId!),
    ...config,
  });

export const useGetPractitionerProducts = (practitionerId?: string) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA, practitionerId, 'products'],

    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { products } = await getProductsForPractitioner(practitionerId!);

      const sortedProducts = products.map(({ chapters, ...product }) => {
        const sortedChapters = chapters.sort(
          ({ name: nameA }, { name: nameB }) => sortChapters(nameA, nameB),
        );

        return {
          ...product,
          chapters: sortedChapters,
        };
      });
      return { products: sortedProducts };
    },
    enabled: !!practitionerId,
  });

export const useDeletePractitionerFromOrg = (
  organisationId: string,
  queryClient: QueryClient,
) =>
  useMutation({
    mutationFn: deletePractitionerFromOrg,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONERS_SUMMARY_DATA, organisationId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
      });
    },
  });

export const useGetPractitionerByEmail = (email: string | undefined) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA, email],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getPractitionerByEmail(email!),
    enabled: !!email,
  });

export const useGetPractitioners = (
  organisationId: string,
  config: QueryOptionsConfig<PractitionerData[]> = {},
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONERS_DATA, organisationId] as QueryKey,
    queryFn: async () => getPractitioners(organisationId),
    ...config,
  });

export const useGetEventPractitioners = (
  organisationId: string,
  eventType?: EventType,
  config: QueryOptionsConfig<PractitionerData[]> = {},
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONERS_DATA, organisationId] as QueryKey,
    queryFn: async () => getEventPractitioners(organisationId, eventType),
    ...config,
  });

// TODO: remove this when we add the ability to get the events based on which tab we navigate to
export const useGetCancelledPractitionerEvents = (
  practitionerId: string,
  config: QueryOptionsConfig<EventSummaryResponse[]> = {},
) => {
  return useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.PRACTITIONER_CANCELLED_EVENTS,
      practitionerId,
    ] as QueryKey,
    queryFn: async () => getPractitionerEvents(practitionerId, 'CANCELLED'),
    ...config,
  });
};

export const useSignIn = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: signIn,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
      }),
  });

export const useInitiateSignIn = () =>
  useMutation({
    mutationFn: initiateSignIn,
  });

export const useGetSignInType = (emailAddress: string) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.INITIATE_SIGN_IN_EMAIL, emailAddress],
    queryFn: async () => initiateSignIn({ emailAddress }),
  });

export const useSignOut = () => {
  return useMutation({
    mutationFn: signOut,
    onSettled: () => {
      const url = new URL(window.location.href);
      url.searchParams.set('redirect', 'false');
      window.history.replaceState({}, '', url.toString());
      reload();
    },
  });
};

export const useReinviteUser = (
  organisationId: string,
  queryClient: QueryClient,
) =>
  useMutation({
    mutationFn: reinviteUser,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.OPEN_PRACTITIONER_INVITES_DATA,
          organisationId,
        ],
      }),
  });

export const useDeleteUserInvite = (
  organisationId: string,
  queryClient: QueryClient,
) =>
  useMutation({
    mutationFn: deleteUserInvite,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.OPEN_PRACTITIONER_INVITES_DATA,
          organisationId,
        ],
      }),
  });

export const useForgottenPassword = () =>
  useMutation({
    mutationFn: forgottenPassword,
  });

export const useInviteUserV2 = (
  organisationId: string,
  queryClient: QueryClient,
) =>
  useMutation({
    mutationFn: inviteUserV2,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.OPEN_PRACTITIONER_INVITES_DATA,
          organisationId,
        ],
      }),
  });

export const useResetPassword = () =>
  useMutation({
    mutationFn: resetPassword,
  });

export const useUpdatePractitioner = () =>
  useMutation<void, RequestError, PractitionerUpdateRequest>({
    mutationFn: updatePractitioner,
  });

export const useAddPractitionerToOrganisations = () =>
  useMutation<void, RequestError, AddPractitionerToOrganisationsRequest>({
    mutationFn: addPractitionerToOrganisations,
  });

export const useDeleteLearner = (learnerId: string, queryClient: QueryClient) =>
  useMutation({
    mutationFn: deleteLearner,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEY_ROOTS.LEARNER_DATA, learnerId],
        })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.LEARNERS_DATA] })
        .then();
    },
  });
export const useUpdateLearner = (learnerId: string, queryClient: QueryClient) =>
  useMutation({
    mutationFn: updateLearner,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEY_ROOTS.LEARNER_DATA, learnerId],
        })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.LEARNERS_DATA] })
        .then();
    },
  });
export const useGetAllLearnersAndInvitees = (
  config?: QueryOptionsConfig<LearnerAndInviteeResponse[]>,
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.LEARNERS_DATA] as QueryKey,
    queryFn: getAllLearnersAndInvitees,
    ...config,
  });

export const useGetFilteredLearners = (
  betaEnabled: boolean,
  searchTerm: string,
  organisationId?: string | null,
  config = { enabled: true },
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.LEARNERS_DATA, searchTerm, organisationId],
    queryFn: async () =>
      getFilteredLearners(betaEnabled, searchTerm, organisationId),
    ...config,
    enabled: Boolean(searchTerm) && config.enabled,
  });

export const useGetOrganisationLearners = (
  organisationId: string,
  config = { enabled: true },
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.LEARNERS_DATA, organisationId],
    queryFn: async () => getOrganisationLearners(organisationId),
    ...config,
  });

export const useSearchLearnersForOrgsTeam = (
  searchTerm: string,
  organisations: MultiOrgsForUserSearch,
  config: QueryOptionsConfig<FilteredLearnerResponse> = {},
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.LEARNERS_DATA, searchTerm] as QueryKey,
    queryFn: async () => {
      return searchLearners(searchTerm, organisations);
    },
    ...config,
  });

export const useGetAllLearnerEvents = (learnerId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.EVENTS_DATA, learnerId],

    queryFn: async () => {
      const { events } = await getAllLearnerEvents(learnerId);
      return events;
    },
  });

export const useGetPractitionerWallet = (
  practitionerId: string,
  config: QueryOptionsConfig<WalletResponse> = {},
) =>
  useQuery<WalletResponse, Error>({
    queryKey: [
      QUERY_KEY_ROOTS.PRACTITIONER_PRIMARY_WALLET_DATA,
      practitionerId,
    ],
    queryFn: async () => getPractitionerWallet(practitionerId),
    ...config,
  });

export const useGetMyLatestTransactions = (
  practitionerId: string,
  count: number,
  config: QueryOptionsConfig<TransactionResponse[]> = {},
) =>
  useQuery<TransactionResponse[], Error>({
    queryKey: [QUERY_KEY_ROOTS.MY_LATEST_TRANSACTIONS_DATA, practitionerId],
    queryFn: async () => getMyLatestTransactions(practitionerId, count),
    ...config,
  });

export const useUpdatePractitionerAccess = (
  practitionerId: string,
  queryClient: QueryClient,
) =>
  useMutation({
    mutationFn: updatePractitionerAccess,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA, practitionerId],
      }),
  });

export const useChangePassword = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: changePassword,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
      }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (error: RequestError) => {
      /* Kept to establish the type of the error */
    },
  });

export const useGetPractitionerGroups = (
  id: string,
  config: QueryOptionsConfig<GroupResponse[]> = {},
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.PRACTITIONER_ORGANISATION_GROUP_DATA,
      id,
    ] as QueryKey,
    queryFn: async () => {
      const { organisationGroups } = await getPractitionerGroups(id);
      return organisationGroups.length > 0 ? organisationGroups : [];
    },
    ...config,
  });

export const useGetPractitionerEvents = (
  practitionerId: string,
  config: QueryOptionsConfig<EventSummaryResponse[]> = {},
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONERS_DATA, practitionerId] as QueryKey,
    queryFn: async () => getPractitionerEvents(practitionerId),
    ...config,
  });

export const useGetMyTeams = (
  organisationId?: string | null,
  name?: string,
  enabled = true,
) =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.MY_TEAMS_DATA, organisationId, name],
    queryFn: async () => getMyTeams({ organisationId, name }),
    enabled,
  });

export const useGetAllPractitionerEvaluatorLinks = (
  id: string,
  config: QueryOptionsConfig<EvaluatorLinkResponse> = {},
) =>
  useQuery<EvaluatorLinkResponse, Error>({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_EVALUATOR_LINKS, id],
    queryFn: async () => getPractitionerEvaluatorLinks(id),
    ...config,
  });

export const useGetLearnerTeams = (learnerId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY_ROOTS.LEARNER_TEAM_DATA, learnerId],

    queryFn: async () => getLearnerTeams(learnerId),
  });
};

export const useRemoveProfileContributions = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeProfileCotributors,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA],
      });
    },
  });
};
