import {
  useQuery,
  useMutation,
  QueryKey,
  QueryClient,
  UseQueryResult,
  UseMutationResult,
} from '@tanstack/react-query';
import { QueryOptionsConfig } from 'api/hooks';
import {
  PractitionerSummaryData,
  EventSummaryResponse,
  OrganisationResponse,
  RequestError,
  EvaluatorLinkResponse,
  EventResponse,
  LearnerWithEvaluation,
  ProfileResponse,
  UserInvite,
  TeamResponse,
  SupportedRolesResponse,
  OrganisationSupportedRole,
  ArchivedProfileResponse,
} from 'api/httpEntities';
import { QUERY_KEY_ROOTS } from 'variables';
import {
  CreateMultiOrgTeamRequest,
  createMultiOrgTeam,
  getOrgsWithRevokeAccessFlag,
  purchaseProfilesForTeam,
} from 'api/api';
import {
  CreateEventData,
  InviteUserData,
  OrganisationEditRequest,
  OrganisationMoveRequest,
  OrganisationRequest,
  PurchaseLearnerRequest,
  TeamRequest,
} from './organisationApitypes';
import {
  getPractitionersSummary,
  getOrganisationEvents,
  inviteUser,
  addOrganisation,
  getAllOrganisations,
  getOrganisation,
  updateOrganisation,
  moveOrganisation,
  deleteOrganisation,
  getLearnerByOrganisation,
  getProfilesByOrganisation,
  getArchivedProfilesByOrganisation,
  purchaseLearnerChapters,
  getOpenPractitionerInvites,
  addTeam,
  purchaseProfilesForEvaluatorLink,
  getEvaluatorLinks,
  createNewEvaluatorLink,
  getEvaluationsProfilesByOrg,
  createEvent,
  getEvaluationsProfilesByOrgs,
  getSupportedRoles,
  getAllSupportedRoles,
} from './organisations';

export const useGetPractitionersSummary = (
  organisationId: string,
  config: QueryOptionsConfig<PractitionerSummaryData[]> = {},
): UseQueryResult<PractitionerSummaryData[], Error> =>
  useQuery<PractitionerSummaryData[], Error>({
    queryKey: [QUERY_KEY_ROOTS.PRACTITIONERS_SUMMARY_DATA, organisationId],
    queryFn: async () => getPractitionersSummary(organisationId),
    ...config,
  });

export const useCreateEvent = (): UseMutationResult<
  EventResponse,
  unknown,
  CreateEventData,
  unknown
> =>
  useMutation({
    mutationFn: createEvent,
  });

export const useGetOrgEvents = (
  organisationId: string,
  status: string,
  config: QueryOptionsConfig<EventSummaryResponse[]> = {},
): UseQueryResult<EventSummaryResponse[], Error> =>
  useQuery<EventSummaryResponse[], Error>({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_EVENT_DATA, organisationId, status],
    queryFn: async () => getOrganisationEvents(organisationId, status),
    ...config,
  });

export const useInviteUser = (
  organisationId: string,
  queryClient: QueryClient,
): UseMutationResult<Response, unknown, InviteUserData, unknown> =>
  useMutation({
    mutationFn: inviteUser,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.OPEN_PRACTITIONER_INVITES_DATA,
          organisationId,
        ],
      }),
  });

export const useAddOrganisation = (
  queryClient: QueryClient,
): UseMutationResult<
  OrganisationResponse,
  unknown,
  OrganisationRequest,
  unknown
> =>
  useMutation({
    mutationFn: addOrganisation,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.ORGANISATIONS_DATA],
      }),
  });

export const useGetAllOrganisations = (
  config: QueryOptionsConfig<OrganisationResponse[]> = {},
): UseQueryResult<OrganisationResponse[], Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATIONS_DATA] as QueryKey,
    queryFn: async () => getAllOrganisations(),
    ...config,
  });

export const useGetOrganisation = (
  organisationId?: string,
  config?: { enabled: boolean },
): UseQueryResult<OrganisationResponse, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_DATA, organisationId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getOrganisation(organisationId!),
    ...{ enabled: !!organisationId, ...config },
  });

export const useGetSupportedRoles = (
  organisationId?: string,
  config?: { enabled: boolean },
): UseQueryResult<SupportedRolesResponse, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_DATA, organisationId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getSupportedRoles(organisationId!),
    ...{ enabled: !!organisationId, ...config },
  });

export const useGetAllSupportedRoles = (
  config: QueryOptionsConfig<OrganisationSupportedRole[]> = {},
): UseQueryResult<OrganisationSupportedRole[], Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.SUPPORTED_ROLES_DATA] as QueryKey,
    queryFn: async () => getAllSupportedRoles(),
    ...config,
  });

export const useUpdateOrganisation = (
  organisationId: string,
  queryClient: QueryClient,
): UseMutationResult<
  OrganisationResponse,
  RequestError,
  OrganisationEditRequest
> =>
  useMutation<OrganisationResponse, RequestError, OrganisationEditRequest>({
    mutationFn: updateOrganisation,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.ORGANISATION_DATA,
          QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA,
          organisationId,
        ],
      }),
  });

export const useMoveLearnerOrganisation = (
  organisationId: string,
  queryClient: QueryClient,
): UseMutationResult<void, RequestError, OrganisationMoveRequest, unknown> =>
  useMutation({
    mutationFn: moveOrganisation,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEY_ROOTS.ORGANISATION_DATA, organisationId],
        })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.EVENTS_DATA] })
        .then();
    },
  });

export const useDeleteOrganisation = (
  queryClient: QueryClient,
): UseMutationResult<void, RequestError, string, unknown> =>
  useMutation({
    mutationFn: deleteOrganisation,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.ORGANISATION_DATA,
          QUERY_KEY_ROOTS.ORGANISATIONS_DATA,
        ],
      }),
  });

export const useGetLearnerByOrganisation = (
  learnerId: string,
  organisationId: string,
): UseQueryResult<LearnerWithEvaluation, RequestError> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.LEARNER_DATA, organisationId, learnerId],
    queryFn: async () => getLearnerByOrganisation(learnerId, organisationId),
  });

export const useGetProfilesByOrganisation = (
  learnerId: string,
  organisationId: string,
): UseQueryResult<ProfileResponse[], RequestError> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA, learnerId, organisationId],
    queryFn: async () => {
      const { profiles } = await getProfilesByOrganisation(
        learnerId,
        organisationId,
      );
      return profiles;
    },
  });

export const useGetArchivedProfilesByOrganisation = (
  learnerId: string,
  organisationId: string,
  betaAvailable: boolean,
): UseQueryResult<ArchivedProfileResponse[], RequestError> =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.ARCHIVED_PROFILES_DATA,
      learnerId,
      organisationId,
    ],
    queryFn: async () => {
      const { profiles: archivedProfiles } =
        await getArchivedProfilesByOrganisation(learnerId, organisationId);
      return archivedProfiles;
    },
    enabled: betaAvailable,
  });

export const usePurchaseLearnerChapter = (
  queryClient: QueryClient,
): UseMutationResult<any, RequestError, PurchaseLearnerRequest, unknown> =>
  useMutation({
    mutationFn: purchaseLearnerChapters,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.LEARNER_DATA] })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA] })
        .then();
    },
  });

export const useGetOpenPractitionerInvites = (
  organisationId: string,
): UseQueryResult<UserInvite[]> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.OPEN_PRACTITIONER_INVITES_DATA, organisationId],
    queryFn: async () => {
      const { invites } = await getOpenPractitionerInvites(organisationId);
      return invites;
    },
  });

export const useAddTeam = (
  queryClient: QueryClient,
): UseMutationResult<
  TeamResponse,
  unknown,
  { organisationId: string; formData: TeamRequest },
  unknown
> =>
  useMutation({
    mutationFn: addTeam,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAMS_DATA] }),
  });

export const useAddMultiOrgTeam = (
  queryClient: QueryClient,
): UseMutationResult<
  TeamResponse,
  unknown,
  CreateMultiOrgTeamRequest,
  unknown
> => {
  return useMutation({
    mutationFn: createMultiOrgTeam,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAMS_DATA] }),
  });
};
export const usePurchaseProfilesForEvaluatorLink = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: purchaseProfilesForEvaluatorLink,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA],
      }),
  });

export const useGetOrganisationEvaluatorLinks = (
  id: string,
  config: QueryOptionsConfig<EvaluatorLinkResponse> = {},
) =>
  useQuery<EvaluatorLinkResponse, Error>({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_EVALUATOR_LINKS, id],
    queryFn: async () => getEvaluatorLinks(id),
    ...config,
  });

export const useCreateNewEvaluatorLink = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: createNewEvaluatorLink,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.ORGANISATION_EVALUATOR_LINKS],
      }),
  });

export const useGetEvaluationsByOrg = (
  organisationId: string,
  evaluationIds: string[],
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.EVALUATOR_PROFILES,
      organisationId,
      evaluationIds.join(),
    ],
    queryFn: async () => {
      const { evaluations } = await getEvaluationsProfilesByOrg({
        organisationId,
        evaluationIds,
      });
      return evaluations;
    },
  });

export const useGetEvaluationsByOrgs = (
  organisationIds: string[],
  evaluationIds: string[],
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.EVALUATOR_PROFILES,
      organisationIds?.join() ?? '',
      evaluationIds.join(),
    ],
    queryFn: async () => {
      const { evaluations } = await getEvaluationsProfilesByOrgs({
        organisationIds,
        evaluationIds,
      });
      return evaluations;
    },
  });

export const useGetOrgsWithRevokeAccessFlag = (
  practitionerId: string,
  config: QueryOptionsConfig<OrganisationResponse[]>,
) => {
  return useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.PRACTITIONER_DATA,
      practitionerId,
      'organisationsWithRevoke',
    ] as QueryKey,
    queryFn: async () => await getOrgsWithRevokeAccessFlag(practitionerId),
    ...config,
  });
};

export const usePurchaseProfilesForTeam = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: purchaseProfilesForTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA],
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_DATA] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.TEAM_LEARNERS_DATA],
      });
    },
  });
