import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { OrganisationAutocompleteV2 } from 'components/OrganisationPicker/OrganisationAutocomplete';
import { Span, TextAreaV2 } from '@insights-ltd/design-library/components';
import { styled } from '@mui/material';
import { GroupResponse, OrganisationResponse } from 'api';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import AccountOrganisationCard from 'components/OrganisationInfoCard/AccountOrganisationCard/AccountOrganisationCard';
import LinkButton from 'components/LinkButton';
import { useAuth } from 'contexts/AuthContext';
import { isSuperAdmin } from 'utils/role';

type Props = {
  open: boolean;
  onClose: () => void;
  organisationId: string;
  organisations: OrganisationResponse[];
  groups: GroupResponse[];
  organisationsInGroups?: GroupOrganisationHashMap;
  currentOrganisationCard?: React.ReactElement;
  handleOrganisationChange: (orgId: string, reason: string) => void;
};

const StyledTextArea = styled(TextAreaV2)({});

const StyledDiv = styled('div')({});

const StyledLabel = styled('label')({ marginBottom: '0.25rem !important' });

const ChangeOrgDialog = ({
  open,
  onClose,
  organisationId,
  organisations,
  groups,
  organisationsInGroups,
  currentOrganisationCard: CurrentOrganisation,
  handleOrganisationChange,
}: Props) => {
  const { t } = useTranslation();
  const [selectedOrgId, setSelectedOrgId] = useState(organisationId);
  const [reason, setReason] = useState('');
  const { user } = useAuth();
  const userIsSuperAdmin = isSuperAdmin(user?.roles);

  useEffect(() => {
    if (open) {
      setSelectedOrgId(organisationId);
    }
  }, [open, organisationId]);

  const currentGroup = useMemo(
    () =>
      groups.find((group) =>
        group.organisations.some((org) => org.id === organisationId),
      ),
    [groups, organisationId],
  );

  const filteredOrganisations = useMemo(
    () =>
      currentGroup
        ? currentGroup.organisations.filter(({ id }) => id !== organisationId)
        : [],
    [currentGroup, organisationId],
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const filteredOrgsSuperAdmin = organisations!.filter(
    ({ id }) => id !== organisationId,
  );

  const selectedOrganisation = useMemo(
    () =>
      organisations?.find(
        ({ id }) => id === selectedOrgId && id !== organisationId,
      ),
    [selectedOrgId, organisationId, organisations],
  );
  const buttonDisabled = selectedOrgId === organisationId;

  const newOrganisationElement = selectedOrganisation ? (
    <>
      <StyledLabel htmlFor="organisation-search">
        <Span variant="body-bold">
          {t('ui.event-management.learners.organisation.new-organisation')}
        </Span>
      </StyledLabel>
      <AccountOrganisationCard
        id={selectedOrganisation.id}
        name={selectedOrganisation.name}
        dataRetentionPolicy={selectedOrganisation.dataRetentionPolicy}
        variant="small"
        sx={{ paddingTop: '4px' }}
        parent={organisationsInGroups?.[selectedOrganisation.id]}
        titleAction={
          <LinkButton
            sx={{
              minWidth: '50px',
              padding: '0 !important',
              justifySelf: 'flex-end',
              alignSelf: 'flex-end',
            }}
            onClick={() => setSelectedOrgId(organisationId)}
          >
            {t('ui.event-management.learners.organisation.change')}
          </LinkButton>
        }
      />
    </>
  ) : (
    <OrganisationAutocompleteV2
      options={
        userIsSuperAdmin ? filteredOrgsSuperAdmin : filteredOrganisations
      }
      onSelect={(organisation) => setSelectedOrgId(organisation.id)}
      organisationsInGroups={organisationsInGroups}
      selectedValue={selectedOrgId}
      loading={false}
      disableClearable
      labelKey="ui.event-management.learners.organisation.new-organisation"
    />
  );

  return (
    <Dialog
      sx={{ '& > div > div': { width: '360px' } }}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        data-testid="confirmation-dialog-title"
        sx={{
          padding: '11px 1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontWeight: '600',
          fontSize: '18px',
        }}
      >
        {t('ui.event-management.learners.organisation.change-org.title')}
        <IconButton
          aria-label="close"
          data-testid="close-dialog"
          onClick={onClose}
          size="large"
          sx={{ padding: '0 !important' }}
        >
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers id="alert-dialog-description">
        <StyledDiv sx={{ margin: '0 0 1rem' }}>
          <StyledLabel htmlFor="organisation-search">
            <Span variant="body-bold">
              {t('ui.event-management.learner.organisation')}
            </Span>
          </StyledLabel>
          {CurrentOrganisation}
        </StyledDiv>
        <StyledDiv sx={{ margin: '0 0 1rem' }}>
          {newOrganisationElement}
        </StyledDiv>
        <StyledDiv sx={{ margin: '0 0 22px' }}>
          <StyledTextArea
            maxLength={500}
            fullWidth
            multiline
            minRows={3}
            label={t('ui.event-management.learner.why-moving')}
            variant="outlined"
            placeholder={t('ui.event-management.learner.provide-reason')}
            disabled={selectedOrgId === organisationId}
            onChange={(event) => setReason(event.target.value)}
            hideCount
          />
        </StyledDiv>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem' }}>
        <Button
          fullWidth
          data-testid="change-org-button"
          onClick={() => {
            onClose();
            handleOrganisationChange(selectedOrgId, reason);
          }}
          color="primary"
          variant="contained"
          disabled={buttonDisabled}
        >
          {t('ui.event-management.learners.organisation.change-org')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeOrgDialog;
