export const formatTimeForTimezone = (timezone: string): string => {
  try {
    const formatter = new Intl.DateTimeFormat('en-GB', {
      timeZone: timezone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    const currentTime = new Date();
    return formatter.format(currentTime);
  } catch {
    return 'Invalid timezone';
  }
};
