import {
  authenticatedFetch,
  handleContentDisposition,
  unauthenticatedFetch,
} from 'api/fetch';
import {
  EvaluatorLinkResponse,
  EventSummaryResponse,
  FilteredLearnerResponse,
  ForgottenPasswordData,
  GroupResponse,
  LearnerAndInviteeResponse,
  OrganisationResponse,
  PasswordUpdateRequest,
  PractitionerData,
  PractitionerProductsResponse,
  SignInRequest,
  SignUpRequest,
  TeamResponse,
  TransactionResponse,
  WalletResponse,
  InitiateSignInRequest,
  InitiateSignInResponse,
  FederatedSignUpRequest,
  FederatedSignUpResponse,
} from 'api/httpEntities';
import { cleanObject } from 'api/utils';
import { AllDialectOptions } from 'types/dialects';
import { sortOrgs } from 'utils/mappers/sorting';
import { AnonymisationType, EventType } from 'types/types';
import {
  AddPractitionerToOrganisationsRequest,
  DeletePractitionerData,
  DeleteUserInviteData,
  EventsResponse,
  GetMyTeamRequest,
  InviteUserDataV2,
  LearnerUpdateRequest,
  MultiOrgsForUserSearch,
  PractitionerUpdateRequest,
  ReinviteUserData,
  ResetPasswordRequest,
  InviteTokenResponse,
} from './practitionerApiTypes';

const processPractitionerResponse = (
  practitionerData: PractitionerData,
): PractitionerData => ({
  ...practitionerData,
  licensedProducts: [...practitionerData.licensedProducts].sort(),
});

export const validateInvite = async (
  token: string,
): Promise<InviteTokenResponse> => {
  const response = await unauthenticatedFetch(
    `/api/v1/practitioners/invites/${token}`,
  );

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  return await response.json();
};

export const signUp = async (data: SignUpRequest): Promise<void> => {
  const { token, fullName, password, preferredDialect } = data;
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/invites/${token}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fullName, password, preferredDialect }),
    },
  );

  await json();
};

export const federatedSignUp = async (
  request: FederatedSignUpRequest,
): Promise<FederatedSignUpResponse> => {
  const { token, fullName, preferredDialect } = request;
  const response = await authenticatedFetch(
    `/api/v1/practitioners/invites/federated/${token}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fullName, preferredDialect }),
    },
  );

  return await response.json();
};

export const getPractitioner = async (): Promise<PractitionerData> => {
  const response = await unauthenticatedFetch('/api/v1/practitioners/me');

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  const practitionerData: PractitionerData = await response.json();
  return processPractitionerResponse({
    ...practitionerData,
    preferredDialect: practitionerData.preferredDialect ?? 'en-GB',
  });
};

export const getPractitionerById = async (
  practitionerId: string,
): Promise<PractitionerData> => {
  const { json } = await authenticatedFetch<PractitionerData>(
    `/api/v1/practitioners/${practitionerId}`,
  );
  const data = await json();

  return processPractitionerResponse(data);
};

export const getOrganisationsForPractitioner = async (
  practitionerId: string,
): Promise<OrganisationResponse[]> => {
  const { json } = await authenticatedFetch<{
    organisations: OrganisationResponse[];
  }>(`/api/v1/practitioners/${practitionerId}/organisations`);
  const { organisations } = await json();

  return (organisations ?? []).sort(sortOrgs);
};

export const getProductsForPractitioner = async (
  practitionerId: string,
): Promise<PractitionerProductsResponse> => {
  const { json } = await authenticatedFetch<PractitionerProductsResponse>(
    `/api/v1/practitioners/${practitionerId}/products`,
  );

  return json();
};

export const deletePractitionerFromOrg = async (
  data: DeletePractitionerData,
): Promise<void> => {
  const url = `/api/v1/organisations/${data.organisationId}/practitioners/${data.practitionerId}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });

  await json();
};

export const emailProfile = async (
  learnerId: string,
  profileId: string,
  dialect: AllDialectOptions,
  format?: string,
  anonymisationType?: AnonymisationType,
) => {
  const urlSearchParams = new URLSearchParams({ dialect });

  if (format) urlSearchParams.append('format', format);
  if (anonymisationType) {
    urlSearchParams.append('anonymisationType', anonymisationType);
  }

  const url = `/api/v1/practitioners/me/learners/${learnerId}/profiles/${profileId}/email?${urlSearchParams.toString()}`;
  const { success, json } = await authenticatedFetch(`${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  await json();

  return success;
};

export const getPractitionerByEmail = async (
  emailAddress: string,
): Promise<PractitionerData | null> => {
  const { status, json } = await authenticatedFetch(
    `/api/v1/practitioners?email=${encodeURIComponent(emailAddress)}`,
  );

  if (status === 204) {
    return null;
  }
  return json();
};

export const getPractitioners = async (
  organisationId: string,
): Promise<Array<PractitionerData>> => {
  const { json } = await authenticatedFetch<{
    practitioners: PractitionerData[];
  }>(`/api/v1/organisations/${organisationId}/practitioners`);

  const { practitioners } = await json();

  return practitioners.map(processPractitionerResponse);
};

export const getEventPractitioners = async (
  organisationId: string,
  eventType?: EventType,
): Promise<Array<PractitionerData>> => {
  const { json } = await authenticatedFetch<{
    practitioners: PractitionerData[];
  }>(
    `/api/v1/organisations/${organisationId}/events/practitioners?eventType=${eventType}`,
  );

  const { practitioners } = await json();
  return practitioners.map(processPractitionerResponse);
};

export const getPractitionerEvents = async (
  practitionerId: string,
  status?: string,
): Promise<Array<EventSummaryResponse>> => {
  const { json } = await authenticatedFetch<{ events: EventSummaryResponse[] }>(
    `/api/v1/practitioners/${practitionerId}/events?status=${
      status || 'ACTIVE'
    }`,
  );

  const { events } = await json();

  return events;
};

export const signIn = async (
  credentials: SignInRequest,
): Promise<PractitionerData> => {
  const response = await unauthenticatedFetch('/api/v1/practitioners/signins', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};

export const initiateSignIn = async ({
  emailAddress,
}: InitiateSignInRequest): Promise<InitiateSignInResponse> => {
  const searchParams = new URLSearchParams({ email: emailAddress });
  const response = await unauthenticatedFetch(
    `/api/v1/practitioners/signins/initiate?${searchParams}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};

export const signOut = async (): Promise<Response> => {
  const { json } = await authenticatedFetch('/api/v1/signout', {
    method: 'POST',
  });

  return json();
};

export const reinviteUser = async (data: ReinviteUserData): Promise<void> => {
  const { emailAddress } = data;
  const url = '/api/v1/practitioners/reinvites';

  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ emailAddress, dialect: 'en-GB' }),
  });

  await json();
};

export const deleteUserInvite = async (
  data: DeleteUserInviteData,
): Promise<void> => {
  const url = '/api/v1/practitioners/invites';
  const { json } = await authenticatedFetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  await json();
};

export const forgottenPassword = async (
  recoveryData: ForgottenPasswordData,
): Promise<Response> => {
  const url = '/api/v1/practitioners/password-resets';
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...recoveryData, dialect: 'en-GB' }),
  });
  return json();
};

export const inviteUserV2 = async (userData: InviteUserDataV2) => {
  const { json } = await authenticatedFetch('/api/v1/practitioners/invites', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
  });
  return json();
};

export const resetPassword = async (
  data: ResetPasswordRequest,
): Promise<void> => {
  const { token, password } = data;

  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/password-resets/${token}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password }),
    },
  );

  await json();
};

export const updatePractitioner = async ({
  practitionerId = 'me',
  formData,
}: PractitionerUpdateRequest): Promise<void> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/${practitionerId}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    },
  );

  await json();
};

export const addPractitionerToOrganisations = async ({
  practitionerId,
  organisationIds,
}: AddPractitionerToOrganisationsRequest) => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/${practitionerId}/organisations`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ organisationIds }),
    },
  );

  return json();
};

export const deleteLearner = async (learnerId: string): Promise<void> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/me/learners/${learnerId}`,
    {
      method: 'DELETE',
    },
  );
  await json();
};

export const updateLearner = async ({
  learnerId,
  formData,
}: LearnerUpdateRequest): Promise<void> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/me/learners/${learnerId}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    },
  );

  await json();
};

export const getAllLearnersAndInvitees = async (): Promise<
  LearnerAndInviteeResponse[]
> => {
  const { json } = await authenticatedFetch<{
    learners: LearnerAndInviteeResponse[];
  }>('/api/v1/practitioners/me/learners');

  const { learners } = await json();

  return learners;
};

export const getFilteredLearners = async (
  betaEnabled: boolean,
  searchTerm: string,
  organisationId?: string | null,
): Promise<FilteredLearnerResponse> => {
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  const searchUrl = `/api/v1/practitioners/me/learners?searchTerm=${encodedSearchTerm}`;

  const apiUrl = organisationId
    ? `${searchUrl}&organisation-id=${organisationId}`
    : searchUrl;

  const { json } = await authenticatedFetch(apiUrl, {
    headers: {
      ...(betaEnabled && { 'insights-beta': '' }),
    },
  });

  return json();
};

export const getOrganisationLearners = async (
  organisationId: string,
): Promise<FilteredLearnerResponse> => {
  const { json } = await authenticatedFetch<FilteredLearnerResponse>(
    `/api/v1/practitioners/me/organisations/${organisationId}/learners`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return json();
};

export const searchLearners = async (
  searchTerm: string,
  organisations: MultiOrgsForUserSearch,
): Promise<FilteredLearnerResponse> => {
  const searchUrl = `/api/v1/practitioners/me/learner-searches`;
  const { json } = await authenticatedFetch(searchUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ searchTerm, organisations }),
  });
  return json();
};

export const getAllLearnerEvents = async (
  learnerId: string,
): Promise<EventsResponse> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/me/learners/${learnerId}/events`,
  );

  return json();
};

export const getPractitionerWallet = async (
  practitionerId: string,
): Promise<WalletResponse> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/${practitionerId}/wallet`,
  );

  return json();
};

export const getMyLatestTransactions = async (
  practitionerId: string,
  count: number,
): Promise<TransactionResponse[]> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/${practitionerId}/wallet/transactions?num=${count}`,
  );

  return json();
};

export const getMyTeams = async (
  queryParams: GetMyTeamRequest,
): Promise<{ teams: TeamResponse[]; limitExceeded: boolean }> => {
  const queries = new URLSearchParams(
    cleanObject(queryParams as Record<string, string>),
  );
  const { json } = await authenticatedFetch<{
    teams: TeamResponse[];
    limitExceeded: boolean;
  }>(`/api/v1/practitioners/me/teams?${queries.toString()}`);

  return json();
};

export const updatePractitionerAccess = async ({
  practitionerId,
  enabled,
}: {
  practitionerId: string;
  enabled: boolean;
}): Promise<void> => {
  const url = `/api/v1/practitioners/${practitionerId}/access?enabled=${enabled}`;
  const { json } = await authenticatedFetch(url, {
    method: 'PUT',
  });

  await json();
};

export const changePassword = async (
  formData: PasswordUpdateRequest,
): Promise<void> => {
  const { json } = await authenticatedFetch(
    `/api/v1/practitioners/password-change`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...formData }),
    },
  );

  return json();
};

export const getDownloadFileBlobForTransactionHistory = async ({
  externalId,
  to,
  from,
}: {
  externalId: string;
  to: string;
  from: string;
}): Promise<{ blob: Blob; filename: string }> => {
  const response = await authenticatedFetch(
    `/api/v1/practitioners/${externalId}/wallet/transactions?to=${to}&from=${from}`,
    {
      method: 'GET',
      headers: { Accept: 'text/csv' },
    },
  );
  const downloadData = await handleContentDisposition(response);
  return downloadData;
};

export const getDownloadFileBlobForProfile = async (
  learnerId: string,
  profileId: string,
  dialect: string,
  anonymisationType?: AnonymisationType,
): Promise<{ blob: Blob; filename: string }> => {
  const urlSearchParams = new URLSearchParams({ dialect });
  if (anonymisationType) {
    urlSearchParams.append('anonymisationType', anonymisationType);
  }

  const url = `/api/v1/practitioners/me/learners/${learnerId}/profiles/${profileId}?${urlSearchParams.toString()}`;

  const response = await authenticatedFetch(url, {
    method: 'GET',
    headers: { Accept: 'application/pdf' },
  });
  const downloadData = await handleContentDisposition(response);
  return downloadData;
};

export const getPractitionerEvaluatorLinks = async (practitionerId: string) => {
  const { json } = await authenticatedFetch<EvaluatorLinkResponse>(
    `/api/v1/practitioners/${practitionerId}/evaluator-links`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return json();
};

export const getPractitionerGroups = async (practitionerId: string) => {
  const url = `/api/v1/practitioners/${practitionerId}/organisation-groups`;

  const { json } = await authenticatedFetch<{
    organisationGroups: GroupResponse[];
  }>(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  return json();
};

export const getLearnerTeams = async (
  learnerId: string,
): Promise<TeamResponse[]> => {
  const url = `/api/v1/practitioners/me/learners/${learnerId}/teams`;
  const { json } = await authenticatedFetch<{ teams: TeamResponse[] }>(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const { teams } = await json();
  return teams;
};

export const removeProfileCotributors = async ({
  learnerId,
  profileId,
  contributorsIds,
}: {
  learnerId: string;
  profileId: string;
  contributorsIds: string[];
}) => {
  const url = `/api/v1/practitioners/me/learners/${learnerId}/profiles/${profileId}/contributions`;

  await authenticatedFetch<{ me: PractitionerData }>(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ contributorsIds }),
  });
};

export const getOrgsWithRevokeAccessFlag = async (practitionerId: string) => {
  const url = `/api/v1/practitioners/${practitionerId}/organisations/with-revocation`;
  const { json } = await authenticatedFetch<{
    organisations: OrganisationResponse[];
  }>(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const { organisations } = await json();
  return organisations;
};
