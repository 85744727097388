import { DateTime, Duration } from 'luxon';

const USER_CREATED_DATE_FORMAT = 'dd MMM yyyy';

export const isoToLocaleString = (isoDate: string, locale = 'en-GB') =>
  DateTime.fromISO(isoDate).toFormat(USER_CREATED_DATE_FORMAT, { locale });

export const isoToDuration = (retentionPeriod: string): Duration => {
  const duration = Duration.fromISO(retentionPeriod);

  return duration;
};

export const mergeDateTimeAndZone = (
  date: DateTime,
  time: DateTime,
  zone: string,
): DateTime =>
  DateTime.local(date.year, date.month, date.day, time.hour, time.minute, {
    zone,
  });

export const toShareIsoFormatFromDateTime = (datetime: DateTime) =>
  datetime.toISO({
    suppressMilliseconds: true,
  }) as string;

export const toDownloadRangeFormatFromDateTime = (date: DateTime): string => {
  if (!date.isValid) {
    throw new Error('Invalid DateTime provided');
  }

  const isoDate = date.toUTC().toISODate();
  if (isoDate === null) {
    throw new Error('Failed to format DateTime to ISO string');
  }
  return isoDate;
};
