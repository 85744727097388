/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  ButtonV2,
  DatePickerV2,
  H1,
  Link,
  P,
  Span,
  SvgMoveDown1Regular,
  TextV2,
} from '@insights-ltd/design-library/components';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { useParams } from 'react-router-dom';
import downloadTransactionHistory from 'pages/Transactions/downloadTransactionHistory';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { isMultiOrg } from 'utils/isMultiOrg';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import {
  useGetAllOrganisations,
  useGetMyLatestTransactions,
  useGetOrganisation,
  useGetPractitionerById,
} from 'api';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import { Card } from '@mui/material';
import { DateTime } from 'luxon';
import { isValidPastDateRange } from 'components/EventForms/validation';
import { toDownloadRangeFormatFromDateTime } from 'utils/dates';
import TransactionsTable from './TransactionsTable';

type DateTimeState = DateTime | null;

const Transactions = () => {
  const [startDate, setStartDate] = useState<DateTimeState>(
    DateTime.now().minus({ days: 90 }),
  );
  const [endDate, setEndDate] = useState<DateTimeState>(DateTime.now());
  const { t } = useTranslation();
  const { practitionerId, organisationId } = useParams<{
    organisationId?: string;
    practitionerId: string;
  }>();
  const { data: practitioner } = useGetPractitionerById(practitionerId);
  const myLatestTransactionsQuery = useGetMyLatestTransactions(
    practitionerId || '',
    30,
  );
  const { data: transactions } = myLatestTransactionsQuery;

  const organisationQuery = useGetOrganisation(organisationId);
  const { data: organisation } = organisationQuery;
  const allOrganisationsQuery = useGetAllOrganisations();
  const { data: practitionerOrganisations } = allOrganisationsQuery;
  const isMultiOrgView = isMultiOrg(practitionerOrganisations);
  const status = queryStatusIgnoreIdle(
    myLatestTransactionsQuery,
    organisationQuery,
    allOrganisationsQuery,
  );

  const { openErrorModal } = useRequestErrorContext();

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.my-account.error.load')}
      />
    );
  }
  const myTransactions = !organisationId;

  const TRANSACTION_EXPORT_LIMIT_DAYS = 396;
  const exportDisabled = !isValidPastDateRange(
    startDate,
    endDate,
    TRANSACTION_EXPORT_LIMIT_DAYS,
  );

  const handleClearDateRange = () => {
    setStartDate(null);
    setEndDate(null);
  };
  const handleExport = () => {
    downloadTransactionHistory({
      externalId: practitionerId || '',
      from: toDownloadRangeFormatFromDateTime(startDate!),
      to: toDownloadRangeFormatFromDateTime(endDate!),
    }).catch(() => openErrorModal());
  };

  const crumbs: { [key: string]: string } = myTransactions
    ? {
        '/': t('ui.event-management.dashboard.home.title'),
        '/account': t('ui.event-management.my-account.title'),
      }
    : {
        '/': t('ui.event-management.dashboard.home.title'),
        '/organisations': t('ui.event-management.organisations.title'),
        [`/organisations/${organisation?.id}`]: organisation?.name || '',
        [`/organisations/${organisation?.id}/practitioners/${practitioner?.id}`]:
          practitioner?.fullName || '',
      };
  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.transactions.title')}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={crumbs}
              activeText={t('ui.event-management.transactions.title')}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid container wrap="nowrap" item xs={12} md={6}>
              <H1 variant="h2">
                {myTransactions ? (
                  t('ui.event-management.my-transactions.header')
                ) : (
                  <Trans
                    i18nKey="ui.event-management.transactions.header"
                    components={{ bold: <Span variant="body-bold" /> }}
                    values={{ fullName: practitioner?.fullName || '' }}
                  />
                )}
              </H1>
            </Grid>
          </Grid>
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.M)}
            mb={(theme) => theme.spacing(spacingSizeMap.L)}
          >
            <Card elevation={1} sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <TextV2
                sx={{ marginLeft: '16px' }}
                variant="bodyBold"
                color="textSecondary"
              >
                {t(
                  'ui.event-management.my-account.transactions.export_transactions-card_heading',
                )}
              </TextV2>
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={2}
                width="100%"
                marginLeft="0px"
                paddingRight={2}
                marginBottom={1}
                justifyContent="flex-start"
              >
                <Grid item>
                  <DatePickerV2
                    value={startDate}
                    onChange={(event) => {
                      setStartDate(event as DateTime);
                    }}
                    sx={{ width: '329px' }}
                    labelText={t(
                      'ui.event-management.events.create.label.start-date',
                    )}
                    disableFuture
                    maxDate={endDate || DateTime.local()}
                  />
                </Grid>
                <Grid item xs={12} md="auto">
                  <TextV2>
                    {t('ui.event-management.events.create.label.to')}
                  </TextV2>
                </Grid>
                <Grid item>
                  <DatePickerV2
                    value={endDate}
                    onChange={(event) => setEndDate(event as DateTime)}
                    sx={{ width: '329px' }}
                    labelText={t(
                      'ui.event-management.events.create.label.end-date',
                    )}
                    disableFuture
                  />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={{ md: 'flex-start' }}
                  alignItems="center"
                  height="4rem"
                  xs={true}
                >
                  <Link
                    href="#"
                    id="clear"
                    sx={{ whiteSpace: 'pre' }}
                    onClick={handleClearDateRange}
                  >
                    {t(
                      'ui.event-management.group.add-remove-organisation.dialog.clear',
                    )}
                  </Link>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={{ md: 'flex-end' }}
                  xs={12}
                  md={true}
                >
                  <ButtonV2
                    disabled={exportDisabled}
                    buttonIcon={<SvgMoveDown1Regular />}
                    buttonText={t(
                      'ui.event-management.my-account.transactions.export_numbered_transactions',
                      { count: undefined },
                    )}
                    style={{ whiteSpace: 'pre' }}
                    onClick={handleExport}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextV2 variant="bodySmall">
                    {t(
                      'ui.event-management.my-account.transactions.export_limit',
                    )}
                  </TextV2>
                </Grid>
              </Grid>
            </Card>
          </Box>
          {transactions!.length ? (
            <>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <P color="textSecondary">
                    {t('ui.event-management.my-account.transactions.helper', {
                      count: transactions!.length,
                    })}
                  </P>
                </Grid>
              </Grid>

              <Box mb={(theme) => theme.spacing(spacingSizeMap.M)} />
              <TransactionsTable
                transactions={transactions!}
                isMultiOrgView={isMultiOrgView}
              />
            </>
          ) : (
            <P>{t('ui.event-management.my-account.transactions.empty')}</P>
          )}
        </Container>
      </FullHeight>
    </>
  );
};

export default Transactions;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
