import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Grid, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Evaluator } from 'types/types';
import { Dialects } from 'types/dialects';
import {
  Span,
  SvgShareExternalLinkCustom,
} from '@insights-ltd/design-library/components';
import { OrganisationResponse } from 'api/httpEntities';
import { getTheme, spacingSizeMap } from '@insights-ltd/design-library/themes';

export const truncateString = (str: string, characterLimit: number) => {
  if (str.length > characterLimit - 1) {
    return `${str.slice(0, characterLimit - 1)}...`;
  }
  return str;
};

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.grey[600],
  lineHeight: 1.75,
}));

const StyledSpan2 = styled(Span)(({ theme }) => ({
  color: `${theme.palette.grey[600]} !important`,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '0.5rem',
  padding: '0 !important',
  '> span': {
    marginBottom: theme.spacing(spacingSizeMap.XS),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
    lineHeight: '1.75',
  },
}));

const StyledH4 = styled('h4')({
  marginTop: '0',
  marginBottom: '0',
  lineHeight: 1.75,
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: 'none',
  fontSize: '1.125rem',

  ':hover': {
    textDecoration: 'underline',
  },
}));

const CompletedPrefix = styled('span')(({ theme }) => ({
  color: theme.palette.grey[600],
}));

interface StyledDivProps {
  completedEvaluators: number;
}

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'completedEvaluators',
})<StyledDivProps>(({ completedEvaluators, theme }) => ({
  width: '100%',
  height: '48px',
  marginTop: theme.spacing(spacingSizeMap.S),
  padding: '0',
  borderRadius: '4px',
  '> p': {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: `10px ${theme.spacing(spacingSizeMap.S)}`,
    '> span': {
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '1.75',
    },
  },
  backgroundColor:
    completedEvaluators > 0
      ? theme.palette.blue.light
      : theme.palette.grey[200],
}));

interface StyledAmountProps {
  completedEvaluators: number;
}

const StyledAmount = styled('div', {
  shouldForwardProp: (prop) => prop !== 'completedEvaluators',
})<StyledAmountProps>(({ completedEvaluators, theme }) => ({
  color:
    completedEvaluators > 1 ? theme.palette.blue.main : theme.palette.grey[600],
  fontWeight:
    completedEvaluators > 1 ? theme.typography.fontWeightBold : undefined,
}));

type Props = {
  id: string;
  evaluatorLink: string;
  name: string;
  organisation?: OrganisationResponse;
  completedEvaluators: number;
  linkCopied?: boolean;
  onCopy?: (id: string) => void;
  evaluatorModel: Evaluator;
  dialect: { code: Dialects; name: string };
};

const DirectLinkCard = ({
  id,
  evaluatorLink,
  name,
  completedEvaluators,
  organisation,
  linkCopied,
  onCopy,
  evaluatorModel = 'DDDRS',
  dialect,
}: Props) => {
  const localTheme = getTheme();
  const { t } = useTranslation();
  const copy = t(
    'ui.event-management.evaluator-links.direct-link-card.button.copy',
  );
  const copied = t(
    'ui.event-management.evaluator-links.direct-link-card.button.copied',
  );

  const [copyText, setCopyText] = useState<string>(linkCopied ? copied : copy);

  const dialectSearchParam = dialect.code ? `&dialect=${dialect.code}` : '';

  const link = `${evaluatorLink}?model=${evaluatorModel}${dialectSearchParam}`;

  useEffect(() => {
    setCopyText(linkCopied ? copied : copy);
  }, [copied, copy, linkCopied]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(link);
    if (copyText === copy) {
      setCopyText(copied);
      if (onCopy) {
        onCopy(id);
      }
    }
  };

  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        maxWidth: '395px',
        padding: theme.spacing(spacingSizeMap.S),
      })}
    >
      <>
        <Container
          sx={(theme) => ({
            padding: '0 !important',
            '> span': {
              marginBottom: theme.spacing(spacingSizeMap.XS),
              fontWeight: theme.typography.fontWeightBold,
              fontSize: theme.typography.fontSize,
              lineHeight: '1.75',
            },
          })}
        >
          <StyledH4>
            <StyledLink to={`/evaluator-links/${id}`}>
              {truncateString(name, 38)}
            </StyledLink>
          </StyledH4>
        </Container>
        <Grid container sx={{ marginBottom: '0.5rem' }}>
          {organisation && (
            <Grid item sm={12}>
              <StyledSpan>
                {t('ui.event-management.events.nav.my-org')}:&nbsp;
                <StyledLink
                  sx={(theme) => ({
                    fontSize: '1rem',
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                  to={`/organisations/${organisation.id}`}
                >
                  {truncateString(organisation.name, 30)}
                </StyledLink>
              </StyledSpan>
            </Grid>
          )}
          <Grid item sm={12}>
            <StyledSpan>
              <Trans
                t={t}
                i18nKey="ui.event-management.evaluator-links.direct-link-card.language"
                values={{ dialectName: dialect.name }}
                components={{
                  strong: <StyledSpan2 variant="body-bold" />,
                }}
              />
            </StyledSpan>
          </Grid>
        </Grid>
        <StyledContainer>
          <span>
            {t(
              'ui.event-management.evaluator-links.direct-link-card.link-title',
            )}
          </span>
          <StyledLink
            data-testid="external-link-icon"
            sx={{ marginLeft: '0.25rem' }}
            to={link}
            target="_blank"
          >
            <SvgShareExternalLinkCustom
              height={14}
              stroke={localTheme.brandColours.blue}
              strokeWidth={2}
            />
          </StyledLink>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TextField
              sx={(theme) => ({
                marginRight: theme.spacing(spacingSizeMap.XS),
              })}
              inputProps={{
                readOnly: true,
                style: {
                  width: '228px',
                  height: '48px',
                  padding: '0 14px',
                  cursor: 'pointer',
                },
                'data-testid': name,
              }}
              variant="outlined"
              defaultValue={link}
              onClick={() => handleCopy()}
              disabled
            />
            <Button
              sx={(theme) => ({
                maxWidth: '100px',
                maxHeight: '48px',
                minWidth: '100px',
                minHeight: '48px',
                width: '100%',
                height: '100%',
                padding: '0 !important',
                color: theme.palette.blue.main,
                border: `2px solid ${theme.palette.blue.main}`,
              })}
              variant="outlined"
              onClick={() => handleCopy()}
            >
              {copyText}
            </Button>
          </Box>
        </StyledContainer>
        <StyledDiv completedEvaluators={completedEvaluators}>
          <p>
            <CompletedPrefix>
              {t(
                'ui.event-management.evaluator-links.direct-link-card.completed-evaluators',
              )}
            </CompletedPrefix>
            &nbsp;
            <StyledAmount completedEvaluators={completedEvaluators}>
              {completedEvaluators}
            </StyledAmount>
          </p>
        </StyledDiv>
      </>
    </Card>
  );
};

export default DirectLinkCard;
