import {
  DatePickerV2,
  RadioGroupControl,
  TextV2,
  TimePickerV2,
} from '@insights-ltd/design-library/components';
import { formatDate } from '@insights-ltd/design-library/utils';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  DistributionFormat,
  EventResponse,
  useCancelShareSchedule,
  useSubmitShareSchedule,
} from 'api';
import {
  getErrorMessage,
  scheduleShareDateFieldRules,
  scheduleShareTimeFieldRules,
} from 'components/EventForms/validation';
import { DateTime } from 'luxon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { EventScheduleShare } from 'components/EventForms';
import {
  mergeDateTimeAndZone,
  toShareIsoFormatFromDateTime,
} from 'utils/dates';

export type SetScheduleShareDialogProps = {
  event: EventResponse;
  setEvent: (event: EventResponse) => void;
  open: boolean;
  onClose: () => void;
};

const SetScheduleShareDialog = ({
  event,
  setEvent,
  open,
  onClose,
}: SetScheduleShareDialogProps) => {
  const { t } = useTranslation();

  const zone = event?.timezone?.replace('GMT', 'UTC') ?? 'UTC';

  const shareProfilesAt = event?.shareProfilesAt
    ? DateTime.fromISO(event?.shareProfilesAt, { zone })
    : undefined;

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
    resetField,
  } = useForm<EventScheduleShare>({
    defaultValues: {
      shareProfilesAtDate: shareProfilesAt,
      shareProfilesAtTime: shareProfilesAt,
    },
  });
  const initialFormat = event.shareProfilesDistributionType || 'PROFILE_LINK';
  const [formatValue, setFormatValue] =
    useState<DistributionFormat>(initialFormat);
  const handleOnClose = () => {
    onClose();
    resetField('shareProfilesAtDate', { defaultValue: shareProfilesAt });
    resetField('shareProfilesAtTime', { defaultValue: shareProfilesAt });
    setFormatValue(initialFormat);
  };
  const {
    mutate: mutateSubmitShareSchedule,
    isPending: isPendingSubmitShareSchedule,
  } = useSubmitShareSchedule();
  const {
    mutate: mutateCancelShareSchedule,
    isPending: isPendingCancelShareSchedule,
  } = useCancelShareSchedule();
  const isPendingRequest =
    isPendingSubmitShareSchedule || isPendingCancelShareSchedule;

  const onSetScheduleShare = ({
    shareProfilesAtDate,
    shareProfilesAtTime,
  }: EventScheduleShare) => {
    const combinedShareAt = `${
      (shareProfilesAtDate?.toISO() as string).split('T')[0]
    }T${(shareProfilesAtTime?.toISO() as string).split(/[T.]/)[1]}Z`;

    mutateSubmitShareSchedule(
      {
        eventId: event.id,
        format: formatValue,
        scheduleShareBody: {
          shareAt: combinedShareAt,
          generateUsing: 'EVALUATION_DIALECT',
        },
      },
      {
        onSuccess: () => {
          setEvent({
            ...event,
            shareProfilesAt: toShareIsoFormatFromDateTime(
              mergeDateTimeAndZone(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                shareProfilesAtDate!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                shareProfilesAtTime!,
                zone,
              ),
            ),
            shareProfilesDistributionType: formatValue,
          });
          onClose();
          resetField('shareProfilesAtDate', {
            defaultValue: shareProfilesAtDate,
          });
          resetField('shareProfilesAtTime', {
            defaultValue: shareProfilesAtTime,
          });
        },
      },
    );
  };

  const handleCancelScheduleShare = () => {
    mutateCancelShareSchedule(
      { eventId: event.id },
      {
        onSuccess: () => {
          setEvent({
            ...event,
            shareProfilesAt: undefined,
            shareProfilesDistributionType: undefined,
          });
          onClose();
          resetField('shareProfilesAtDate', { defaultValue: null });
          resetField('shareProfilesAtTime', { defaultValue: null });
          setFormatValue('PROFILE_LINK');
        },
      },
    );
  };

  const hasScheduleShare = Boolean(shareProfilesAt);

  const dateLabel = hasScheduleShare
    ? t('ui.event-management.events.schedule-share.modal.update.date.label')
    : t('ui.event-management.events.schedule-share.modal.set.date.label');

  const primaryButtonText = hasScheduleShare
    ? t('ui.event-management.events.schedule-share.modal.update.submit.button')
    : t('ui.event-management.events.schedule-share.modal.set.submit.button');

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBlock: 0,
          paddingInline: 2,
        }}
      >
        <TextV2 variant="h4" component="p">
          {t('ui.event-management.events.schedule-share.modal.title')}
        </TextV2>
        <IconButton aria-label="close" onClick={handleOnClose} size="large">
          <CloseIcon sx={(theme) => ({ fill: theme.palette.blue.main })} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 2 }}
      >
        <TextV2 variant="body1" component="p">
          <Trans
            i18nKey="ui.event-management.events.schedule-share.modal.desc"
            values={{
              today: formatDate(new Date().toISOString()),
              deadline: formatDate(event.deadlineAt),
            }}
            components={{
              stress: <TextV2 variant="bodyBold" component="span" />,
            }}
          />
        </TextV2>
        <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
          <Controller
            control={control}
            name="shareProfilesAtDate"
            render={({ field: { onChange: onReactHookFormChange, value } }) => (
              <DatePickerV2
                timezone={zone}
                error={Boolean(errors.shareProfilesAtDate)}
                errorMessage={t(getErrorMessage(errors.shareProfilesAtDate))}
                labelText={dateLabel}
                onChange={(newValue) => {
                  onReactHookFormChange(newValue);
                  trigger(['shareProfilesAtDate', 'shareProfilesAtTime']);
                }}
                value={value}
                placeholder={t(
                  'ui.event-management.events.create.label.select-date',
                )}
              />
            )}
            rules={scheduleShareDateFieldRules()}
          />
        </Box>
        <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
          <Controller
            control={control}
            name="shareProfilesAtTime"
            render={({ field: { onChange: onReactHookFormChange, value } }) => {
              return (
                <TimePickerV2
                  timezone={zone}
                  error={Boolean(errors.shareProfilesAtTime)}
                  errorMessage={t(getErrorMessage(errors.shareProfilesAtTime), {
                    time: DateTime.local({ zone }).toFormat('HH:mm'),
                  })}
                  labelText={t(
                    'ui.event-management.events.schedule-share.modal.time.label',
                  )}
                  onChange={(newValue) => {
                    onReactHookFormChange(newValue);
                    trigger(['shareProfilesAtDate', 'shareProfilesAtTime']);
                  }}
                  value={value}
                  placeholder={t(
                    'ui.event-management.events.schedule-share.modal.time.placeholder',
                  )}
                />
              );
            }}
            rules={scheduleShareTimeFieldRules(getValues, zone)}
          />
        </Box>
        <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
          <RadioGroupControl<DistributionFormat>
            options={[
              {
                value: 'PROFILE_LINK',
                label: t(
                  'ui.event-management.events.download-or-share-profiles-modal.radio.share-download-link',
                ),
              },
              {
                value: 'ATTACHMENT',
                label: t(
                  'ui.event-management.events.download-or-share-profiles-modal.radio.share-download-pdf',
                ),
              },
            ]}
            name="format"
            value={formatValue}
            onChange={(newFormat) => setFormatValue(newFormat)}
            title={t(
              'ui.event-management.events.download-or-share-profiles-modal.radio.share.aria-label',
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}
      >
        <Button
          fullWidth
          onClick={handleSubmit(onSetScheduleShare)}
          variant="contained"
          disabled={isPendingRequest}
        >
          {primaryButtonText}
        </Button>
        {hasScheduleShare ? (
          <Button
            fullWidth
            onClick={handleCancelScheduleShare}
            variant="outlined"
            color="error"
            disabled={isPendingRequest}
          >
            {t(
              'ui.event-management.events.schedule-share.modal.update.cancel.button',
            )}
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={handleOnClose}
            variant="outlined"
            disabled={isPendingRequest}
          >
            {t(
              'ui.event-management.events.schedule-share.modal.set.cancel.button',
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SetScheduleShareDialog;
