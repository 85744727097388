import { Box, Button, Grid, styled, SxProps, Theme } from '@mui/material';
import { Group, GroupType } from 'api';
import {
  P,
  Span,
  SvgLock2Bold,
  Tag,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  IconWrapper,
  OrganisationCardIcon,
  OrganisationIconHeader,
  OrganisationTitle,
} from '../StyledComponents';
import DataRetentionPolicy from '../DataRetentionPolicy';
import { GroupLink, StyledLink, StyledSpan } from './AccountOrganisationHelper';

export type Props = {
  id: string;
  name: string;
  dataRetentionPolicy?: { maximumInactivityPeriod: string };
  parent?: Group;
  type?: OrganisationCardIcon | null;
  orgCount?: number;
  groupType?: GroupType;
  allowDemotion?: boolean;
  onClick?: (id: string) => void;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  variant?: 'small' | 'medium';
  titleAction?: React.ReactNode;
  isPrivateOrg?: boolean;
};

const Div = styled('div')({});

const InfoContainer = styled('div')({ margin: '0.5rem 1rem 14px' });

const StyledP = styled(P)({});

const StyledLock = styled(SvgLock2Bold)(({ theme }) => ({
  fill: theme.palette.grey[600],
  transform: 'scale(67%)',
  marginRight: '1px',
}));

const AccountOrganisationCard: React.FC<Props> = ({
  id,
  name,
  dataRetentionPolicy,
  type = 'Organisation',
  orgCount = 0,
  groupType = 'PARTNER',
  allowDemotion = false,
  parent,
  onClick,
  sx,
  children,
  variant = 'medium',
  titleAction,
  isPrivateOrg = false,
}) => {
  const { t } = useTranslation();

  const demotionLink =
    type === 'Organisation' ? (
      <Button
        onClick={() => {
          if (onClick) onClick(id);
        }}
        sx={{ padding: '0 !important', marginBottom: '8px !important' }}
        color="error"
      >
        {t('ui.event-management.my-account.organisation.revoke')}
      </Button>
    ) : (
      <StyledSpan
        sx={{ paddingBottom: '8px !important' }}
        color="textSecondary"
      >
        {t('ui.event-management.my-account.group.revoke')}
      </StyledSpan>
    );

  const StyledDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    padding:
      // eslint-disable-next-line no-nested-ternary
      type === 'Organisation'
        ? variant === 'small'
          ? `12px ${theme.spacing(spacingSizeMap.S)}`
          : `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
              spacingSizeMap.S,
            )}`
        : '0',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '0.25rem',
    flexDirection: 'column',
    height: '100%',
  }));

  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.group.label.customer'),
    PARTNER: t('ui.event-management.organisations.group.label.partner'),
  };

  return (
    <Grid item xs={12} md={variant === 'small' ? 12 : 6} key={id} sx={sx}>
      <StyledDiv>
        {type === 'Organisation' ? (
          <>
            <OrganisationIconHeader
              sx={{
                marginTop: '4px',
                alignItems: 'center',
                display: 'flex',
                marginBottom: '0',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Div
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <IconWrapper iconType="Organisation" />
                  <OrganisationTitle
                    sx={{
                      fontSize:
                        variant === 'small'
                          ? '18px !important'
                          : '24px !important',
                      display: 'flex',
                    }}
                  >
                    {name}
                  </OrganisationTitle>
                </Div>
                {isPrivateOrg ? (
                  <Div sx={{ marginLeft: -2 }}>
                    <Tag
                      icon={<StyledLock />}
                      label={t('ui.event-management.special-handling.tag')}
                      variant="default"
                      color="white"
                    />
                  </Div>
                ) : null}
              </Box>

              {titleAction ? (
                <StyledSpan sx={{ justifySelf: 'flex-end' }}>
                  {titleAction}
                </StyledSpan>
              ) : null}
            </OrganisationIconHeader>

            <DataRetentionPolicy dataRetentionPolicy={dataRetentionPolicy} />
            {parent ? (
              <GroupLink
                variant={variant}
                sx={{ marginTop: '5px !important' }}
                parent={parent}
              />
            ) : null}
            {children}
            {allowDemotion ? (
              <StyledP sx={{ marginTop: '0.125rem !important' }}>
                {demotionLink}
              </StyledP>
            ) : null}
          </>
        ) : (
          <>
            <OrganisationIconHeader
              sx={(theme) => ({
                maxWidth: '100%',
                padding: '20px 16px 14px',
                backgroundColor:
                  groupType === 'PARTNER'
                    ? theme.palette.green.light
                    : theme.palette.orange.light,
                marginBottom: 0,
                borderRadius: '4px',
                alignItems: 'center',
              })}
            >
              <IconWrapper iconType="Group" />
              <OrganisationTitle
                sx={{
                  fontSize:
                    variant === 'small' ? '18px !important' : '24px !important',
                }}
                variant="body-large"
              >
                {name}
              </OrganisationTitle>
            </OrganisationIconHeader>
            <DataRetentionPolicy dataRetentionPolicy={dataRetentionPolicy} />

            <InfoContainer>
              <StyledP sx={{ marginBottom: '0.5rem !important' }}>
                <Trans
                  i18nKey={
                    orgCount === 1
                      ? 'ui.event-management.my-account.group.organisations.singular'
                      : 'ui.event-management.my-account.group.organisations.plural'
                  }
                  values={{
                    groupType: groupTypeLabel[groupType],
                    count: orgCount,
                  }}
                  components={{
                    1: <Span variant="body-bold" />,
                    2: (
                      <StyledLink
                        sx={(theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                          margin: '0 !important',
                        })}
                        to={`/groups/${id}`}
                      />
                    ),
                  }}
                />
              </StyledP>

              {allowDemotion ? <P> {demotionLink} </P> : null}
            </InfoContainer>
          </>
        )}
      </StyledDiv>
    </Grid>
  );
};

export default AccountOrganisationCard;
