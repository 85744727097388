/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { H1 } from '@insights-ltd/design-library/components';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import Wallet from 'components/Wallet/Wallet';
import { useAuth } from 'contexts/AuthContext';
import RemoveOrganisationDialog from 'components/RemoveOrganisationDialog/RemoveOrganisationDialog';
import { isSuperAdmin } from 'utils/role';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { getOrganisationText } from 'pages/PractitionerAccount/PractitionerAccount';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { useDeletePractitionerFromOrg } from 'api';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_ROOTS } from 'variables';
import UserInfoSection from './views/UserInfoSection';
import ExperiencesSection from './ExperiencesSection';
import TransactionsSection from './TransactionsSection';
import Actions from './Actions';
import useGetAccountData from './useGetAccountData';
import RequestUnitsDialog from './views/RequestUnitsDialog';
import OrganisationInfoSection from './OrganisationInfoSection';
const Account = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const {
    isPending,
    isError,
    wallet,
    organisation,
    emailAddress,
    activeEvents,
    cancelledEvents,
    transactions,
    practitionerOrganisations,
    allMyOrganisations,
    groups,
    supportedRoles,
    signInMethod,
  } = useGetAccountData();
  const [requestUnitsDialogOpen, setRequestUnitsDialogOpen] =
    React.useState(false);
  const [organisationToRemove, setOrganisationToRemove] = React.useState<
    string | null
  >(null);
  const queryClient = useQueryClient();
  const { mutateAsync: removeOrg } = useDeletePractitionerFromOrg(
    organisationToRemove ?? '',
    queryClient,
  );

  if (isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (isError) {
    return (
      <FullScreenError
        message={t('ui.event-management.my-account.error.load')}
      />
    );
  }

  const isAdminUser = isSuperAdmin(user?.roles);
  const { availableUnits } = wallet!;
  const showMultiOrg =
    (!isAdminUser && practitionerOrganisations!.length > 1) ||
    (groups && groups?.length > 0);

  const displayOrganisations = showMultiOrg
    ? allMyOrganisations
    : [allMyOrganisations![0]!];

  const title = t('ui.event-management.my-account.title');

  const noResultsTranslations = {
    cancelled:
      'ui.event-management.my-account.experiences.cancelled.no-results',
    active: 'ui.event-management.my-account.experiences.upcoming.no-results',
    archived: 'ui.event-management.my-account.experiences.past.no-results',
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
              }}
              activeText={title}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid container wrap="nowrap" item xs={12} md={4}>
              <H1 variant="h2">{title}</H1>
            </Grid>
            <Actions
              preferredDialect={user?.preferredDialect}
              signInMethod={signInMethod!}
            />
          </Grid>
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.M)}
            mb={(theme) => theme.spacing(spacingSizeMap.L)}
          >
            <Divider />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8}>
              <UserInfoSection
                emailAddress={emailAddress}
                practitioner={user}
              />
              <OrganisationInfoSection
                organisations={displayOrganisations ?? []}
                groups={groups}
                practitioner={user}
                allowDemotion={isSuperAdmin(user?.roles)}
                supportedRoles={supportedRoles ?? []}
                onOrganisationRemove={setOrganisationToRemove}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Wallet
                availableUnits={availableUnits}
                practitionerId={user?.id}
                organisationId={organisation?.id}
                isMyWallet
                openRequestUnitsDialog={() => setRequestUnitsDialogOpen(true)}
              />
            </Grid>
          </Grid>
          <TransactionsSection
            transactions={transactions!}
            practitionerId={user!.id}
          />
          <ExperiencesSection
            cancelledEvents={cancelledEvents!}
            activeEvents={activeEvents!}
            isAdminUser={isAdminUser}
            practitionerOrganisations={practitionerOrganisations!}
            textKeys={noResultsTranslations}
          />
        </Container>
      </FullHeight>
      <RequestUnitsDialog
        open={requestUnitsDialogOpen}
        onClose={() => setRequestUnitsDialogOpen(false)}
        organisations={allMyOrganisations!}
        requestingUser={user!}
      />
      <RemoveOrganisationDialog
        organisationText={getOrganisationText(
          organisationToRemove ?? '',
          practitionerOrganisations ?? [],
          createGroupOrganisationHashMap(groups ?? []),
          t,
        )}
        open={!!organisationToRemove}
        onSubmit={async () => {
          queryClient
            .invalidateQueries({
              queryKey: [QUERY_KEY_ROOTS.ORGANISATION_DATA],
            })
            .then();
          await removeOrg({
            organisationId: organisationToRemove ?? '',
            practitionerId: user?.id ?? '',
          }).then(() => {
            setOrganisationToRemove(null);
          });
        }}
        onClose={() => {
          setOrganisationToRemove(null);
        }}
      />
    </>
  );
};

export default Account;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
