import { authenticatedFetch } from 'api/fetch';
import {
  EvaluationsProfilesResponse,
  EvaluatorLinkResponse,
  EventResponse,
  EventSummaryResponse,
  LearnerAndInviteeResponse,
  LearnerPurchaseRequest,
  LearnerWithEvaluation,
  OrganisationResponse,
  OrganisationSupportedRole,
  PractitionerSummaryData,
  SupportedRolesResponse,
  TeamResponse,
} from 'api/httpEntities';
import { sortOrgs } from 'utils/mappers/sorting';
import {
  CreateEventData,
  CreateNewEvaluatorLink,
  InviteUserData,
  OpenPractitionerInvitesResponse,
  OrganisationEditRequest,
  OrganisationMoveRequest,
  OrganisationRequest,
  ProfilesResponse,
  ArchivedProfilesResponse,
  PurchaseLearnerRequest,
  TeamRequest,
} from './organisationApitypes';

export const getPractitionersSummary = async (
  organisationId: string,
): Promise<Array<PractitionerSummaryData>> => {
  const { json } = await authenticatedFetch<{
    practitioners: PractitionerSummaryData[];
  }>(`/api/v1/organisations/${organisationId}/practitioners?view=summary`);

  const { practitioners } = await json();

  return practitioners;
};

export const createEvent = async ({
  event,
  organisationId,
}: CreateEventData): Promise<EventResponse> => {
  const { json } = await authenticatedFetch<EventResponse>(
    `/api/v1/organisations/${organisationId}/events`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(event),
    },
  );
  return json();
};
export const getOrganisationEvents = async (
  organisationId: string,
  status: string,
): Promise<EventSummaryResponse[]> => {
  const { json } = await authenticatedFetch<{ events: EventSummaryResponse[] }>(
    `/api/v1/organisations/${organisationId}/events?status=${status}`,
  );
  const { events } = await json();

  return events;
};

export const inviteUser = async (
  userData: InviteUserData,
): Promise<Response> => {
  const { organisationId, ...packet } = userData;
  const url = `/api/v1/organisations/${organisationId}/practitioners/invites`;
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...packet, dialect: 'en-GB' }),
  });

  return json();
};

export const addOrganisation = async (
  formData: OrganisationRequest,
): Promise<OrganisationResponse> => {
  const { json } = await authenticatedFetch<OrganisationResponse>(
    `/api/v1/organisations`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    },
  );

  return json();
};

export const getAllOrganisations = async (): Promise<
  OrganisationResponse[]
> => {
  const { json } = await authenticatedFetch<{
    organisations: OrganisationResponse[];
  }>('/api/v1/organisations');

  const { organisations }: { organisations: OrganisationResponse[] } =
    await json();

  return (organisations ?? []).sort(sortOrgs);
};

export const getOrganisation = async (organisationId: string) => {
  const { json } = await authenticatedFetch<OrganisationResponse>(
    `/api/v1/organisations/${organisationId}`,
  );

  return json();
};

export const updateOrganisation = async ({
  groupId,
  organisationId,
  formData,
}: OrganisationEditRequest) => {
  let url = `/api/v1/organisations/${organisationId}`;
  if (groupId) {
    url = `/api/v1/organisation-groups/${groupId}/organisations/${organisationId}`;
  }
  const { json } = await authenticatedFetch<OrganisationResponse>(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  return json();
};

export const moveOrganisation = async ({
  learnerId,
  sourceOrganisationId,
  targetOrganisationId,
  reason,
}: OrganisationMoveRequest): Promise<void> => {
  const url = `/api/v1/organisations/${sourceOrganisationId}/learners/${learnerId}/transfer-learner/${targetOrganisationId}`;
  const { json } = await authenticatedFetch(url, {
    method: 'PUT',
    headers: {
      InsightsApiAuditReason: reason,
    },
  });

  await json();
};

export const deleteOrganisation = async (
  organisationId: string,
): Promise<void> => {
  const url = `/api/v1/organisations/${organisationId}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });
  await json();
};

export const getLearnerByOrganisation = async (
  learnerId: string,
  organisationId: string,
): Promise<LearnerWithEvaluation> => {
  const { json } = await authenticatedFetch<LearnerWithEvaluation>(
    `/api/v1/organisations/${organisationId}/learners/${learnerId}`,
  );

  return json();
};

export const getLearnerByEmail = async (
  organisationId: string,
  emailAddress: string,
): Promise<LearnerAndInviteeResponse> => {
  const { status, json } = await authenticatedFetch<LearnerAndInviteeResponse>(
    `/api/v1/organisations/${organisationId}/learners?email=${encodeURIComponent(
      emailAddress,
    )}`,
  );

  if (status === 204) {
    throw Error(status.toString());
  }

  return json();
};

export const getProfilesByOrganisation = async (
  learnerId: string,
  organisationId: string,
): Promise<ProfilesResponse> => {
  const { json } = await authenticatedFetch<ProfilesResponse>(
    `/api/v1/organisations/${organisationId}/learners/${learnerId}/profiles`,
  );

  return json();
};

export const getArchivedProfilesByOrganisation = async (
  learnerId: string,
  organisationId: string,
): Promise<ArchivedProfilesResponse> => {
  const { json } = await authenticatedFetch<ArchivedProfilesResponse>(
    `/api/v1/archives/${organisationId}/profiles?learner-id=${learnerId}`,
  );

  return json();
};

export const purchaseLearnerChapters = async ({
  organisationId,
  learnerId,
  purchaseData,
}: PurchaseLearnerRequest) => {
  const { json } = await authenticatedFetch(
    `/api/v1/organisations/${organisationId}/learners/${learnerId}/profiles`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(purchaseData),
    },
  );

  return json();
};

export const getOpenPractitionerInvites = async (
  organisationId: string,
): Promise<OpenPractitionerInvitesResponse> => {
  const { json } = await authenticatedFetch(
    `/api/v1/organisations/${organisationId}/practitioners/invites?open`,
  );

  return json();
};

export const addTeam = async ({
  organisationId,
  formData,
}: {
  organisationId: string;
  formData: TeamRequest;
}): Promise<TeamResponse> => {
  const { json } = await authenticatedFetch(
    `/api/v1/organisations/${organisationId}/teams`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    },
  );

  return json();
};

export const purchaseProfilesForEvaluatorLink = async ({
  evaluatorLinkId,
  organisationId,
  request,
}: {
  evaluatorLinkId: string;
  organisationId: string;
  request: LearnerPurchaseRequest;
}) => {
  const { success } = await authenticatedFetch(
    `/api/v1/organisations/${organisationId}/evaluator-links/${evaluatorLinkId}/profiles`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    },
  );

  return success;
};

export const getEvaluatorLinks = async (id: string) => {
  const { json } = await authenticatedFetch<EvaluatorLinkResponse>(
    `/api/v1/organisations/${id}/evaluator-links`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return json();
};

export const createNewEvaluatorLink = async ({
  organisationId,
  newEvaluatorLinkName,
  dialect,
}: CreateNewEvaluatorLink) => {
  const { json } = await authenticatedFetch(
    `/api/v1/organisations/${organisationId}/evaluator-links`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: newEvaluatorLinkName,
        model: 'DDDRS',
        dialect,
      }),
    },
  );

  await json();
};

export const getEvaluationsProfilesByOrg = async ({
  organisationId,
  evaluationIds,
}: {
  organisationId: string;
  evaluationIds: string[];
}): Promise<EvaluationsProfilesResponse> => {
  const url = `/api/v1/organisations/${organisationId}/evaluations/searches`;

  const { json } = await authenticatedFetch<EvaluationsProfilesResponse>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ evaluationIds }),
  });

  return json();
};

export const getEvaluationsProfilesByOrgs = async ({
  organisationIds,
  evaluationIds,
}: {
  organisationIds: string[];
  evaluationIds: string[];
}): Promise<EvaluationsProfilesResponse> => {
  const calls: Promise<EvaluationsProfilesResponse>[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < organisationIds.length; i++) {
    calls.push(
      getEvaluationsProfilesByOrg({
        organisationId: organisationIds[i],
        evaluationIds,
      }),
    );
  }

  const combinedEvaluations = (
    await Promise.all<EvaluationsProfilesResponse>(calls)
  )
    .map(({ evaluations }) => evaluations)
    .flat();

  return { evaluations: combinedEvaluations };
};

export const getSupportedRoles = async (
  organisationId: string,
): Promise<SupportedRolesResponse> => {
  const { json } = await authenticatedFetch<SupportedRolesResponse>(
    `/api/v1/organisations/${organisationId}/supportedRoles`,
  );

  return json();
};

export const getAllSupportedRoles = async (): Promise<
  OrganisationSupportedRole[]
> => {
  const { json } = await authenticatedFetch<{
    organisationSupportedRoles: OrganisationSupportedRole[];
  }>(`/api/v1/organisations/supported-roles`);

  const {
    organisationSupportedRoles,
  }: { organisationSupportedRoles: OrganisationSupportedRole[] } = await json();

  return organisationSupportedRoles ?? [];
};
