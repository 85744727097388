import {
  AlertV2,
  ButtonV2,
  Modal,
  TextV2,
} from '@insights-ltd/design-library/components';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  formId?: string;
  isSaving: boolean;
};

const Content = () => {
  const { t } = useTranslation();
  return (
    <>
      <TextV2 color="textSecondary">
        <Trans
          i18nKey="ui.event-management.special-handling.organisations.add.confirmation.modal.info"
          components={{
            stress: <TextV2 variant="bodyBold" color="textSecondary" />,
          }}
        />
      </TextV2>
      <Box mt={2}>
        <AlertV2
          severity="error"
          message={t(
            'ui.event-management.special-handling.organisations.add.confirmation.modal.warning',
          )}
        />
      </Box>
    </>
  );
};

export const SpecialHandlingWarningModal = ({
  isOpen,
  onClose,
  formId,
  isSaving,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      content={(<Content />) as any}
      title={t(
        'ui.event-management.special-handling.organisations.add.confirmation.modal.title',
      )}
      actions={
        <>
          <Button
            variant="contained"
            type="submit"
            form={formId}
            disabled={isSaving}
            endIcon={isSaving && <CircularProgress size={24} />}
          >
            {t(
              'ui.event-management.special-handling.organisations.add.confirmation.modal.confirm',
            )}
          </Button>
          <ButtonV2
            buttonText={t(
              'ui.event-management.special-handling.organisations.add.confirmation.modal.cancel',
            )}
            variant="outlined"
            onClick={onClose}
          />
        </>
      }
    />
  );
};
