import React from 'react';
import PermissionGuard from 'components/PermissionGuard';
import { SpecialHandlingSelection } from 'components/SpecialHandlingSelection/SpecialHandlingSelection';

export const AdditionalDetails = ({
  setSpecialHandling,
  isSpecialHandling,
}: {
  setSpecialHandling: (value: boolean) => void;
  isSpecialHandling: boolean;
}) => {
  return (
    <PermissionGuard
      requiredPermissions={[{ action: 'Special_Handling', scope: 'Global' }]}
    >
      <SpecialHandlingSelection
        setSpecialHandling={setSpecialHandling}
        isSpecialHandling={isSpecialHandling}
      />
    </PermissionGuard>
  );
};
