import { LanguageSelect } from '@insights-ltd/design-library/components';
import {
  LANGUAGES,
  Dialects,
  LanguageDetails,
} from '@insights-ltd/design-library/utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSupportedDialects } from 'domain/dialect';
import { LanguageSelectWrapperContext } from '../../contexts/LanguageSelectWrapperContext';

const LanguageSelectWrapper = () => {
  const languageSelectContext = useContext(LanguageSelectWrapperContext);
  if (languageSelectContext === undefined) {
    throw new Error(
      'languageSelectContext must be used with LanguageSelectWrapperProvider',
    );
  }
  const { i18n } = useTranslation();
  const supportedDialects: LanguageDetails[] = Object.entries(
    useGetSupportedDialects(),
  ).map(([dialect, text]): LanguageDetails => {
    return { tag: dialect as Dialects, text, enabled: true };
  });
  const [appLanguage, setAppLangauge] = useState<Dialects>(
    languageSelectContext.userSelectedDialect || 'en-GB',
  );

  const onLanguageSelect = useCallback(
    (language: Dialects) => {
      setAppLangauge(language);
      i18n.changeLanguage(language);
    },
    [i18n],
  );

  useEffect(() => {
    const language =
      languageSelectContext.userSelectedDialect || navigator.language;
    if (appLanguage !== language) {
      const dialect = LANGUAGES.find((l) => l.tag.includes(language));
      if (dialect) {
        onLanguageSelect(dialect.tag);
      }
    }
  }, [
    appLanguage,
    languageSelectContext.userSelectedDialect,
    onLanguageSelect,
  ]);

  return (
    <LanguageSelect
      id="language-selector"
      aria-label="Select your langauge"
      onChange={(event) => {
        languageSelectContext.setUserSelectedDialect(
          event.target.value as Dialects,
        );
        onLanguageSelect(event.target.value as Dialects);
      }}
      value={appLanguage}
      languageOptions={supportedDialects}
    />
  );
};

export default LanguageSelectWrapper;
