/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Grid, styled } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import AddOrganisationForm from 'components/OrganisationForm/OrganisationForm';
import { OrganisationData } from 'components/OrganisationForm/types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  DetailsHeader,
  H2,
  Tag,
  Text,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import {
  getVisibility,
  GroupType,
  RequestError,
  useCreateGroupedOrganisation,
  useGetGroup,
} from 'api';
import FullScreenSpinner from 'components/FullScreen/FullScreenSpinner';
import FullScreenError from 'components/FullScreen/FullScreenError';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import { isSuperAdmin } from 'utils/role';
import { useAuth } from 'contexts/AuthContext';
import { useBetaEnabled } from 'features';
import { AdditionalDetails } from 'pages/HelperComponents/HelperComponents';
import { SpecialHandlingWarningModal } from 'components/SpecialHandlingWarningModal/SpecialHandlingWarningModal';

const StyledText = styled(Text)({
  wordWrap: 'break-word',
  wordBreak: 'break-word',
});

const CreateGroupOrganisation: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { groupId } = useParams<{ groupId: string }>();
  const { data: group, status } = useGetGroup(groupId!);
  const [isSpecialHandling, setSpecialHandling] = useState<boolean>(false);
  const [isDetailsHeaderOpen, setIsDetailsHeaderOpen] =
    useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const isSpecialHandlingEnabled = useBetaEnabled('special-handling');

  const isAdmin = isSuperAdmin(user?.roles ?? []);
  const organisationGroupName: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.group.breadcrumb.customer', {
      groupName: group?.name,
    }),
    PARTNER: t('ui.event-management.group.breadcrumb.partner', {
      groupName: group?.name,
    }),
  };
  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.group.label.customer'),
    PARTNER: t('ui.event-management.organisations.group.label.partner'),
  };
  const translations: Record<string, string> = {
    title: 'ui.event-management.organisations.create-organisation',
    breadcrumb: 'ui.event-management.organisations.create-organisation',
    organisations: 'ui.event-management.organisations.title',
  };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCreateGroupedOrganisation(queryClient);
  const isSaving = isPending;

  const onSubmit = (
    formData: OrganisationData,
    onError?: (error: RequestError) => void,
  ) => {
    mutate(
      {
        groupId: groupId!,
        ...formData,
        ...(isSpecialHandlingEnabled && {
          visibility: getVisibility(isSpecialHandling),
        }),
      },
      {
        onSuccess: () => navigate(`/groups/${groupId}`),
        onError: (error) => {
          setShowWarningModal(false);
          if (error instanceof RequestError && !!onError) {
            onError(error);
          } else {
            throw error;
          }
        },
      },
    );
  };

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisation.error-loading')}
      />
    );
  }
  const formId = 'add-org-to-group-form';
  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.add-organisation"
      activeTextKey={translations.breadcrumb}
      crumbs={{
        '/': t('ui.event-management.dashboard.home.title'),
        '/organisations': t('ui.event-management.organisations.title'),
        [`/groups/${group?.id}`]:
          organisationGroupName[group?.type ?? 'CUSTOMER'],
      }}
      headingKey={translations.title}
    >
      <AddOrganisationForm
        organisation={{ name: '' }}
        onSubmit={
          isSpecialHandling && !showWarningModal
            ? () => setShowWarningModal(true)
            : onSubmit
        }
        id={formId}
      >
        {isAdmin ? (
          <Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <H2 variant="body-large">
                  <Trans
                    i18nKey="ui.event-management.organisations.add.select-group"
                    components={{ styles: <Text color="textSecondary" /> }}
                  />
                </H2>
              </Grid>
            </Grid>
            <Grid item>
              <Paper elevation={0} variant="outlined">
                <Box
                  sx={(theme) => ({
                    m: 0,
                    p: 2,
                    '& > div': {
                      margin: `0 ${theme.spacing(spacingSizeMap.S)}`,
                    },
                  })}
                >
                  <StyledText color="textPrimary" variant="body-bold">
                    {group?.name ?? ''}
                  </StyledText>
                  <Tag
                    color={group?.type === 'CUSTOMER' ? 'orange' : 'darkGreen'}
                    label={groupTypeLabel[group?.type ?? 'CUSTOMER']}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : null}

        {isSpecialHandlingEnabled && isAdmin ? (
          <Box width={1}>
            <DetailsHeader
              id="create-org-additional-details"
              open={isDetailsHeaderOpen}
              onClick={() => setIsDetailsHeaderOpen(!isDetailsHeaderOpen)}
              type="collapsible"
              collapsibleContent={
                <AdditionalDetails
                  setSpecialHandling={setSpecialHandling}
                  isSpecialHandling={isSpecialHandling}
                />
              }
              text={t('ui.event-management.create-org.accordion.title')}
              fullWidth
            />
          </Box>
        ) : null}

        <Grid container justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            endIcon={isSaving && <CircularProgress size={24} />}
          >
            {t('ui.event-management.organisations.add.create-button')}
          </Button>
        </Grid>
        <div />
        <SpecialHandlingWarningModal
          formId={formId}
          isOpen={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          isSaving={isSaving}
        />
      </AddOrganisationForm>
    </BreadcrumbLayout>
  );
};
export default CreateGroupOrganisation;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
